import {RouteLocation} from "vue-router";
import {storeToRefs} from "pinia";
import _ from "lodash";
import {OplitLevel, Sector, Simulation} from "@/interfaces";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useSimulationStore} from "@/stores/simulationStore";
import {useMainStore} from "@/stores/mainStore";
import {useGlobalTabs} from "@/composables/useGlobalTabs";
import router from "@/router";
import {getBreadcrumbsItemsFromSector} from "@/tscript/utils/coreHelper";

export const useNavigation = () => {
  const {isScheduling, perimeters, simulations, teamSimulations, hasStock} =
    storeToRefs(useMainStore());
  const {setBreadcrumbs} = useMainStore();
  const {setSimulationGlobal} = useSimulationStore();

  const {schedulingSimulations, teamSchedulingSimulations} = storeToRefs(
    useSchedulingStore(),
  );

  const {globalTabsPerRoute, setSelectedTab} = useGlobalTabs();

  const updateBreadcrumbs = (
    secteur: Sector,
    level: OplitLevel,
    stayInPage = false,
    simulation_id = "",
    forceRouteName?: string,
  ) => {
    if (!secteur?.id) return;

    const {breadcrumbs, level: determinedLevel} = getBreadcrumbsItemsFromSector(
      secteur,
      level,
      perimeters.value,
    );

    setBreadcrumbs(breadcrumbs);

    // using corresponding variables from store updates
    const contextualSimulations = isScheduling.value
      ? schedulingSimulations.value
      : simulations.value;
    const contextualTeamSimulations = isScheduling.value
      ? teamSchedulingSimulations.value
      : teamSimulations.value;

    const routeName =
      forceRouteName ||
      (isScheduling.value ? "scheduling-planning" : "simulation");
    const route = router.currentRoute.value;
    const query = route.query || {};
    const {simulation_id: currentSimulationId} = query;
    // default fields to set to $route.query
    const queryParamsToUpdate: {[key: string]: string} = {
      id: secteur.id,
      collection: determinedLevel.collection,
      level: `${determinedLevel.level}`, // string casting for the lodash isEqual below
    };

    // shorthand failure handling
    const onHandleNoSimulation = (): void => {
      // no simulation - removing the eventual association
      setSimulationGlobal(null);
      delete query.simulation_id;
    };

    if (simulation_id) {
      // check if the simulation_id passed represents one of this user's team's simulation
      let simulation: Simulation = contextualTeamSimulations.find(
        (sim: Simulation): boolean => sim.id === simulation_id,
      );

      // select the latest simulation within the same team as the first fallback
      if (!simulation) simulation = contextualTeamSimulations.at(0);

      // select the latest simulation without team_id in the whole simulations' list
      if (!simulation) {
        simulation = contextualSimulations.find(
          (sim: Simulation) => !sim.team_ids?.length,
        );
      }

      // if we still can't find a simulation, handle failure
      if (!simulation) onHandleNoSimulation();

      // adding the query parameter from the retrieved simulation
      queryParamsToUpdate.simulation_id = simulation?.id;
      // setting the new simulation/scheduling_simulation
      if (simulation?.id !== currentSimulationId)
        setSimulationGlobal(simulation);
    } else onHandleNoSimulation();

    // default query object to update the router
    const routerObject: Partial<RouteLocation> = {
      query: {
        ...query,
        ...queryParamsToUpdate,
      },
      name: "",
    };

    const shouldRedirect: boolean = !stayInPage && route.name !== routeName;

    // since stocks additions, the manufacturing tab is the last one of `simulation`'s
    if (hasStock.value && routeName === "simulation")
      setSelectedTab(globalTabsPerRoute.value[routeName].length - 1);

    // do not take action to router if the route is the same and the query is unchanged
    if (!shouldRedirect && _.isEqual(route.query, routerObject.query)) return;

    const routerFunction = shouldRedirect ? "push" : "replace";

    if (shouldRedirect) routerObject.name = routeName;

    router[routerFunction](routerObject);
  };

  return {updateBreadcrumbs};
};
