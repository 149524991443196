<template>
  <v-dialog
    v-model="isOpen"
    max-width="800px"
    content-class="fdialog bg-newAppBackground export-modal"
    @click:outside="toggleModal"
  >
    <div class="capa-import-modal">
      <div class="capa-import-modal-header">
        <h3 class="font-weight-bold">{{ $t("scheduling.update_capa") }}</h3>
        <vue-feather
          type="x"
          tag="div"
          class="cursor-pointer"
          size="20"
          @click="toggleModal"
        />
      </div>

      <div class="pa-0 fbody-1 keep-scrollbar">
        <v-tabs v-model="tab" grow v-if="userData.has_production_planning">
          <v-tab
            v-for="tab in updateModes"
            :key="tab"
            class="fbody-1 no-uppercase font-weight-bold"
          >
            {{ tab }}
          </v-tab>
        </v-tabs>

        <v-window v-model="tab" class="capa-import-modal-body">
          <v-window-item>
            <FormSimulationsSelect
              :label="$t('scheduling.update_capa_simulation_select_label')"
              :simulations="simulationsList"
              hide-default-option
              @update-selected-simulation="onUpdateSelectedSimulation"
            />
          </v-window-item>

          <v-window-item>
            <div class="fbody-1 semi-bold">
              {{ $t("scheduling.drop_capa_file") }}
            </div>

            <i18n
              keypath="scheduling.import_capa_file_helper"
              tag="div"
              scope="global"
            >
              <br />
            </i18n>

            <a download target="_blank" :href="templateLink">
              <FButton class="mt-2" icon="download" small>
                {{ $t("Import.download_template") }}
              </FButton>
            </a>

            <DragAndDrop
              :title="$t('Import.drop_file')"
              :description="$t('Import.select_file')"
              @add-files="afterComplete"
              @remove-file="afterRemovedFile"
            />
          </v-window-item>
        </v-window>
      </div>

      <div class="capa-import-modal-actions">
        <FButton outlined @click="toggleModal">
          {{ $t("global.cancel") }}
        </FButton>

        <FButton
          :disabled="!isValidForm"
          :loading="loading"
          filled
          @click="onValidate"
        >
          {{ $t("global.valider") }}
        </FButton>
      </div>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import {computed} from "vue";
import {storeToRefs} from "pinia";
import {serverTimestamp} from "firebase/firestore";
import _ from "lodash";
import moment from "moment";

import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {FButton, DragAndDrop} from "@/components/Global";
import FormSimulationsSelect from "@/views/Simulation/FormSimulationsSelect.vue";
import {
  asyncForEach,
  buildRecursivelySectorTree,
  parseDate,
} from "@oplit/shared-module";
import {FORM_SIMULATIONS_SELECT_EMPTY} from "@/config/constants";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useDropzone} from "@/composables/useDropzone";
import {defineComponent, PropType, toRefs, ref} from "vue";
import {useSchedulingCapaSectorUpdater} from "@/composables/useSchedulingCapaSectorUpdater";
import {useI18n} from "vue-i18n";
import {Sector, Simulation} from "@/interfaces";
import {apiClient} from "@/tscript/utils/requirements";

import {useMainStore} from "@/stores/mainStore";

export default defineComponent({
  name: "capa-import-modal",
  props: {
    show: {type: Boolean, required: true},
    toggleModal: {type: Function as PropType<() => void>, required: true},
  },
  components: {FButton, DragAndDrop, FormSimulationsSelect},

  setup(props) {
    const {show} = toRefs(props);

    const {t} = useI18n();

    const {files, loading, afterComplete, afterRemovedFile} = useDropzone();
    const {schedulingSimulations, selectedSimulation: storeSelectedSimulation} =
      storeToRefs(useSchedulingStore());

    const mainStore = useMainStore();
    const {
      userData,
      perimeters,
      calendars,
      pgRefresh,
      simulation,
      simulations,
      isScheduling,
      stations,
      teamSimulations,
      team,
    } = storeToRefs(mainStore);

    const selectedSimulation = ref<Simulation>(null);
    const tab = ref<number>(0);

    const simulationsList = computed(() => {
      const filteredSimulations = isScheduling.value
        ? [...schedulingSimulations.value, ...simulations.value]
        : teamSimulations.value;
      const sortedSimulations = _.orderBy(
        filteredSimulations,
        (o) => parseDate(o.created_at),
        "desc",
      );
      return sortedSimulations;
    });

    const isValidForm = computed(() => {
      switch (tab.value) {
        case 1:
          return !!files.value.length;
        case 0:
          return (
            selectedSimulation.value &&
            selectedSimulation.value.id !== FORM_SIMULATIONS_SELECT_EMPTY
          );
        default:
          return false;
      }
    });

    return {
      isOpen: ref<boolean>(show.value),
      sectorTree: ref<Sector>({}),
      tab,
      updateModes: ref<string[]>([
        t("scheduling.update_capa_simulation"),
        t("scheduling.import_capa_file"),
      ]),
      selectedSimulation,
      templateLink: ref<string>("/templates/template_import_capa_ordo.csv"),
      files,
      loading,
      afterComplete,
      afterRemovedFile,
      userData,
      perimeters,
      calendars,
      pgRefresh,
      simulation,
      simulations,
      stations,
      teamSimulations,
      schedulingSimulations,
      team,
      storeSelectedSimulation,
      simulationsList,
      isValidForm,
    };
  },

  watch: {
    loading: function (isLoading: boolean) {
      this.$openSnackbar({loading: isLoading});
    },
  },
  mounted() {
    // sets the correct tab for users with ordo only
    this.tab = Number(!this.userData?.has_production_planning);
  },
  beforeUnmount() {
    this.$openSnackbar({loading: false});
  },
  methods: {
    onValidate: _.throttle(async function () {
      const {
        files,
        stations,
        userData,
        perimeters,
        storeSelectedSimulation: simulation,
      } = this;
      const {
        id: user_id,
        first_name = "",
        last_name = "",
        client_id,
      } = userData || {};
      this.loading = true;
      if (this.tab === 0) {
        const [error] = await apiClient.updateCapacityFromSimulation({
          target_simulation_id: simulation.id,
          source_simulation_id: this.selectedSimulation.id,
        });

        if (error) {
          this.loading = false;
          this.$openSnackbar(null, "GENERIC_ERROR");
          return;
        } else {
          this.$openSnackbar(null, "UPDATE_SUCCESS");

          //we also update the simulation to notify that its capa comes from pdp:
          const timestamp = serverTimestamp();
          await dbHelper.setDataToCollection(
            "simulations",
            simulation.id,
            {
              capa_from_pdp: true,
              updated_at: timestamp,
              updated_by: {user_id, first_name, last_name},
              pg_parsed: moment.utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
            },
            true,
          );
          this.storeSelectedSimulation.value = {
            ...simulation,
            capa_from_pdp: true,
          };
        }
      } else {
        if (!client_id || !files?.length) return;
        const [firstFile] = files;
        const {storagePath} = firstFile;

        const results = await apiClient.parseFile({storagePath});

        if (!results?.data?.length) return;
        const {data} = results;
        const groupByPoste = _.groupBy(data, "poste_de_charge");
        await asyncForEach(
          Object.keys(groupByPoste),
          async (poste_de_charge: string) => {
            const capas = _.sortBy(groupByPoste[poste_de_charge], "date");
            //match the sector
            const sector = stations.find(
              (x: any) => x.name + "" === poste_de_charge,
            );
            this.sectorTree = buildRecursivelySectorTree(sector, perimeters);
            if (!sector) return;
            const {updateSector} = useSchedulingCapaSectorUpdater(sector);
            let acc: any = [];
            //we create a fake last item to simplify the if cases afterwards
            await asyncForEach(
              [...capas, {}],
              async (capaItem: any, idx: number) => {
                const {capa, date} = capaItem;
                const lastCapa: any = _.last(acc);
                if (
                  acc.length &&
                  (capa !== lastCapa.capa ||
                    date !==
                      moment(lastCapa.date).add(1, "days").format("YYYY-MM-DD"))
                ) {
                  //save the accumulator
                  const startDate = _.get(acc, [0, "date"]);
                  const endDate = lastCapa.date;
                  let capaToSave = _.get(acc, [0, "capa"]);
                  capaToSave = (capaToSave + "").replace(/,/g, ".");
                  capaToSave = _.toNumber(capaToSave);
                  if (isNaN(capaToSave)) return;
                  capaToSave = capaToSave * acc.length;
                  const quickEvent = await updateSector(
                    "capa_validee",
                    capaToSave,
                    [startDate, endDate],
                  );
                  if (!quickEvent) this.$openSnackbar(null, "GENERIC_ERROR");
                  else this.$openSnackbar(null, "UPDATE_SUCCESS");
                  if (idx !== capas.length - 1) acc = [capaItem];
                } else acc.push(capaItem);
              },
            );
          },
        );
      }
      this.pgRefresh++;
      this.loading = false;
      this.toggleModal();
    }, 3000),
    onUpdateSelectedSimulation(simulation: any): void {
      this.selectedSimulation = simulation;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/constants.scss";

.capa-import-modal {
  background: rgb(var(--v-theme-newLayerBackground));

  & > div {
    padding: var(--g-vertical-spacing) var(--g-horizontal-spacing);

    &:first-child {
      border-bottom: $fModalBorder;
    }

    &:last-child {
      border-top: $fModalBorder;
    }
  }
}

.capa-import-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.capa-import-modal-body {
  padding: var(--g-vertical-spacing) var(--g-horizontal-spacing);

  & .v-window-item {
    display: flex;
    flex-direction: column;
    gap: calc(var(--g-vertical-spacing) / 2);
  }
}

.capa-import-modal-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: calc(var(--g-section-spacing) / 2);
}
</style>
