import {defineStore, storeToRefs} from "pinia";
import {computed} from "vue";
import {isUserFromRole} from "@/tscript/utils/permissions";
import {USER_ROLES} from "@/config/constants";
import {useMainStore} from "@/stores/mainStore";

export const useUserStore = defineStore("user", () => {
  const {userData} = storeToRefs(useMainStore());

  const isOplitAdmin = computed(() =>
    isUserFromRole(userData.value, USER_ROLES.ADMIN),
  );
  const isClientSuperAdmin = computed(
    () =>
      isUserFromRole(userData.value, USER_ROLES.CLIENT_SUPER_ADMIN) ||
      isUserFromRole(userData.value, USER_ROLES.GROUP_ADMIN),
  );
  const isGroupAdmin = computed(() =>
    isUserFromRole(userData.value, USER_ROLES.GROUP_ADMIN),
  );

  return {
    isOplitAdmin,
    isClientSuperAdmin,
    isGroupAdmin,
  };
});
