<script setup lang="ts">
import {toRefs} from "vue";

import {FChip} from "@/components/Global/Homemade/Commons";
import {SchedulingOperation} from "@/interfaces";
import {
  priorityColorsBG,
  priorityColorsText,
} from "@/tscript/utils/schedulingUtils";
import {TEST_IDS} from "@/config/constants";

const props = withDefaults(
  defineProps<{
    op: SchedulingOperation;
    compact?: boolean;
    readOnly?: boolean;
    zIndex?: number;
  }>(),
  {zIndex: 2000},
);
const {op} = toRefs(props);
const emit = defineEmits<{
  (e: "change-priority", newPriority: string): void;
}>();

const getPriorityColorsBG = (priority: string) => {
  if (!priority) return "";
  return priorityColorsBG(priority);
};
const getPriorityColorsText = (priority: string) => {
  if (!priority) return "";
  return priorityColorsText(priority);
};
const selectPriority = (n: number) => {
  emit("change-priority", `P${n}`);
};
const deletePriority = () => {
  emit("change-priority", "");
};
</script>

<template>
  <v-menu
    :disabled="readOnly"
    content-class="operation-priority-menu-wrapper"
    max-height="110px"
    :z-index="zIndex"
    data-testid="operation-priority-button"
  >
    <template v-slot:activator="{props}">
      <div
        :class="[
          'operation-priority-button-wrapper',
          {compact, 'no-pointer': readOnly},
        ]"
      >
        <FChip
          v-bind="props"
          x-small
          :color="getPriorityColorsBG(op.fast_track)"
          :text-color="getPriorityColorsText(op.fast_track)"
          class="operation-priority-chip__activator"
        >
          {{
            op.fast_track ||
            $t(`scheduling.${readOnly ? "no_priority" : "add_priority"}`)
          }}
          <vue-feather
            v-if="!readOnly && !!op.fast_track"
            class="chip-prepend-icon ml-1"
            type="x"
            tag="div"
            size="14px"
            @click.stop="deletePriority"
          />
        </FChip>
      </div>
    </template>
    <v-list density="compact" data-testid="operation-priority-menu-content">
      <v-list-item v-for="n in 9" :key="n">
        <FChip
          x-small
          :color="getPriorityColorsBG('P' + n)"
          :text-color="getPriorityColorsText('P' + n)"
          :data-testid="`${TEST_IDS.OPERATION_PRIORITY_CHIP__CHIP_PREFIX}${n}`"
          @click="() => selectPriority(n)"
        >
          {{ "P" + n }}
        </FChip>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped lang="scss">
.operation-priority-menu-wrapper {
  & .v-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  & .v-list-item {
    padding: 0;
    min-height: unset;
  }
}
.operation-priority-button-wrapper {
  & .v-chip {
    padding: 0 8px;
  }
  &.compact .v-chip {
    padding: 0 4px;
  }
  &.no-pointer {
    pointer-events: none;
  }
}
</style>
