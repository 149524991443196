import {OplitUserRole} from "@/interfaces";
import {FAST_TRACK, OF_STATUS, type Mesh} from "@oplit/shared-module";

const SCHEDULING_MAX_DISPLAYED_OPS = 2;
const GRAPH_MAX_LOADS_DISPLAYED = 5;
const FILTERS_MAX_PREVIEWED_CHIPS = 4;
const TAG_SEPARATOR = "^^";
const REGEXP_ESCAPED_TAG_SEPARATOR = "\\^\\^";
const FORM_SIMULATIONS_SELECT_EMPTY = "_form_empty_simulation";
const DATE_DEFAULT_FORMAT = "YYYY-MM-DD";
const GLOBAL_TABS_QUERY_PARAMETER = "gtab";
const GLOBAL_TAB_SIMULATION_MANUFACTURING = "manufacturing";
const GLOBAL_TAB_SIMULATION_DEMAND = "demand";
const GLOBAL_TAB_SIMULATION_STOCKS = "stocks";
const GLOBAL_TAB_SCHEDULING_PLANNING_CALENDAR = "calendar";
const GLOBAL_TAB_SCHEDULING_PLANNING_CAPACITY = "capacity";
const GLOBAL_TAB_SCHEDULING_PLANNING_OFS = "ofs";
const GLOBAL_TAB_SCHEDULING_PILOTING_IN_PROGRESS = "in_progress";
const GLOBAL_TAB_SCHEDULING_PILOTING_PROGRESS_TRACKING = "progress_tracking";
const GLOBAL_TAB_ADMIN_CLIENTS = "clients-admin";
const GLOBAL_TAB_ADMIN_TEAMS = "teams-admin";
const GLOBAL_TAB_ADMIN_CAPA = "capa-admin";
const GLOBAL_TAB_ADMIN_GROUPS = "group-admin";
const GLOBAL_TAB_ADMIN_EMAILS = "emails-admin";
const GLOBAL_TAB_ADMIN_ACCOUNT_MANAGER = "account-manager-admin";
const GLOBAL_TAB_ANALYTICS_PRODUCTION = "analytics-production";
const GLOBAL_TAB_ANALYTICS_PLANNING = "analytics-planning";
const GLOBAL_TAB_ANALYTICS_LOGISTICS = "analytics-logistics";
const FEEDBACK_COMPONENTS_DEFAULT_TYPE = "informative";
const GLOBAL_TAB_PARAMETERS_LOCAL = "parameters-by-sector";
const GLOBAL_TAB_PARAMETERS_IMPORTS = "parameters-imports";
const GLOBAL_TAB_PARAMETERS_GLOBAL = "parameters-general";
const GLOBAL_TAB_ANALYTICS_CROSSING_TIMES = "analytics-crossing-times";

/*
Niveau 1 : 200ms
Niveau 2 : 1000ms
Niveau 3 : 5000ms
Niveau 4 : 30000ms
*/
const RESPONSE_TIME_LEVELS = [200, 1000, 5000, 30000];

const GRAPH_LEGEND_GROUP_NAMES = {
  CAPACITY: "capacities",
  LOAD: "loads",
  DONE_DELAYED: "doneAndDelayed",
};

const PARSING_RULES_MATCHING_KEYS = {
  op_status: OF_STATUS,
  fast_track: FAST_TRACK,
} as const;

const SIMULATION_STATUS = {
  ARCHIVED: "archived",
  ACTIVE: "active",
  REMOVED: "removed",
};

const FILTER_TYPE = {
  ARRAY: "array",
  STRING: "string",
};

const ACTION_STATUS = {
  TODO: "a-faire",
  DONE: "fait",
};

const EVENT_STATUS = {
  ACTIVE: "active",
  ADDED: "added",
  DISABLED: "disabled",
  REMOVED: "removed",
  UPDATED: "updated",
};

const QUALIFICATIONS_CHARGE_SERIE = {
  ADDITION: "ajout_charge",
  OVERWRITE: "overwrite_charge",
  MODIFICATION: "modifier",
  DELETION: "supprimer",
  TRANSFER: "charge",
};

const QUALIFICATIONS_CAPA_SERIE = {
  VALIDATED_CAPA: "capa_validee",
  MACHINE_ASSIGNMENT: "machine_assignment",
  OTHER: "other",
};

const QUALIFICATIONS_PLANNING_ACTION = {
  ASSIGNMENT: "Affectation",
  ABSENCE: "Absence",
  MACHINE_STOP: "Arrêt machine",
  MACHINE_ADD: "Ajout machine",
};

const USER_ROLES: Record<OplitUserRole, OplitUserRole> = {
  CLIENT_USER: "CLIENT_USER",
  CLIENT_ADMIN: "CLIENT_ADMIN",
  CLIENT_SUPER_ADMIN: "CLIENT_SUPER_ADMIN",
  GROUP_ADMIN: "GROUP_ADMIN",
  ADMIN: "ADMIN",
};

const LOCAL_STORAGE = {
  USER_DATA: "oplit-local-user-data",
};

//the order is important below:
const LOAD_CHART_COLOR_PALETTE = [
  "newPrimaryRegular",
  "newOrangeLight1",
  "newPurpleDark2",
  "newOrangeDark2",
  "newGreenLight1",
  "newPurpleLight2",
];

// CSS-related constants
const CSS_FLUX_SELECTED_OP_CLASS = "selected-op";
const CSS_OPERATION_CARD_CLASS = "operation-card";
const CSS_OPERATION_CARD_SELECTED_CLASS = "selected-card";
const CSS_OPERATIONS_CARDS_GROUP_SELECTED_CLASS = "selected-cards-group";
const CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS = "click-outside-operation";

const PROJECTED_DELAY_METHODS = {
  goal: "goal",
  demonstrated: "demonstrated",
};

const OPLIT_CONTAINER_DEFAULT_GAP = "var(--g-vertical-spacing)";

const PDP_DEFAULT_SIDEBAR_FIELDS = [
  "name",
  "unite",
  "formula",
  "capa_cible",
  "capa_validee",
  "aggregation_method",
  "cmj_stock",
  "target_stock",
  "cmj_wip",
  "target_wip",
  "min_wip",
  "max_wip",
  "absences",
  "arrets",
];

const CUSTOM_COLOR_DROPDOWN_COLOR_PALETTE = [
  "newDarkJungleGreen",
  "newDisableText",
  "newPrimaryRegular",
  "newPurpleRegular",
  "newDarkBlueGray",
  "newEnglishLavender",
  "newByzantine",
  "newShockingPinkCrayola",
  "newPinkRegular",
  "newMaximumRed",
  "newOrangeSoda",
  "newOrangeRegular",
  "newSatinSheenGold",
  "newYellowRegular",
  "newYellowGreen",
  "newGreenRegular",
  "newLightGrey",
  "newPersianGreen",
  "newTurquoise",
];

const PROPS_FDROPDOWN_ITEM_VALUE_DEFAULT = "id";

const ENVIRONMENT_ID_DEV = "dev";
const ENVIRONMENT_ID_STAGING = "staging";
const ENVIRONMENT_ID_DEMO = "demo";
const ENVIRONMENT_ID_PROD = "prod";
const CONFIG_PER_CLIENT_DEFAULT = "default";

const STORED_ENTITY_STATUS = {
  ACTIVE: "active",
  REMOVED: "removed",
};

const GENERIC_TABLE_HEADERS_TYPES = {
  TEXT: "text",
  DATE: "date",
  OF_DELAY: "of_delay",
  STAGNATION: "stagnation",
  DELAY: "delay",
  NUMBER: "number",
};

const MESHES = ["year", "quarter", "month", "week", "day"] as Mesh[];

const ENTITY_DETAILS_TOOLTIP__DEFAULT_ICON_PROPS = {
  tag: "div",
  type: "info",
  size: "16px",
};

const EMPTY_SECTORS_PLACEHOLDER_ACTIVATOR_ID =
  "empty-sectors-placeholder-activator-id";

export {
  GRAPH_MAX_LOADS_DISPLAYED,
  FILTERS_MAX_PREVIEWED_CHIPS,
  TAG_SEPARATOR,
  REGEXP_ESCAPED_TAG_SEPARATOR,
  FORM_SIMULATIONS_SELECT_EMPTY,
  DATE_DEFAULT_FORMAT,
  RESPONSE_TIME_LEVELS,
  GRAPH_LEGEND_GROUP_NAMES,
  OF_STATUS,
  PARSING_RULES_MATCHING_KEYS,
  SIMULATION_STATUS,
  FILTER_TYPE,
  ACTION_STATUS,
  QUALIFICATIONS_CHARGE_SERIE,
  QUALIFICATIONS_CAPA_SERIE,
  QUALIFICATIONS_PLANNING_ACTION,
  USER_ROLES,
  LOCAL_STORAGE,
  LOAD_CHART_COLOR_PALETTE,
  CSS_FLUX_SELECTED_OP_CLASS,
  CSS_OPERATION_CARD_CLASS,
  CSS_OPERATION_CARD_SELECTED_CLASS,
  CSS_OPERATIONS_CARDS_GROUP_SELECTED_CLASS,
  CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
  GLOBAL_TABS_QUERY_PARAMETER,
  GLOBAL_TAB_SIMULATION_MANUFACTURING,
  GLOBAL_TAB_SIMULATION_DEMAND,
  GLOBAL_TAB_SIMULATION_STOCKS,
  GLOBAL_TAB_SCHEDULING_PLANNING_CALENDAR,
  GLOBAL_TAB_SCHEDULING_PLANNING_CAPACITY,
  GLOBAL_TAB_SCHEDULING_PLANNING_OFS,
  GLOBAL_TAB_SCHEDULING_PILOTING_IN_PROGRESS,
  GLOBAL_TAB_SCHEDULING_PILOTING_PROGRESS_TRACKING,
  GLOBAL_TAB_ANALYTICS_PRODUCTION,
  GLOBAL_TAB_ANALYTICS_PLANNING,
  GLOBAL_TAB_ANALYTICS_LOGISTICS,
  GLOBAL_TAB_PARAMETERS_GLOBAL,
  GLOBAL_TAB_PARAMETERS_LOCAL,
  GLOBAL_TAB_PARAMETERS_IMPORTS,
  PROJECTED_DELAY_METHODS,
  SCHEDULING_MAX_DISPLAYED_OPS,
  FEEDBACK_COMPONENTS_DEFAULT_TYPE,
  EVENT_STATUS,
  OPLIT_CONTAINER_DEFAULT_GAP,
  PDP_DEFAULT_SIDEBAR_FIELDS,
  CUSTOM_COLOR_DROPDOWN_COLOR_PALETTE,
  PROPS_FDROPDOWN_ITEM_VALUE_DEFAULT,
  ENVIRONMENT_ID_DEV,
  ENVIRONMENT_ID_STAGING,
  ENVIRONMENT_ID_DEMO,
  ENVIRONMENT_ID_PROD,
  CONFIG_PER_CLIENT_DEFAULT,
  GLOBAL_TAB_ADMIN_CLIENTS,
  GLOBAL_TAB_ADMIN_TEAMS,
  GLOBAL_TAB_ADMIN_CAPA,
  GLOBAL_TAB_ADMIN_GROUPS,
  GLOBAL_TAB_ADMIN_EMAILS,
  GLOBAL_TAB_ADMIN_ACCOUNT_MANAGER,
  STORED_ENTITY_STATUS,
  GENERIC_TABLE_HEADERS_TYPES,
  MESHES,
  ENTITY_DETAILS_TOOLTIP__DEFAULT_ICON_PROPS,
  EMPTY_SECTORS_PLACEHOLDER_ACTIVATOR_ID,
  GLOBAL_TAB_ANALYTICS_CROSSING_TIMES,
};
