<script setup lang="ts">
import {useMainStore} from "@/stores/mainStore";
import {storeToRefs} from "pinia";
import {computed} from "vue";

const props = defineProps<{
  ids?: {fr: string; en?: string};
}>();

const {userData} = storeToRefs(useMainStore());

const locale = computed(() => {
  const {locale} = userData.value;
  if (props.ids && !props.ids[locale]) return "fr";
  return locale;
});

const computedHRef = computed<string>(
  () =>
    `https://oplit.crisp.help/${locale.value}/` +
    (props.ids ? `article/${props.ids[locale.value]}` : ""),
);
</script>

<template>
  <a target="_blank" rel="noopener noreferrer nofollow" :href="computedHRef">
    <slot />
  </a>
</template>
