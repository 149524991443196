<template>
  <FDropdown
    :model-value="currentSimulation"
    :placeholder="$t('Simulation.select_simulation', simulationsList.length)"
    :items="simulationsList"
    :prepend-icon="simulationPrependedIcon"
    :outlined="['newLayerBackground']"
    :helper="simulationHelperText"
    :btn-class="btnClasses"
    item-text="name"
    item-value="id"
    icon-size="20"
    text-class="font-weight-bold text-left"
    tag-included
    return-object
    keep-scrollbar
    list-class="pb-0"
    :data-testid="TEST_IDS.SIMULATION_DROPDOWN"
    @update:model-value="onChangeSimulation"
  >
    <template v-slot:append-item>
      <div
        v-if="canCreateSimulation"
        class="create-new-simulation-btn-container"
        data-sticky
      >
        <v-btn
          variant="outlined"
          class="w-100 no-uppercase"
          id="create-new-simulation-btn"
          :data-testid="TEST_IDS.SIMULATION_DROPDOWN__CREATE_SIMULATION_BUTTON"
          @click="$openSimulationModal(false)"
        >
          <vue-feather type="plus" tag="span" class="mr-3" />
          <span class="fbody-2">
            {{ $t("Simulation.create_new_simulation") }}
          </span>
        </v-btn>
      </div>
    </template>
    <template v-slot:item-append="simulation">
      <div
        :class="{'is-pinned': simulation.is_pinned}"
        class="fd-flex-center simulation-pin"
        data-testid="simulation-dropdown-append-icon"
        @click.stop="() => pinSimulation(simulation, !simulation.is_pinned)"
      >
        <OplitIcon name="pin" size="15" class="pin-icon" />
      </div>
    </template>
  </FDropdown>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import FDropdown from "@/components/Global/Homemade/Dropdowns/FDropdown.vue";
import {Simulation} from "@/interfaces";
import {SIMULATION_STATUS, TEST_IDS} from "@/config/constants";
import {usePGWatcher} from "@/composables/pgComposable";
import {parseDate} from "@oplit/shared-module";
import {isArchivedSimulation} from "@/tscript/utils/simulation";
import {usePermissionsStore} from "@/stores/permissionsStore";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {serverTimestamp} from "firebase/firestore";
import moment from "moment";

import {useMainStore} from "@/stores/mainStore";
import {useSimulationStore} from "@/stores/simulationStore";
import {useSchedulingStore} from "@/stores/schedulingStore";

export default defineComponent({
  components: {
    FDropdown,
  },
  setup() {
    const mainStore = useMainStore();
    const {
      userData,
      simulation,
      isScheduling,
      isDevEnv,
      clientParameters,
      apiClient,
      team,
      teamSimulations,
      sectorTree,
      variables,
    } = storeToRefs(mainStore);

    const simulationStore = useSimulationStore();
    const {setSimulationGlobal} = simulationStore;
    const {simulationStep, viewableSimulationFromRouteOrPermissions} =
      storeToRefs(simulationStore);

    const {currentPermissions} = storeToRefs(usePermissionsStore());

    const {selectedSimulation, teamSchedulingSimulations} = storeToRefs(
      useSchedulingStore(),
    );

    const currentSimulation = computed<Simulation>(() =>
      isScheduling.value ? selectedSimulation.value : simulation.value,
    );
    const btnClasses = computed(() => {
      const classes = "gapped-button simulations-dropdown";
      if (!currentSimulation.value?.only_admin) return classes;
      return `${classes} bg-newLightGrey`;
    });

    return {
      userData,
      simulation,
      isScheduling,
      isDevEnv,
      clientParameters,
      apiClient,
      team,
      teamSimulations,
      sectorTree,
      variables,
      viewableSimulationFromRouteOrPermissions,
      currentPermissions,
      simulationStep,
      selectedSimulation,
      teamSchedulingSimulations,
      setSimulationGlobal,
      last_prod_update: ref<string>(null),
      dateFormat: "DD/MM/YYYY",
      TEST_IDS,
      currentSimulation,
      btnClasses,
    };
  },
  computed: {
    // remove from Planning if removing here
    canCreateSimulation(): boolean {
      if (!this.currentPermissions.general.create_update_simulation)
        return false;

      return (
        !this.isScheduling ||
        !this.clientParameters.unique_scheduling_simulation ||
        this.isDevEnv
      );
    },
    simulationHelperText(): string {
      const helperText = [];

      if (!this.currentSimulation?.status) return;

      if (this.currentSimulation.status === SIMULATION_STATUS.ARCHIVED) {
        helperText.push(
          this.$t(`Simulation.SimulationDropdown.archived_at`, {
            date: this.getSimulationReadableDate("archived_at"),
          }),
        );

        if (this.last_prod_update) {
          helperText.push(
            this.$t(`Simulation.SimulationDropdown.last_prod_update`, {
              date: moment(this.last_prod_update).format(this.dateFormat),
            }),
          );
        }
      } else {
        helperText.push(
          this.$t(`Simulation.SimulationDropdown.start_date`, {
            date: moment(
              this.currentSimulation.forceToday ||
                this.currentSimulation.dateMin,
            ).format(this.dateFormat),
          }),
        );

        helperText.push(
          this.$t(`Simulation.SimulationDropdown.updated_at`, {
            date: this.getSimulationReadableDate("updated_at"),
          }),
        );
      }

      return helperText.join(" - ");
    },
    simulationPrependedIcon(): string {
      if (!this.currentSimulation?.status) return "plus";
      if (this.currentSimulation?.only_admin) return "eye-off";
      switch (this.currentSimulation.status) {
        case "active":
          return "tool";
        default:
          return "check-circle";
      }
    },
    simulationsList(): Simulation[] {
      const baseSimulationsList: Simulation[] =
        (this.isScheduling
          ? this.teamSchedulingSimulations
          : this.teamSimulations) || [];
      const [pinnedSimulations, notPinnedSimulations] =
        baseSimulationsList.reduce<Simulation[][]>(
          ([pinnedSimulations, notPinnedSimulations], simulation) => {
            return simulation.is_pinned
              ? [[...pinnedSimulations, simulation], notPinnedSimulations]
              : [pinnedSimulations, [...notPinnedSimulations, simulation]];
          },
          [[], []],
        );

      const sortedPinnedSimulations = _.sortBy(pinnedSimulations, "updated_at");

      const finalSimulations = [
        ...sortedPinnedSimulations,
        ...notPinnedSimulations,
      ];

      if (this.currentPermissions.general.read_simulation_active)
        return finalSimulations;

      return finalSimulations.filter(isArchivedSimulation);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        const {simulation_id} = this.$route.query;
        if (!simulation_id) return;
        if (this.currentSimulation?.id === simulation_id) return;
        this.synchronizeSelectedSimulationWithQuery();
      },
    },
  },
  created() {
    if (this.simulation?.status === SIMULATION_STATUS.ARCHIVED) this.loadProd();

    // this is only used to trigger `loadProd` on context change
    usePGWatcher(this);
  },
  methods: {
    // returns the last production update for archived simulations
    async loadProd(): Promise<void> {
      const {sectorTree: sector_tree, team} = this;
      const {id: secteur_id, site_id} = sector_tree || {};
      const {id: team_id} = team || {};

      const last_prod_update = await this.apiClient.getLastProdUpdate({
        secteur_id: site_id || secteur_id,
        team_id,
      });

      if (last_prod_update) this.last_prod_update = last_prod_update;
    },
    getSimulationReadableDate(dateKey: string) {
      const {
        currentSimulation: {
          updated_at,
          status,
          // quickfix fallback to updated_at when archived_at is missing
          [dateKey]: dateKeyContent = status === "archived" ? updated_at : null,
        },
        dateFormat,
      } = this;
      if (!dateKeyContent) return;
      return parseDate(dateKeyContent, false, false, dateFormat);
    },
    determineSwitchableSimulation(simulation: Simulation) {
      const {currentPermissions, viewableSimulationFromRouteOrPermissions} =
        this;

      return currentPermissions.general.read_simulation_active ||
        isArchivedSimulation(simulation)
        ? simulation
        : viewableSimulationFromRouteOrPermissions;
    },
    onChangeSimulation(simulation: Simulation): void {
      this.setSimulationGlobal(this.determineSwitchableSimulation(simulation));

      // sets the simulationStep back to 1 on changing to an unarchived silmulation
      if (simulation.status !== SIMULATION_STATUS.ARCHIVED)
        this.simulationStep = 1;
    },
    synchronizeSelectedSimulationWithQuery(): void {
      const {viewableSimulationFromRouteOrPermissions} = this;

      if (viewableSimulationFromRouteOrPermissions)
        this.setSimulationGlobal(viewableSimulationFromRouteOrPermissions);
      else delete this.$route.query.simulation_id;
    },
    async pinSimulation(simulation: Simulation, is_pinned: boolean) {
      const {userData} = this;
      const {first_name, last_name, id: user_id} = userData || {};

      const updatedFields = {
        is_pinned,
        updated_at: serverTimestamp(),
        updated_by: {first_name, last_name, user_id},
        pg_parsed: moment.utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
      };

      const error = await dbHelper.setDataToCollection(
        "simulations",
        simulation.id,
        updatedFields,
        true,
      );
      if (!error) this.$openSnackbar(null, "SAVE_SUCCESS");
      else this.$openSnackbar(null, "GENERIC_ERROR");
    },
  },
});
</script>

<style scoped lang="scss">
.create-new-simulation-btn-container {
  position: sticky;
  bottom: 0;
  padding: 8px;
  background: rgb(var(--v-theme-newLayerBackground));
  & .v-btn--variant-outlined {
    border-color: rgb(var(--v-theme-newSelected));
  }
}
.simulation-pin {
  opacity: 0;
  & .pin-icon {
    fill: transparent;
    stroke: black;
  }
  &:hover .pin-icon {
    stroke: rgb(var(--v-theme-newPrimaryRegular));
  }
  &.is-pinned {
    opacity: 1;
    & .pin-icon {
      fill: rgb(var(--v-theme-newPrimaryRegular));
      stroke: rgb(var(--v-theme-newPrimaryRegular));
    }
  }
}
.v-list-item:hover .simulation-pin {
  opacity: 1;
}
.v-list-item--active .simulation-pin .pin-icon {
  stroke: white;
}
</style>
