<script lang="ts">
import {h, PropType, VNode, FunctionalComponent} from "vue";
import NavListe from "./NavListe.vue";
import {
  levelCorresp,
  merca_levels,
  getSortedPerimeters,
} from "@oplit/shared-module";
import {Sector} from "@/interfaces";

type Level = {
  type: string;
  level: number;
  collection: string;
  displayName?: string;
  data_type?: string;
};

type NavListeMiddleWareProps = {
  key?: string;
  field?: string;
  index?: number;
  niveau?: number;
  secteur?: Sector;
  level?: Level;
  hideNbChildren?: boolean;
  multiSelect?: boolean;
  singleSelect?: boolean;
  hasChildSelected?: boolean;
  disableBlocked?: boolean;
  canSelectAll?: boolean;
  perimetres?: Record<string, Sector[]>;
  mercateamSectors?: boolean;
  onlySelectLastLevel?: boolean;
  toggle?: (sector: Sector, level: {level: number}, field?: string) => void;
  onItemSelected?: (
    sector: Sector,
    level: {level: number},
    field?: string,
    remove?: boolean,
  ) => void;
  onSelectAll?: (
    sector: Sector,
    level: {level: number},
    hasChildSelected?: boolean,
  ) => void;
};

const NavListeMiddleWare: FunctionalComponent<NavListeMiddleWareProps> = (
  props,
) => {
  const deployChildren = (
    props: NavListeMiddleWareProps,
    ligne: Sector,
    existingArr: VNode[],
  ) => {
    if (ligne?.children && ligne[props.field || "open"]) {
      let newArr = existingArr;

      const levelStructure: {
        collection: string;
        type: string;
        level: number;
      }[] = props.mercateamSectors ? merca_levels : levelCorresp;
      const newChildren: Sector[] = [];
      ligne.children.forEach((subligne) => {
        if (!subligne || ["removed", "deleted"].includes(subligne.status))
          return false;
        if (subligne.is_machine) return newChildren.push(subligne); //This is to handle the case where we want to display machine_tags under their affected sectors
        const level = levelStructure.find(
          (x) => x.collection === subligne.collection,
        );
        const match = props.perimetres[level?.collection]?.find(
          (x) => x?.id === subligne.id,
        );
        if (!match) return;
        newChildren.push(match);
      });
      const children = getSortedPerimeters<Sector>(newChildren);

      children.forEach((subligne, index) => {
        if (!subligne) return;
        const level = levelStructure.find(
          (x) => x.collection === subligne.collection,
        );
        const children = (subligne.children || [])
          .filter(
            (x) =>
              (x?.collection || x?.is_machine) &&
              !["removed", "deleted"].includes(x.status),
          )
          .map((x) => {
            if (x.is_machine) return x;
            return (props.perimetres[x.collection] || []).find(
              (y) => y.id === x.id,
            );
          })
          .filter(Boolean);

        let hasChildSelected = false;
        if ((props.multiSelect || props.canSelectAll) && children?.length) {
          levelStructure.forEach((niveau) => {
            hasChildSelected =
              hasChildSelected ||
              (niveau.level > subligne.level &&
                (props.perimetres[niveau?.collection] || []).some(
                  (x) =>
                    !["removed", "deleted"].includes(x.status) &&
                    x[subligne.type + "_id"] === subligne.id &&
                    x.selected,
                ));
          });
        }
        let newProps = {
          ...props,
          secteur: {...subligne, children},
          level,
          niveau: props.niveau + 1,
          index,
          hasChildSelected,
        };
        newArr.push(h(NavListe, newProps));
        if (subligne?.children && subligne[props.field || "open"]) {
          const arrChildren = deployChildren(newProps, subligne, []);
          newArr = [...newArr, ...arrChildren];
        }
      });
      return newArr;
    } else return existingArr;
  };

  const first = h(NavListe, props);

  return deployChildren(props, props.secteur, [first]);
};

NavListeMiddleWare.props = {
  key: String,
  field: {type: String, default: "open"},
  index: Number,
  niveau: Number,
  secteur: Object as PropType<Sector>,
  level: Object as PropType<Level>,
  hideNbChildren: {type: Boolean, default: false},
  multiSelect: {type: Boolean, default: false},
  singleSelect: {type: Boolean, default: false},
  hasChildSelected: {type: Boolean, default: false},
  disableBlocked: {type: Boolean, default: false},
  canSelectAll: {type: Boolean, default: false},
  perimetres: {type: Object as PropType<Record<string, Sector[]>>, default: {}},
  mercateamSectors: {type: Boolean, default: false},
  onlySelectLastLevel: {type: Boolean, default: false},
  toggle: Function as PropType<
    (sector: Sector, level: {level: number}, field?: string) => void
  >,
  onItemSelected: Function as PropType<
    (
      sector: Sector,
      level: {level: number},
      field?: string,
      remove?: boolean,
    ) => void
  >,
  onSelectAll: {
    type: Function as PropType<
      (
        sector: Sector,
        level: {level: number},
        hasChildSelected?: boolean,
      ) => void
    >,
    default: () => void 0,
  },
};

export default NavListeMiddleWare;
</script>
