import moment from "moment";
import _ from "lodash";
import loggerHelper from "@/tscript/loggerHelper";
import {
  STOCKS_PROD_OBJ_TYPES,
  STOCKS_GRAPH_OBJECTIVE_OBJECT,
  STOCKS_GRAPH_DEMANDS_OBJECT,
  STOCKS_GRAPH_STOCKS_OBJECT,
  STOCKS_LOWEST_LEVEL_FIELD_NAME,
} from "@/config/constants";
import {weeksArrayFromPeriod} from "@oplit/shared-module";
import {n} from "@/tscript/utils/generalHelpers";
import {
  Article,
  GenericConfigObject,
  Demand,
  Poste,
  Product,
  StocksFilter,
  StocksGroupBy,
  ProductsParams,
  LoadStocksParameters,
  SetSelectedProductParameters,
} from "@/interfaces";
import {defineStore, storeToRefs} from "pinia";
import {ref, computed, unref} from "vue";
import {getWeightedKey} from "@/components/Simulation/Stocks/helpers";
import {useInternationalizedConstants} from "@/composables/i18n/useInternationalizedConstants";
import {useStocksUtils} from "@/composables/stocks/useStocksUtils";
import {useMainStore} from "@/stores/mainStore";
import {useAvailableLoadQuantityFields} from "@/composables/load/useAvailableLoadQuantityFields";

export const useStocksStore = defineStore("stocks", () => {
  const mainStore = useMainStore();
  const {DEFAULT_STOCKS_FILTER_BY, STOCKS_GROUP_BY_OPTIONS} =
    useInternationalizedConstants();
  const {groupDemands} = useStocksUtils();
  const {currentDemandsLoadQuantityField, currentStocksLoadQuantityField} =
    useAvailableLoadQuantityFields();

  const selectedProduct = ref(null);
  const products = ref<Product[]>(null);
  const demands = ref<Demand[]>(null);
  const productsParams = ref<ProductsParams>(null);
  const stocksBreadcrumbs = ref<GenericConfigObject[]>([]);
  const stocksGroupBy = ref<StocksGroupBy>(STOCKS_GROUP_BY_OPTIONS[0]);
  const stocksFilterBy = ref<StocksFilter>(DEFAULT_STOCKS_FILTER_BY);
  const demandBreadcrumbs = ref<GenericConfigObject[]>([]);
  const isLoadingStocks = ref(false);
  const demandGroupBy = ref<GenericConfigObject[]>([]);
  const availableUnits = ref<{
    unite_of: string;
    unite_op: string;
    unite_op_2?: string;
  }>(null);

  const getIsLoadingStocks = computed(() => isLoadingStocks.value);
  const getGroupedDemands = computed<Demand[]>(() => {
    const {userData} = storeToRefs(mainStore);
    const {client_id} = userData.value || {};
    return groupDemands(
      demands.value,
      demandGroupBy.value,
      demandBreadcrumbs.value,
      client_id,
    );
  });
  const getStocksFilteredProductsLastOp = computed<Product[]>(() => {
    return getStocksProducts.value.filter(
      (product: Product) => product.is_last_operation && !product.is_semi_fini,
    );
  });
  const getStocksSelectedProduct = computed<Product>(
    () => selectedProduct.value,
  );
  const getStocksProducts = computed<Product[]>(() => {
    const {field: lastBreadcrumbItemField, value: lastBreadcrumbItemValue} =
      getStocksBreadcrumbs.value[getStocksBreadcrumbs.value.length - 1] || {};
    return (products.value || []).map((p) => {
      const is_semi_fini =
        lastBreadcrumbItemField === "article_id" &&
        p.article_id !== lastBreadcrumbItemValue;
      return {...p, is_semi_fini};
    });
  });
  const getStocksDemands = computed<Demand[]>(() => demands.value);

  // Would both need to be renamed at some point
  const isSecondaryStocksUnit = computed(
    () => currentStocksLoadQuantityField.value !== "quantite_of",
  );
  const isSecondaryDemandsUnit = computed(
    () => currentDemandsLoadQuantityField.value !== "quantite_of",
  );

  const getStocksProductsCMJ = computed(() => {
    if (isSecondaryStocksUnit.value) return "n/a";
    if (!products.value?.length) return 0;
    return _.round(
      _.sumBy(
        getStocksFilteredProductsLastOp.value,
        (o: Product) => +(o.cmj || 0),
      ),
      0,
    );
  });
  const getStocksProductsDemand = computed(() => {
    if (isSecondaryStocksUnit.value) return "n/a";
    if (!products.value?.length) return 0;
    return _.round(
      _.sumBy(
        getStocksFilteredProductsLastOp.value,
        (o: Product) => +(o.demande || 0),
      ),
      0,
    );
  });
  const getStocksProductsObjective = computed(() => {
    if (!products.value?.length) return 0;
    return _.round(
      _.sumBy(
        getStocksFilteredProductsLastOp.value,
        (o: Product) =>
          +o[getWeightedKey("objective", isSecondaryStocksUnit.value)] || 0,
      ),
      0,
    );
  });
  const getStocksDelta = computed(() => {
    // returns the delta of stock
    if (!products.value?.length) return 0;
    const initialStock = _.sumBy(
      getStocksFilteredProductsLastOp.value,
      (o: Product) =>
        +o[getWeightedKey("initial_stock", isSecondaryStocksUnit.value)] || 0,
    );
    const finalStock = _.sumBy(
      getStocksFilteredProductsLastOp.value,
      (o: Product) =>
        +o[getWeightedKey("final_stock", isSecondaryStocksUnit.value)] || 0,
    );
    return _.round(finalStock - initialStock, 0);
  });
  const getWIPDelta = computed(() => {
    // returns the delta of wip
    if (!products.value?.length) return 0;
    const initialWIP = _.sumBy(
      getStocksFilteredProductsLastOp.value,
      (o: Product) =>
        +o[getWeightedKey("wip", isSecondaryStocksUnit.value)] || 0,
    );
    const finalWIP = _.sumBy(
      getStocksFilteredProductsLastOp.value,
      (o: Product) =>
        +o[getWeightedKey("final_wip", isSecondaryStocksUnit.value)] || 0,
    );
    return _.round(finalWIP - initialWIP, 0);
  });
  const getStocksBreadcrumbs = computed(() => stocksBreadcrumbs.value);
  const getDemandBreadcrumbs = computed(() => demandBreadcrumbs.value);
  const getDemandGroupBy = computed(() => demandGroupBy.value);
  const getStocksGroupBy = computed(() => stocksGroupBy.value);
  const getStocksFilterBy = computed(() => stocksFilterBy.value);
  const getIsGroupedStocks = computed(
    () =>
      stocksGroupBy.value?.field &&
      stocksGroupBy.value.field !== STOCKS_LOWEST_LEVEL_FIELD_NAME,
  );
  const getAvailableUnits = computed(() => availableUnits.value);

  async function saveProduct(product: Product): Promise<boolean> {
    const {apiClient} = storeToRefs(mainStore);
    try {
      const {article_id, secteur_id, stock_cible, num_sequence} = product;

      const response = await apiClient.value.postRequest(
        "/api/stocks/set_target_stock",
        {
          article_id,
          secteur_id,
          num_sequence,
          stock_cible: n(stock_cible, 0),
        },
      );

      return response.success;
    } catch (e) {
      return false;
    }
  }

  async function loadStocksProducts(parameters: LoadStocksParameters) {
    const {silent = false, refresh = false} = parameters ?? {};
    const {apiClient, userData, simulation, siteId, stations} =
      storeToRefs(mainStore);
    const client_id = unref(userData)?.client_id;
    // .silent can be passed within the payload to prevent the various logics associated with getIsLoadingStocks
    isLoadingStocks.value = !silent;

    /**
     * if the @parameters contains {refresh: true}, we use the previously stored
     * query parameters to refresh the results
     */
    const params = refresh
      ? productsParams.value || {}
      : {
          ...parameters,
          simulation_id: simulation.value?.id || null,
          site_id: siteId.value,
          query_type: "stocks",
          client_id,
        };
    productsParams.value = params;
    const productsResponse = await apiClient.value.pgCustom({
      ...params,
      group_by: getStocksGroupBy.value,
      breadcrumbs: getStocksBreadcrumbs.value,
    });

    if (selectedProduct.value) {
      selectedProduct.value = {
        ...productsResponse.find(
          (product: Product): boolean =>
            product.id === selectedProduct.value.id,
        ),
      };
    }

    products.value = Array.from(productsResponse || [], (product: Product) => {
      /**
       * the below block has been added to retrieve information about the entities in relation to this product
       * currently only required for the grouped view of the stocks table
       */
      const {secteur_id, parent_id} = product;
      const sectorMatch = stations.value.find(
        (poste: Poste) => poste.id === secteur_id,
      );
      if (!sectorMatch) return;
      const {name: secteur_name} = sectorMatch;
      const parentMatch = stations.value.find(
        (poste: Poste) => poste.id === parent_id,
      );
      /***/
      return {
        ...product,
        secteur_name,
        parent_name: parentMatch?.name,
      };
    }).filter(Boolean);

    isLoadingStocks.value = false;
  }

  const debouncedLoadStocksProducts = _.debounce(loadStocksProducts, 200);

  async function loadStocksDemands(parameters: LoadStocksParameters) {
    const {userData, simulation, siteId, apiClient} = storeToRefs(mainStore);
    const {client_id} = userData.value || {};
    isLoadingStocks.value = true;

    const params = {
      ...parameters,
      simulation_id: simulation.value?.id || null,
      site_id: siteId.value,
      query_type: "demands",
      client_id,
    };
    const demandsResponse = await apiClient.value.pgCustom(params);
    demands.value = demandsResponse;

    isLoadingStocks.value = false;
  }

  async function changeProductionObjective({type, products, date, value}) {
    if (!Object.values(STOCKS_PROD_OBJ_TYPES).includes(type)) return;
    if (!products?.length) return;
    const {userData, simulation, siteId, apiClient} = storeToRefs(mainStore);
    const {client_id} = userData.value || {};

    const {id: simulation_id} = simulation.value || {};

    const [startDate, endDate] = date;

    try {
      await apiClient.value.setStocksTarget({
        type,
        value, // contains manually set value
        products,
        client_id,
        simulation_id,
        startDate,
        endDate,
        site_id: siteId.value,
      });

      loadStocksProducts({refresh: true, silent: true});
    } catch (e) {
      loggerHelper.error(e);
    }
    return;
  }

  async function setSelectedProduct(settings: SetSelectedProductParameters) {
    const {userData, simulation, siteId, apiClient} = storeToRefs(mainStore);
    const {client_id} = userData.value || {};
    const {product, params} = settings || {};
    const {article_id, secteur_id} = product || {};
    const {id: simulation_id} = simulation.value;
    const {period} = params || {};
    const [startDate, endDate] = period || [];

    if (product) {
      const commonParams = {
        client_id,
        article_id,
        simulation_id,
        startDate,
        endDate,
        sector: {secteur_id, site_id: siteId.value},
      };

      const promises = [];
      //1 Get the demand
      promises.push(
        apiClient.value
          .pgCustom({
            ...commonParams,
            query_type: "single_product_stocks",
          })
          .catch(loggerHelper.log),
      );
      //2 Get the objective
      promises.push(
        apiClient.value
          .pgCustom({
            ...commonParams,
            query_type: "article_objective",
          })
          .catch(loggerHelper.log),
      );

      const result = await Promise.all(promises);

      const [{demand}, dailyObjectives] = result;
      //parse results
      //Demand
      const groupByWeek: Record<number, Article[]> = _.groupBy(
        demand || [],
        (o: Demand) => moment(o.day_date).format("W"),
      );
      const demandByWeek: Record<number, number> = {};
      Object.keys(groupByWeek).forEach((week: string) => {
        demandByWeek[week] = _.round(
          _.sumBy(
            groupByWeek[week],
            (o: {quantite_of: string}) => +o.quantite_of || 0,
          ),
          2,
        );
      });
      product[STOCKS_GRAPH_DEMANDS_OBJECT] = demandByWeek;

      //objectives
      const groupObjectivesByWeek: Record<
        number,
        Array<{day_date: string; load: string}>
      > = _.groupBy(dailyObjectives || [], (o: Article) =>
        moment(o.day_date).format("W"),
      );
      const objectiveByWeek: Record<number, number> = {};
      Object.keys(groupObjectivesByWeek).forEach((week: string) => {
        objectiveByWeek[week] = _.round(
          _.sumBy(groupObjectivesByWeek[week], (o: Article) => +(o.load || 0)),
          2,
        );
      });
      product[STOCKS_GRAPH_OBJECTIVE_OBJECT] = objectiveByWeek;

      // constructs the stocksObject from the demand & objective
      product[STOCKS_GRAPH_STOCKS_OBJECT] = weeksArrayFromPeriod(period).reduce(
        (
          acc: {[weekNumber: number]: number},
          currentWeek: number,
          index: number,
          currentArray: number[],
        ) => {
          if (!index)
            acc[currentWeek] = _.round(product.initial_stock / product.cmj, 2);
          else {
            const lastValue = _.last(Object.values(acc));
            const previousWeek = currentArray[index - 1];
            const previousDemand =
              product[STOCKS_GRAPH_DEMANDS_OBJECT][previousWeek] ?? 0;
            const previousObjective =
              product[STOCKS_GRAPH_OBJECTIVE_OBJECT][previousWeek] ?? 0;
            acc[currentWeek] = _.round(
              (lastValue * product.cmj - previousDemand + previousObjective) /
                product.cmj,
              2,
            );
          }

          return acc;
        },
        {},
      );
    }

    selectedProduct.value = product;
  }
  function setStocksDemands(newDemands: Demand[]) {
    demands.value = newDemands;
  }

  function setStocksBreadcrumbs(breadcrumbs: GenericConfigObject[]) {
    const {setUserParameter} = mainStore;
    stocksBreadcrumbs.value = breadcrumbs;

    setUserParameter({stocksBreadcrumbs: breadcrumbs});
    debouncedLoadStocksProducts({refresh: true});
  }

  function setDemandBreadcrumbs(breadcrumbs: GenericConfigObject[]): void {
    const {setUserParameter} = mainStore;
    demandBreadcrumbs.value = breadcrumbs;
    setUserParameter({demandBreadcrumbs: breadcrumbs});
  }

  function setDemandGroupBy(groupBy: GenericConfigObject[]): void {
    const {setUserParameter} = mainStore;
    demandGroupBy.value = groupBy;
    demandBreadcrumbs.value = [];
    setUserParameter({
      demandGroupBy: groupBy,
      demandBreadcrumbs: [],
    });
  }

  function setStocksFilterBy(filterBy: StocksFilter): void {
    stocksFilterBy.value = filterBy;
  }

  function setStocksGroupBy(groupBy: StocksGroupBy): void {
    const {setUserParameter} = mainStore;
    stocksGroupBy.value = groupBy;
    stocksBreadcrumbs.value = [];
    setUserParameter({
      stocksGroupBy: groupBy,
      stocksBreadcrumbs: [],
    });
    debouncedLoadStocksProducts({refresh: true});
  }

  /**
   * initializes state variables from firebase `parametres_user` table
   * the variables are set
   */
  function initializeStocksModuleFromParametresUser(): void {
    const {userParameters} = storeToRefs(mainStore);
    if (!userParameters.value) return;
    const syncStateVariables = [
      "stocksGroupBy",
      "demandGroupBy",
      "stocksBreadcrumbs",
      "demandBreadcrumbs",
    ];
    for (const stateVariable of syncStateVariables) {
      if (userParameters.value[stateVariable]) {
        switch (stateVariable) {
          case "stocksGroupBy":
            setStocksGroupBy(userParameters.value[stateVariable]);
            break;
          case "demandGroupBy":
            setDemandGroupBy(userParameters.value[stateVariable]);
            break;
          case "stocksBreadcrumbs":
            setStocksBreadcrumbs(userParameters.value[stateVariable]);
            break;
          case "demandBreadcrumbs":
            setDemandBreadcrumbs(userParameters.value[stateVariable]);
            break;
          default:
            break;
        }
      }
    }
  }

  return {
    selectedProduct,
    getIsLoadingStocks,
    getGroupedDemands,
    getStocksSelectedProduct,
    getStocksProducts,
    getStocksDemands,
    isSecondaryStocksUnit,
    isSecondaryDemandsUnit,
    getStocksProductsCMJ,
    getStocksProductsDemand,
    getStocksProductsObjective,
    getStocksDelta,
    getWIPDelta,
    getStocksBreadcrumbs,
    getDemandBreadcrumbs,
    getDemandGroupBy,
    getStocksGroupBy,
    getStocksFilterBy,
    getIsGroupedStocks,
    getAvailableUnits,
    saveProduct,
    loadStocksProducts,
    loadStocksDemands,
    changeProductionObjective,
    setSelectedProduct,
    setStocksDemands,
    setStocksBreadcrumbs,
    setDemandBreadcrumbs,
    setDemandGroupBy,
    setStocksFilterBy,
    setStocksGroupBy,
    initializeStocksModuleFromParametresUser,
  };
});
