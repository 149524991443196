import {ref, inject, computed, unref} from "vue";
import {RouteRecordName, useRouter} from "vue-router";
import {defineStore, storeToRefs} from "pinia";
import {serverTimestamp} from "firebase/firestore";
import slugify from "slugify";
import _ from "lodash";
import moment from "moment";
import {i18n} from "@/i18n";
import {useParametersUtils} from "@/composables/parameters/useParametersUtils";
import {useClientsConfigurations} from "@/composables/useClientsConfigurations";
import loggerHelper from "@/tscript/loggerHelper";
import {slugOptions} from "@/tscript/utils/slugOptions";
import {getInitialMSDParameter} from "@/tscript/utils/parameters";
import {
  asyncForEach,
  levelCorresp,
  getLevelCorrespFieldsForSector,
  parseDate,
  toSafeString,
  DATE_DEFAULT_FORMAT,
  MsdData,
  type ParameterSchedulingDisplay,
  type ParametersOfDisplay,
  ImportParsingFieldData,
  ImportParsingFieldCompositeRules,
  ImportDispatchFormulaOperation,
  ImportParsingFieldSimpleRules,
} from "@oplit/shared-module";
import {
  PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING,
  PARAMETERS_DATA_IMPORT_ROUTE_NAME,
  PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING,
  PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING,
} from "@/config/routes";
import {
  PARAMETERS_MANDATORY_CAPA_FIELDS_MODELS,
  PARAMETERS_OPTIONS_VALUES,
} from "@/config/constants";

import type {
  ListOptionLink,
  SectorGroup,
  UnknownError,
  Operator,
  Planning,
  Sector,
  ParametersColorsCategory,
  ParametersColorsCategoryOption,
  OplitCalculusRule,
  MSDField,
  GenericObject,
  MenuItem,
  ShiftPlanningDocument,
  OpenSnackbarFunction,
  Poste,
  ParsingRulesData,
  ParametersSchedulingRule,
  MSD,
  MsdParameter,
  ClientParameter,
  Segment,
  SectorLike,
} from "@/interfaces";
import {useMainStore} from "./mainStore";
import {useCustomerInterfaceStore} from "./customerInterfaceStore";

import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {useDBUtils} from "@/composables/dbUtils";
import {useGlobalTabs} from "@/composables/useGlobalTabs";
import {getMSDData} from "@/tscript/utils/sharedModuleHelper";
import {apiClient} from "@/tscript/utils/requirements";

/**
 * returns common payload for the load functions of the different parameters variables
 */
const getParametersCollectionsLoadCondition = () => {
  const {userData, team} = storeToRefs(useMainStore());
  const {client_id} = userData.value;
  const {id: team_id} = team.value ?? {};

  const whereCondition = [
    {
      field: "client_id",
      value: client_id,
      compare: "==",
    },
  ];
  if (!team_id) return whereCondition;

  return [
    ...whereCondition,
    {
      field: "team_id",
      value: team_id,
      compare: "==",
    },
  ];
};

export const useParameterStore = defineStore("parameters", () => {
  const openSnackbar = inject<OpenSnackbarFunction>("openSnackbar");
  const segment = inject<Segment>("segment");
  const mainStore = useMainStore();
  const {t} = i18n;
  const {GENERAL} = useClientsConfigurations();
  const router = useRouter();
  const {getBaseOplitFirestoreDocument, getOplitFirestoreDocumentUserMetadata} =
    useDBUtils();

  // Initialized with the generic configuration so that the display can happen prior to loading
  const parametersSchedulingDisplay = ref<Partial<ParameterSchedulingDisplay>>(
    GENERAL.default.PARAMETERS_DEFAULT_SCHEDULING_DISPLAY,
  );
  const parametersAllSchedulingDisplaysByTeamId = ref<GenericObject>({
    "": GENERAL.default.PARAMETERS_DEFAULT_SCHEDULING_DISPLAY,
  });
  const parametersSchedulingRules = ref<ParametersSchedulingRule[]>([]);
  const parametersSectorGroups = ref<SectorGroup[]>([]);
  const parametersColorsCategories = ref<ParametersColorsCategory[]>(null);

  //object structure is: secteur_id > machine_tag_id > date > operator_id > {...changes}
  const parametersStdPlanningChanges = ref<GenericObject>({});

  const areEditedColorsCategories = ref<boolean>(false);
  const shouldSaveColorsCategories = ref<boolean>(false);
  const parametersList = ref<MSDField[]>([]);
  const parametersView = ref<ListOptionLink>(null);
  const calculusRules = ref<OplitCalculusRule[]>([]);
  const msdData = ref<MsdData>({});
  const chargeMsdParameters = ref<MsdParameter>({});
  const shiftPlanning = ref<ShiftPlanningDocument>(null);
  // initialized as `null` to differentiate pre and post loading
  const importParsingRules = ref<ParsingRulesData>(null);
  const msdListByClient = ref<GenericObject[]>([]);
  const parametersOfDisplay = ref<ParametersOfDisplay | null>(null);
  const parametersOfDisplayFromProviders = ref<ParametersOfDisplay[]>([]);
  const isEditedOfDisplay = ref<boolean>(false);
  const shouldSaveOfDisplay = ref<boolean>(false);

  const parametersCurrentMenuItems = computed<MenuItem[]>(() => {
    const {globallySelectedTab, globalTabsPerRoute} = useGlobalTabs();
    const {mapParametersMenuItems} = useParametersUtils();

    if (
      globallySelectedTab.value ===
      globalTabsPerRoute.value.parameters.indexOf("parameters-general")
    )
      return mapParametersMenuItems(parametersGeneralMenuItems.value);

    if (
      globallySelectedTab.value ===
      globalTabsPerRoute.value.parameters.indexOf("parameters-by-sector")
    )
      return mapParametersMenuItems(parametersBySectorMenuItems.value);

    if (
      globallySelectedTab.value ===
      globalTabsPerRoute.value.parameters.indexOf("parameters-imports")
    )
      return mapParametersMenuItems(parametersImportMenuItems.value);

    return [];
  });
  const parametersGeneralMenuItems = computed<MenuItem[]>(() => {
    const {
      hasStock,
      hasNomenclature,
      hasFullAccessPDP,
      hasFullAccessScheduling,
      hasFullAccessStock,
      hasFullAccessCustomerInterfaceProviderSide,
      clientParameters,
    } = storeToRefs(mainStore);
    return [
      {
        name: "factory",
        icon: "factory.svg",
        links: [
          {
            name: "factory_structure",
            routeName: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.factoryStructure,
          },
          {
            name: "sectors_groups",
            routeName: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.sectorsGroups,
          },
          {
            name: "operators_list",
            routeName: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.operatorsList,
            condition:
              hasFullAccessPDP.value ||
              hasFullAccessStock.value ||
              clientParameters.value.has_conwip,
          },
          {
            name: "machines_list",
            routeName: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.machinesList,
          },
          {
            name: "oplit_users",
            routeName: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.oplitUsers,
          },
        ],
      },
      {
        name: "capacity",
        icon: "tool",
        links: [
          {
            name: "parameters_list",
            routeName: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.parametersList,
          },
          {
            name: "calculus_rules",
            routeName: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.calculusRules,
          },
          {
            name: "daily_capacity",
            routeName: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.dailyCapacity,
            condition: unref(doesClientHaveShiftPlanning),
          },
        ],
      },
      {
        name: "load",
        icon: "activity",
        links: [
          {
            name: "scheduling_rules",
            routeName: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.schedulingRules,
            condition: hasFullAccessScheduling.value,
          },
          {
            name: "planning_display",
            routeName: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.planningDisplay,
            condition: hasFullAccessPDP.value || hasFullAccessStock.value,
          },
          {
            name: "scheduling_display",
            routeName:
              PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.schedulingDisplay,
            condition: hasFullAccessScheduling.value,
          },
          {
            name: "customer_provider_display",
            routeName:
              PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.customerProviderDisplay,
            condition: hasFullAccessCustomerInterfaceProviderSide.value,
          },
        ],
      },
      {
        name: "finished_products",
        icon: "box",
        links: [
          {
            name: "nomenclature",
            routeName: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.nomenclatures,
            condition: hasNomenclature.value,
          },
          {
            name: "manufacturing_range",
            routeName: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.stocks,
            condition: hasStock.value,
          },
        ],
      },
      {
        name: "configuration",
        icon: "settings",
        links: [
          {
            name: "modules",
            routeName: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.modules,
          },
        ],
      },
    ];
  });
  const parametersBySectorMenuItems = computed<MenuItem[]>(() => {
    const {
      clientParameters,
      hasFullAccessPDP,
      hasFullAccessStock,
      hasFullAccessScheduling,
    } = storeToRefs(mainStore);
    return [
      {
        name: "factory",
        icon: "factory.svg",
        links: [
          {
            name: "sector_information",
            routeName:
              PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.sectorInformation,
          },
        ],
      },
      {
        name: "capacity",
        icon: "tool",
        links: [
          {
            name: "calendar",
            routeName: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.calendar,
          },
          {
            name: "standard_planning",
            routeName:
              PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.standardPlanning,
            condition: hasFullAccessPDP.value || hasFullAccessStock.value,
          },

          {
            name: "calculus_rules",
            routeName: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.calculusRules,
          },
        ],
      },
      {
        name: "load",
        icon: "activity",
        links: [
          {
            name: "scheduling_rules",
            routeName:
              PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.schedulingRules,
            condition: hasFullAccessScheduling.value,
          },
          {
            name: "target_wip",
            routeName: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.targetWIP,
            condition: hasFullAccessScheduling.value,
          },
          {
            name: "load_formula",
            routeName: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.loadFormula,
            condition: !!clientParameters.value?.has_load_formula,
          },
        ],
      },
      {
        name: "stock",
        icon: "database",
        links: [
          {
            name: "target_stock",
            routeName: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.targetStock,
            condition: hasFullAccessPDP.value || hasFullAccessStock.value,
          },
        ],
      },
    ];
  });
  const parametersImportMenuItems = computed<MenuItem[]>(() => [
    {
      name: "factory",
      icon: "factory.svg",
      links: [
        {
          name: "import_data",
          routeName: PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING.importData,
        },
        {
          name: "import_rules",
          routeName: PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING.importRules,
        },
        {
          name: "import_parsing_rules",
          routeName: PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING.importParsingRules,
        },
      ],
    },
  ]);
  const parametersOptions = computed<ListOptionLink[]>(() =>
    Array.from(
      [
        {
          value: PARAMETERS_OPTIONS_VALUES.general,
          routeName: PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.root,
        },
        {
          value: PARAMETERS_OPTIONS_VALUES.by_sector,
          routeName: PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.root,
        },
        {
          value: PARAMETERS_OPTIONS_VALUES.data_import,
          routeName: PARAMETERS_IMPORTS_ROUTES_NAMES_MAPPING.root,
        },
      ],
      ({value, routeName}: Partial<ListOptionLink>): ListOptionLink => ({
        label: t(`Parameters.lists.${value}`) as string,
        value,
        routeName,
      }),
    ),
  );
  const parametersSchedulingColorsCategories = computed<
    ParametersColorsCategory[]
  >(() =>
    parametersColorsCategories.value?.filter(
      ({is_scheduling}) => is_scheduling,
    ),
  );
  const parametersPlanningColorsCategories = computed<
    ParametersColorsCategory[]
  >(() =>
    parametersColorsCategories.value?.filter(
      ({is_scheduling}) => !is_scheduling,
    ),
  );
  const dailyCapacityParameter = computed<MSDField>(() =>
    parametersList.value.find(
      (parameter: MSDField) => parameter.model === "capa_journaliere",
    ),
  );
  const shiftPlanningItems = computed(() => {
    if (!shiftPlanning.value?.shift_planning) return [];
    /**
     * various logic works with the list being sorted asc. on `start_time`
     * if you need a different sorting do it locally, not here
     */
    return shiftPlanning.value.shift_planning.sort((a, b) =>
      a.start_time.localeCompare(b.start_time),
    );
  });
  const doesClientHaveShiftPlanning = computed<boolean>(() => {
    const {clientParameters} = storeToRefs(mainStore);
    return !!unref(clientParameters).has_shift_planning;
  });
  const clientFirstShift = computed(() => unref(shiftPlanningItems).at(0));
  const parametersOfDisplayHiddenColumns = computed<
    ParametersOfDisplay["hidden_columns"]
  >(() => parametersOfDisplay.value?.hidden_columns || []);
  const parametersOfDisplayAddedColumns = computed<
    (ParametersOfDisplay["added_columns"][0] & {label: string})[]
  >(() => {
    const loadFields = importParsingRules.value?.LOAD || {};
    const labelByKeys = Object.entries(loadFields).reduce<
      Record<string, string>
    >(
      (acc, [key, {client_field_name}]) => ({
        ...acc,
        [key]: client_field_name,
      }),
      {},
    );

    return (parametersOfDisplay.value?.added_columns || []).map((col) => ({
      ...col,
      label: labelByKeys[col.key] || col.key,
    }));
  });
  const firstProviderParametersOfDisplayHiddenColumns = computed<
    ParametersOfDisplay["hidden_columns"]
  >(() => parametersOfDisplayFromProviders.value[0]?.hidden_columns || []);
  const firstProviderParametersOfDisplayAddedColumns = computed<
    (ParametersOfDisplay["added_columns"][0] & {label: string})[]
  >(() =>
    (parametersOfDisplayFromProviders.value[0]?.added_columns || []).map(
      (col) => ({
        ...col,
        label: col.key,
      }),
    ),
  );
  const firstProviderParametersOfDisplayColumnsOrder = computed<
    ParametersOfDisplay["columns_order"]
  >(() => parametersOfDisplayFromProviders.value[0]?.columns_order || {});

  async function updateParametersSchedulingDisplay(
    payload: Partial<ParameterSchedulingDisplay>,
  ) {
    const {userData, team} = storeToRefs(mainStore);

    const finalPayload: ParameterSchedulingDisplay = {
      ...payload,
      id:
        payload.id ??
        (await dbHelper.getCollectionId("parameters_scheduling_display")),
      client_id: userData.value.client_id,
    };

    // firebase errors if we try to set a value as `undefined`
    if (team.value?.id) finalPayload.team_id = team.value?.id;

    try {
      await dbHelper.setDataToCollection(
        "parameters_scheduling_display",
        finalPayload.id,
        finalPayload,
        true,
      );

      parametersSchedulingDisplay.value = finalPayload;
      parametersAllSchedulingDisplaysByTeamId.value[team.value?.id || ""] =
        finalPayload;
    } catch (error: unknown) {
      // TODO: rework errors logging system
      openSnackbar(null, "GENERIC_ERROR");
    }
  }
  /**
   * The initial call for this method is made inside `Navbar` on the `team` watcher
   */
  async function loadParametersSchedulingDisplay(): Promise<void> {
    const {userData, clientConfiguration, teams} = storeToRefs(mainStore);
    if (!userData.value) return;
    const {client_id} = userData.value;
    /**
     * these default values depend on the client through the useClientsConfigurations file configurations
     * and are defaulted to a generic value in case there is no specific configuration for the current client
     */
    const defaultSchedulingDisplay: ParameterSchedulingDisplay = {
      ...clientConfiguration.value.PARAMETERS_DEFAULT_SCHEDULING_DISPLAY,
      client_id, // including `client_id` to replicate collection model
    };

    try {
      const scheduling_displays: ParameterSchedulingDisplay[] =
        await dbHelper.getAllDataFromCollectionWithAll(
          "parameters_scheduling_display",
          {
            where: [
              {
                field: "client_id",
                value: client_id,
                compare: "==",
              },
            ],
          },
        );
      const scheduling_display =
        scheduling_displays[0] || defaultSchedulingDisplay;

      parametersSchedulingDisplay.value = scheduling_display;

      //make sure we have one scheduling_displays for each team
      let teamIds = teams.value.map((team) => team.id);
      if (!teamIds.length) teamIds = [""];
      const schedulingDisplaysByTeamId = _.groupBy(
        scheduling_displays,
        (o) => o.team_id || "",
      );
      parametersAllSchedulingDisplaysByTeamId.value = teamIds.reduce(
        (acc, teamId) => {
          acc[teamId] =
            schedulingDisplaysByTeamId[teamId]?.at(0) ||
            defaultSchedulingDisplay;
          return acc;
        },
        {},
      );
    } catch (error) {
      parametersSchedulingDisplay.value = defaultSchedulingDisplay;
      parametersAllSchedulingDisplaysByTeamId.value = {
        "": defaultSchedulingDisplay,
      };
    }
  }

  async function updateParametersColorsCategories(
    payload: ParametersColorsCategory,
  ) {
    const {userData, team} = storeToRefs(mainStore);

    /**
     * removes unnamed options from payload
     */
    const cleanOptions = payload.options?.length
      ? payload.options.filter(
          (option: ParametersColorsCategoryOption) => !!option.name,
        )
      : [];

    const finalPayload: ParametersColorsCategory = {
      ...payload,
      options: cleanOptions,
      id:
        payload.id ??
        (await dbHelper.getCollectionId("parameters_colors_categories")),
      client_id: userData.value.client_id,
    };

    // firebase errors if we try to set a value as `undefined`
    if (team.value?.id) finalPayload.team_id = team.value?.id;

    try {
      await dbHelper.setDataToCollection(
        "parameters_colors_categories",
        finalPayload.id,
        finalPayload,
        true,
      );

      parametersColorsCategories.value = [
        ...parametersColorsCategories.value.filter(
          (colorCategory: ParametersColorsCategory) =>
            colorCategory.id !== finalPayload.id,
        ),
        finalPayload,
      ];
    } catch (error: unknown) {
      // TODO: rework errors logging system
      openSnackbar(null, "GENERIC_ERROR");
    }
  }
  /**
   * the initial call for this method is made inside `Navbar` on the `team` watcher
   */
  async function loadParametersColorsCategories(): Promise<void> {
    const {userData} = storeToRefs(mainStore);
    if (!userData.value) return;

    try {
      const colorsCategories: ParametersColorsCategory[] =
        await dbHelper.getAllDataFromCollectionWithAll(
          "parameters_colors_categories",
          {
            where: getParametersCollectionsLoadCondition(),
          },
        );

      parametersColorsCategories.value = colorsCategories || [];
    } catch (error) {
      loggerHelper.log({error});
    }
  }
  async function deleteParametersColorsCategories(
    payload: ParametersColorsCategory,
  ): Promise<void> {
    if (!payload.id) return;

    try {
      await dbHelper.deleteData("parameters_colors_categories", payload.id);

      parametersColorsCategories.value = [
        ...parametersColorsCategories.value.filter(
          (colorCategory: ParametersColorsCategory) =>
            colorCategory.id !== payload.id,
        ),
      ];
    } catch (error: unknown) {
      // TODO: rework errors logging system
      openSnackbar(null, null, error);
    }
  }

  // FIXME
  async function loadMSDData() {
    const {userData, activeMsd, activeSector} = storeToRefs(mainStore);

    if (!userData.value.client_id) throw new Error("NO_CLIENT_ID");
    if (!activeMsd.value?.id) return;
    if (!activeSector.value?.id || activeSector.value.level === undefined)
      throw new Error("GENERIC_ERROR");

    const msdDataResult = await getMSDData(
      userData.value.client_id,
      activeSector.value,
      activeMsd.value,
    );

    msdData.value = _.get(msdDataResult, 0) || {};
  }
  async function loadMSDChargeData(): Promise<[unknown, MsdData]> {
    const {userData, activeMsd, activeSector} = storeToRefs(mainStore);
    try {
      if (!userData.value.client_id) throw new Error("NO_CLIENT_ID");
      if (!activeMsd.value?.id) return;
      if (!activeSector.value?.id || activeSector.value.level === undefined)
        throw new Error("GENERIC_ERROR");

      const msdChargeDataResult = await getMSDData(
        userData.value.client_id,
        activeSector.value,
        activeMsd.value,
        "secteur_id",
        "charge",
      );

      return [null, msdChargeDataResult.at(0) ?? {}];
    } catch (error) {
      return [error, null];
    }
  }
  async function loadSectorMSDData({
    sector,
    type,
  }: {
    sector: {
      id?: string;
      secteur_id?: string;
      level?: number;
      secteur_level?: number;
    };
    type: "capa" | "charge";
  }) {
    const {userData, activeMsd} = storeToRefs(mainStore);

    if (!userData.value.client_id) throw new Error("NO_CLIENT_ID");
    if (!activeMsd.value?.id) return;
    const sectorId = sector?.id || sector?.secteur_id;
    const sectorLevel = sector?.level || sector?.secteur_level;
    if (!sectorId || sectorLevel === undefined)
      throw new Error("GENERIC_ERROR");
    try {
      const msdDataResult = await getMSDData(
        userData.value.client_id,
        {id: sectorId, level: sectorLevel},
        activeMsd.value,
        "secteur_id",
        type,
      );

      return [null, msdDataResult.at(0) ?? {}];
    } catch (error) {
      return [error, null];
    }
  }

  function resetDefaultPlanningModifications() {
    parametersStdPlanningChanges.value = {};
  }
  function updateDefaultPlanningModifications(
    machineId: string,
    sectorId: string,
    date: string,
    modifiedOperator: GenericObject,
  ) {
    parametersStdPlanningChanges.value = {
      ...parametersStdPlanningChanges.value,
      [sectorId]: {
        ...(parametersStdPlanningChanges.value[sectorId] || {}),
        [machineId]: {
          ...(parametersStdPlanningChanges.value[sectorId]?.[machineId] || {}),
          [date]: {
            ...(parametersStdPlanningChanges.value[sectorId]?.[machineId]?.[
              date
            ] || {}),
            [modifiedOperator.id]: {
              ...(parametersStdPlanningChanges.value[sectorId]?.[machineId]?.[
                date
              ]?.[modifiedOperator.id] || {}),
              ...modifiedOperator,
            },
          },
        },
      },
    };
  }
  async function saveDefaultPlanning() {
    const {userData, apiClient, stations, operators} = storeToRefs(mainStore);
    const {client_id} = userData.value || {};

    const buildPlanningId = (
      operator_id: string,
      machine_tag_id: string,
      secteur_id: string,
      day_of_week: Planning["day_of_week"],
    ): string =>
      slugify(
        [machine_tag_id, operator_id, secteur_id, day_of_week].join("_"),
        slugOptions,
      );

    type PlanningUpdateParams = {
      operator: Operator;
      sector: Poste;
      machine_tag_id: string;
      secteur_id: string;
      day_of_week: Planning["day_of_week"];
      add?: boolean;
    };
    const buildPlaningUpdate = (params: PlanningUpdateParams): Planning => {
      const {
        operator,
        sector,
        machine_tag_id,
        secteur_id,
        day_of_week,
        add = false,
      } = params;
      const {collection, level, type} = sector;
      const {
        id: operator_id,
        name: operator_name,
        assignment_percentage,
        day_part,
      } = operator;
      const id = buildPlanningId(
        operator_id,
        machine_tag_id,
        secteur_id,
        day_of_week,
      );
      const planning: Planning = {
        id,
        client_id,
        operator_id,
        operator_name,
        machine_tag_id,
        secteur_id,
        day_of_week,
        day_part,
        assignment_percentage,
        add,
        collection,
        level,
        type,
        status: "active",
        updated_at: moment.utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
        updated_by: userData.value.id,
      };
      levelCorresp.forEach((level) => {
        if (sector[level.type + "_id"])
          planning[level.type + "_id"] = sector[level.type + "_id"];
      });
      Object.keys(planning).forEach((key: string) => {
        if ([undefined, null].includes(planning[key])) delete planning[key];
      });
      return planning;
    };

    const planningUpdates: Partial<Planning>[] = [];
    const stdPlanningChanges = unref(parametersStdPlanningChanges);
    const impactedSectors: Poste[] = [];
    Object.keys(stdPlanningChanges).forEach((secteur_id: string) => {
      const sectorPlanningChanges = stdPlanningChanges[secteur_id];
      const sector = stations.value.find((x) => x.id === secteur_id);
      if (!sector?.id) return;
      impactedSectors.push(sector); //this will be used for the backend query
      Object.keys(sectorPlanningChanges).forEach((machine_tag_id: string) => {
        const machinePlanningChanges = sectorPlanningChanges[machine_tag_id];
        Object.keys(machinePlanningChanges).forEach((date: string) => {
          const datePlanningChanges = machinePlanningChanges[date];
          const day_of_week = moment(
            date,
          ).isoWeekday() as Planning["day_of_week"];
          Object.keys(datePlanningChanges).forEach((operator_id: string) => {
            const operatorPlanningChanges = datePlanningChanges[operator_id];
            const {add} = operatorPlanningChanges;
            const operator = operators.value.find((x) => x.id === operator_id);
            if (!operator?.id) return;

            const planningChange = buildPlaningUpdate({
              operator: {...operator, ...operatorPlanningChanges},
              sector,
              machine_tag_id,
              secteur_id,
              day_of_week,
              add: add === false ? false : true, //explicit definition to avoir "null" and "undefined" to be considered as false
            });
            planningUpdates.push(planningChange);
          });
        });
      });
    });

    //send data to firestore first
    const promises: Promise<unknown>[] = [];
    const isRealInsert = true;
    await asyncForEach(planningUpdates, async (update: Partial<Planning>) => {
      promises.push(
        // eslint-disable-next-line no-async-promise-executor
        new Promise(async (resolve, reject) => {
          if (!isRealInsert) return resolve(false);
          const error = await dbHelper.setDataToCollection(
            "standard_planning",
            update.id,
            {...update, trigger_function: false},
            true,
          );
          if (error && error?.e) return reject(error.e);
          return resolve(true);
        }),
      );
    });
    const results = await Promise.all(promises);

    //On prépare la requete backend
    //On calcule tous les secteurs impactés
    type SectorToParse = {
      secteur_id: string;
      secteur_collection: string;
      secteur_level: number;
    };
    const sectors = impactedSectors.reduce(
      (acc: SectorToParse[], sector: Poste) => {
        const {
          id: secteur_id,
          collection: secteur_collection,
          level: secteur_level,
        } = sector;
        acc.push({
          secteur_id,
          secteur_collection,
          secteur_level,
        });

        levelCorresp.forEach((level) => {
          if (!sector[level.type + "_id"]) return;
          acc.push({
            secteur_id: sector[level.type + "_id"],
            secteur_collection: level.collection,
            secteur_level: level.level,
          });
        });
        return acc;
      },
      [],
    );
    const uniqSectors = _.uniqBy(sectors, "secteur_id");
    const result = await apiClient.value.postRequest(
      `/api/planning/save-standard-planning`,
      {
        planning_updates: planningUpdates,
        affected_sectors: uniqSectors,
        // localDebug: true, //for local debug only
      },
    );
    resetDefaultPlanningModifications();

    return [...results, result];
  }

  async function loadParametersSectorGroups(clientId: string): Promise<void> {
    try {
      const sectorGroups: SectorGroup[] =
        await dbHelper.getAllDataFromCollectionWithAll("sectors_groups", {
          where: [{field: "client_id", value: clientId, compare: "=="}],
        });

      parametersSectorGroups.value = _.sortBy(
        sectorGroups,
        (group) => group.name?.toLowerCase() ?? "",
      );
    } catch (error) {
      throw new Error();
    }
  }
  async function saveParametersSectorGroups(payload: {
    updates: SectorGroup[];
  }): Promise<void> {
    const {userData} = storeToRefs(mainStore);
    const clientId = userData.value.client_id;

    if (!clientId) throw new Error("NO_CLIENT_ID");

    const writes = payload.updates.map((sectorGroup) => {
      sectorGroup.client_id = clientId;
      return dbHelper.setDataToCollection(
        "sectors_groups",
        sectorGroup.id,
        sectorGroup,
        true,
      );
    });
    const results = await Promise.all(writes);

    const hasErrors = results.filter((res) => res && !!res?.e).length > 0;
    if (hasErrors) throw new Error();

    return loadParametersSectorGroups(clientId);
  }

  /**
   * returns an array from the `parametres_msd` collection for the current client
   * FIXME: this may be more related to a generic module than the parameters'
   */
  async function loadClientMSDs(): Promise<[UnknownError, MsdParameter[]]> {
    const {userData, perimeters, activeMsd} = storeToRefs(mainStore);
    if (!userData.value?.client_id) return [{e: t("Alert.no_client_id")}, null];
    if (!activeMsd.value?.id) return [{e: "No MSD id"}, null];
    const site = _.get(perimeters.value, ["sites", 0]);
    if (!site?.id) return [{e: "No site id"}, null];
    try {
      /**
       * FIXME: this is problematic to get the parameters list because we return a single item that may not have all the fields defined for the different sectors of the client
       */
      // const id = [activeMsd.value.id, site.id].join("_");
      // const msdParameter = await dbHelper.getDocFromCollection(
      //   "parametres_msd",
      //   id,
      // );
      // if (msdParameter?.id) return [null, [msdParameter]];
      //When msdParameters are saved with the new version, they carry this all_site_params boolean as true
      let msdParameters = await dbHelper.getAllDataFromCollectionWithAll(
        "parametres_msd",
        {
          where: [
            {
              field: "client_id",
              value: userData.value.client_id,
              compare: "==",
            },
            {
              field: "status",
              value: "active",
              compare: "==",
            },
            {
              field: "all_site_params",
              value: true,
              compare: "==",
            },
            {
              field: "msd_id",
              value: activeMsd.value.id,
              compare: "==",
            },
          ],
          limit: 1,
        },
      );
      if (msdParameters?.length) return [null, msdParameters];
      //we fall back to all msdParameters for the client
      msdParameters = await dbHelper.getAllDataFromCollectionWithAll(
        "parametres_msd",
        {
          where: [
            {
              field: "client_id",
              value: userData.value.client_id,
              compare: "==",
            },
          ],
        },
      );
      return [null, msdParameters];
    } catch (error) {
      return [error, null];
    }
  }
  /**
   * performs an update into the `parametres_msd` collection
   * FIXME: this may be more related to a generic module than the parameters'
   */
  async function saveClientMSDs(payload): Promise<[UnknownError, unknown]> {
    const {perimeters, userData, activeMsd, apiClient} = storeToRefs(mainStore);
    if (!userData.value?.client_id) return [{e: t("Alert.no_client_id")}, null];
    const {first_name, last_name, id: user_id} = userData.value || {};
    const {new_value, old_value} = payload;
    /**
     * creates a new msd parameter object in case none exists for the current client
     */
    let update = {
      ...new_value,
      updated_at: serverTimestamp(),
      updated_by: {first_name, last_name, user_id},
      pg_parsed: moment.utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
    };
    if (!update.id) {
      const site = _.get(perimeters.value, ["sites", 0]);
      if (!site?.id) return [{e: "No site id"}, null];
      if (!activeMsd.value?.id) return [{e: "No msd id"}, null];
      const id = [activeMsd.value.id, site.id].join("_"); //dbHelper.getCollectionId("parametres_msd"),
      //TODO: migrate all previous msd parameters to this new id
      update = {
        ...update,
        ...getInitialMSDParameter(site),
        id,
      };
    }
    if (!update.msd_id) update.msd_id = activeMsd.value.id;

    try {
      const error = await dbHelper.setDataToCollection(
        "parametres_msd",
        update.id,
        update,
        true,
      );
      if (error && error?.e) return [error.e, null];
      //save to pg
      apiClient.value.postRequest(`/api/pg-triggers`, {
        value: update,
        collection: "parametres_msd",
        type: "updated",
        previousValue: old_value,
      });
      return [null, update];
    } catch (error) {
      return [error, null];
    }
  }

  /**
   * loads the list of fields with defined in the ParametersList for the current client into the store
   * these fields are filtered on the `in_formula` key set to `true`
   * returns the `parametres_msd` at the site level if available
   */
  async function loadClientParametersList(): Promise<
    [UnknownError, Partial<{fields: MSDField[]; parameters: MsdParameter}>]
  > {
    const {getParametersCapaMandatoryConfiguredFields} = useParametersUtils();
    const [error, msdParameters] = await loadClientMSDs();

    if (error) return [error, null];

    const allMsdFieldsObj = msdParameters.reduce(
      (acc: Record<string, MSDField>, curr: MsdParameter) => {
        const {fields = []} = curr;
        // const formulaFields = fields.filter(isInFormulaField);
        fields.forEach((field: MSDField) => {
          acc[field.model] = field;
        });
        return acc;
      },
      {},
    );
    const allMsdFields = Object.values(allMsdFieldsObj);
    if (!allMsdFields.length) return [null, null];
    // adds mandatory fields if absent from the loaded parameters' fields
    for (const field of getParametersCapaMandatoryConfiguredFields) {
      if (allMsdFields.some((item: MSDField) => item.model === field.model))
        continue;
      allMsdFields.push(field);
    }
    //Sort the fields
    const sortedAllMsdFields = _.orderBy(allMsdFields, [
      (o: MSDField) =>
        PARAMETERS_MANDATORY_CAPA_FIELDS_MODELS.includes(o.model) ? -1 : 1,
      "model",
      "order",
    ]);

    parametersList.value = sortedAllMsdFields;

    // return the `site`-level msd parameters
    if (msdParameters.some(({level}) => level === 0)) {
      const sortedMSDParameters = _.orderBy(
        msdParameters,
        [
          "level",
          (o: any) => parseDate(o.updated_at || o.created_at),
          "secteur_name",
        ],
        ["desc", "desc", "asc"],
      );

      return [null, _.last(sortedMSDParameters)];
    }

    return [null, null];
  }
  async function loadParametersSchedulingRules(
    clientId: string,
  ): Promise<void> {
    if (!clientId) return;

    try {
      const scheduleRulesList: ParametersSchedulingRule[] =
        await dbHelper.getAllDataFromCollectionWithAll("scheduling_ai_rules", {
          where: [{field: "client_id", compare: "==", value: clientId}],
        });

      parametersSchedulingRules.value = scheduleRulesList.sort((ruleA, ruleB) =>
        ruleA?.name.localeCompare(ruleB?.name),
      );
    } catch (error) {
      throw new Error();
    }
  }
  async function saveParametersSchedulingRules(payload: {
    updates: ParametersSchedulingRule[];
  }): Promise<void> {
    const {userData} = storeToRefs(mainStore);
    const clientId = userData.value?.client_id;
    if (!clientId) throw new Error("NO_CLIENT_ID");

    const writes = payload.updates.map((_schedulingRule) => {
      const schedulingRule = {
        ..._schedulingRule,
        ...getOplitFirestoreDocumentUserMetadata(_schedulingRule),
      };

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        const result = await dbHelper.setDataToCollection(
          "scheduling_ai_rules",
          schedulingRule.id,
          schedulingRule,
          true,
        );
        //look for all sectors that have this rule id saved
        const allMsdsWRule = await dbHelper.getAllDataFromCollectionWithAll(
          "msd_charge_data",
          {
            where: [
              {
                field: "client_id",
                value: clientId,
                compare: "==",
              },
              {
                field: "ai_data.selected_model_id",
                value: schedulingRule.id,
                compare: "==",
              },
            ],
          },
        );
        asyncForEach(allMsdsWRule, async (msd_charge_item: any) => {
          //no need to await that, we let it run async
          dbHelper.setDataToCollection(
            "msd_charge_data",
            msd_charge_item.id,
            {ai_data: schedulingRule.ai_data},
            true,
          );
        });

        resolve(result);
      });
    });
    const results = await Promise.all(writes);

    const hasErrors =
      results.filter((res: {e?: GenericObject}) => !!res?.e).length > 0;

    if (hasErrors) throw new Error();

    return loadParametersSchedulingRules(clientId);
  }
  /**
   * this function updates a single schedulingRule, as opposed to the existing `saveParametersSchedulingRules`
   * that has a specific formatting that isn't easily compatible with the logic set to update individual schedulingRules
   * this function is used to update a rule name through the ParametersRuleModal component
   */
  async function updateSingleParametersSchedulingRule(
    payload: Partial<ParametersSchedulingRule>,
  ): Promise<[unknown, ParametersSchedulingRule]> {
    const {activeMsd, userData} = storeToRefs(mainStore);

    if (!activeMsd.value?.id) return;

    const finalPayload: ParametersSchedulingRule = {
      ...payload,
      ...getOplitFirestoreDocumentUserMetadata(payload),
      id: payload.id ?? (await dbHelper.getCollectionId("scheduling_ai_rules")),
      client_id: userData.value.client_id,
      msd_id: activeMsd.value.id,
    };

    try {
      await dbHelper.setDataToCollection(
        "scheduling_ai_rules",
        finalPayload.id,
        finalPayload,
        true,
      );

      const updatedRules = parametersSchedulingRules.value.filter(
        (rule: ParametersSchedulingRule) => rule.id !== finalPayload.id,
      );

      parametersSchedulingRules.value = [finalPayload, ...updatedRules];

      return [null, finalPayload];
    } catch (error: unknown) {
      // TODO: rework errors logging system
      openSnackbar(null, "GENERIC_ERROR");

      return [error, null];
    }
  }
  // FIXME: singular
  async function updateParametersCalculusRules(
    payload: Partial<OplitCalculusRule>,
  ): Promise<[unknown, OplitCalculusRule]> {
    const {activeMsd, userData} = storeToRefs(mainStore);

    if (!activeMsd.value?.id) return;

    const finalPayload: OplitCalculusRule = {
      ...payload,
      id:
        payload.id ??
        (await dbHelper.getCollectionId("parameters_calculus_rules")),
      client_id: userData.value.client_id,
      msd_id: activeMsd.value.id,
    };

    try {
      await dbHelper.setDataToCollection(
        "parameters_calculus_rules",
        finalPayload.id,
        finalPayload,
        true,
      );

      const updatedRules = calculusRules.value.filter(
        (rule: OplitCalculusRule) => rule.id !== finalPayload.id,
      );

      calculusRules.value = [finalPayload, ...updatedRules];

      return [null, finalPayload];
    } catch (error: unknown) {
      // TODO: rework errors logging system
      openSnackbar(null, "GENERIC_ERROR");

      return [error, null];
    }
  }
  // FIXME: singular
  async function deleteParametersCalculusRules(
    payload: Partial<OplitCalculusRule>,
  ): Promise<[unknown, boolean]> {
    if (!payload?.id) return;
    try {
      await dbHelper.deleteData("parameters_calculus_rules", payload.id);

      const updatedRules = calculusRules.value.filter(
        (rule: OplitCalculusRule) => rule.id !== payload.id,
      );

      calculusRules.value = updatedRules;
      return [null, true];
    } catch (error: unknown) {
      // TODO: rework errors logging system
      openSnackbar(null, "GENERIC_ERROR");
      return [error, false];
    }
  }
  async function deleteParametersSchedulingRule(
    payload: Partial<OplitCalculusRule>,
  ): Promise<[unknown, boolean]> {
    if (!payload?.id) return;
    try {
      await dbHelper.deleteData("scheduling_ai_rules", payload.id);

      const updatedRules = parametersSchedulingRules.value.filter(
        (rule: ParametersSchedulingRule) => rule.id !== payload.id,
      );

      parametersSchedulingRules.value = updatedRules;
      return [null, true];
    } catch (error: unknown) {
      // TODO: rework errors logging system
      openSnackbar(null, "GENERIC_ERROR");
      return [error, false];
    }
  }
  /**
   * the initial call for this action is made inside `Navbar` on the `team` watcher
   */
  async function loadParametersCalculusRules(): Promise<void> {
    const {userData} = storeToRefs(mainStore);
    if (!userData.value) return;

    try {
      const calculus_rules: OplitCalculusRule[] =
        await dbHelper.getAllDataFromCollectionWithAll(
          "parameters_calculus_rules",
          {
            where: [
              {
                field: "client_id",
                value: userData.value.client_id,
                compare: "==",
              },
            ],
            // orderBy: [{field: "name"}],
          },
        );

      // can be removed by creating an index and ordering directly in the query above
      const sortedCalculusRules = calculus_rules?.sort((a, b) =>
        toSafeString(a.name).localeCompare(b.name),
      );

      calculusRules.value = sortedCalculusRules || [];
    } catch (error) {
      loggerHelper.log({error});
    }
  }

  async function getBaseMSDDataObject(collection = "msd_data") {
    const {userData, activePerim} = storeToRefs(mainStore);

    const {
      secteur_id,
      id,
      secteur_name,
      name,
      collection: secteur_collection,
      level,
      type,
    } = activePerim.value;

    const msdDataObject: MsdData = {
      id: dbHelper.getCollectionId(collection),
      secteur_id: secteur_id || id || null,
      secteur_name: secteur_name || name || null,
      collection: secteur_collection,
      level: level,
      type: type,
      client_id: userData.value.client_id,
      created_by: userData.value.id,
      updated_by: userData.value.id,
      status: "active",
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
    };

    return {
      ...msdDataObject,
      ...getLevelCorrespFieldsForSector(activePerim.value),
    };
  }
  async function getSectorBaseMSDDataObject({
    sector,
    collection = "msd_data",
  }: {
    sector: Sector;
    collection?: string;
  }) {
    const {userData} = storeToRefs(mainStore);

    const {
      secteur_id,
      id,
      secteur_name,
      name,
      collection: secteur_collection,
      level,
      type,
    } = sector;

    const msdDataObject: MsdData = {
      id: dbHelper.getCollectionId(collection),
      secteur_id: secteur_id || id || null,
      secteur_name: secteur_name || name || null,
      collection: secteur_collection,
      level: level,
      type: type,
      client_id: userData.value.client_id,
      created_by: userData.value.id,
      updated_by: userData.value.id,
      status: "active",
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
    };

    return {
      ...msdDataObject,
      ...getLevelCorrespFieldsForSector(sector),
    };
  }
  /**
   * creates/updates an entry in the `msd_data` collection
   * this represents the configuration of a given sector for a specific calculus rule version
   */
  async function saveMSDData(payload): Promise<[unknown, boolean?]> {
    // FIXME : rework errors logging system
    if (!payload) return [{e: "Empty data"}];
    const {userData, activeMsd} = storeToRefs(mainStore);

    // FIXME : rework errors logging system
    if (!activeMsd.value?.id)
      return [{e: "Missing internal mandatory parameter"}];

    const currentMSDDataID = payload.id ?? msdData.value.id;

    const overloadedPayload = {
      ...payload,
      updated_at: serverTimestamp(),
      updated_by: userData.value.id,
      msd_id: activeMsd.value.id,
    };

    try {
      if (currentMSDDataID) {
        await dbHelper.setDataToCollection(
          "msd_data",
          currentMSDDataID,
          overloadedPayload,
          true,
        );
      } else {
        const newMSDDataObject = {
          ...overloadedPayload,
          ...(await getBaseMSDDataObject("msd_data")),
        };

        await dbHelper.setDataToCollection(
          "msd_data",
          newMSDDataObject.id,
          newMSDDataObject,
          true,
        );
      }

      //TODO: eventually call trigger from here
      // const result = await apiClient.postRequest(`/api/pg-triggers`, {
      //   value: {id: currentMSDDataID, ...update},
      //   collection: "msd_data",
      //   type: "updated",
      // });

      return [null, true];
    } catch (error) {
      return [error, null];
    }
  }
  /**
   * creates/updates an entry in the `msd_charge_data` collection
   **/
  async function saveMSDChargeData(payload): Promise<[unknown, boolean?]> {
    // FIXME : rework errors logging system
    if (!payload) return [{e: "Empty data"}];
    const {userData, activeMsd} = storeToRefs(mainStore);

    // FIXME : rework errors logging system
    if (!activeMsd.value?.id)
      return [{e: "Missing internal mandatory parameter"}];

    const overloadedPayload = {
      ...payload,
      updated_at: serverTimestamp(),
      updated_by: userData.value.id,
      msd_id: activeMsd.value.id,
    };

    try {
      if (payload.id) {
        await dbHelper.setDataToCollection(
          "msd_charge_data",
          payload.id,
          overloadedPayload,
          true,
        );
      } else {
        const newMSDDataObject = {
          ...overloadedPayload,
          ...(await getBaseMSDDataObject("msd_charge_data")),
        };

        await dbHelper.setDataToCollection(
          "msd_charge_data",
          newMSDDataObject.id,
          newMSDDataObject,
          true,
        );
      }

      return [null, true];
    } catch (error) {
      return [error, null];
    }
  }
  /**
   * creates/updates an entry in the `msd_data` or `msd_charge_data` collection for a specified sector
   * this represents the configuration of a given sector for a specific calculus rule version
   */
  async function saveSectorMSDData({
    sector,
    collection = "msd_data",
    payload,
  }: {
    sector: Sector;
    collection: string;
    payload: any;
  }): Promise<[unknown, boolean?]> {
    // FIXME : rework errors logging system
    if (!payload) return [{e: "Empty data"}];
    const {userData, activeMsd} = storeToRefs(mainStore);

    // FIXME : rework errors logging system
    if (!activeMsd.value?.id)
      return [{e: "Missing internal mandatory parameter"}];

    const currentMSDDataID = payload.id;

    let overloadedPayload = {
      ...payload,
      updated_at: serverTimestamp(),
      updated_by: userData.value.id,
      msd_id: activeMsd.value.id,
    };

    try {
      if (!currentMSDDataID) {
        const baseMSDData = await getSectorBaseMSDDataObject({
          sector,
          collection,
        });
        overloadedPayload = {
          ...overloadedPayload,
          ...baseMSDData,
        };
      }
      await dbHelper.setDataToCollection(
        collection,
        overloadedPayload.id,
        overloadedPayload,
        true,
      );

      return [null, true];
    } catch (error) {
      return [error, null];
    }
  }

  async function loadChargeMSDParameters() {
    const {userData, activeSector} = storeToRefs(mainStore);
    const {client_id} = userData.value;

    if (!client_id) throw new Error("NO_CLIENT_ID");
    if (!activeSector.value?.id || activeSector.value.level === undefined)
      throw new Error("GENERIC_ERROR");

    const paramsMSD = await dbHelper.getAllDataFromCollectionWithAll(
      "parametres_msd_charge",
      {
        where: [
          {field: "client_id", value: client_id, compare: "=="},
          {field: "secteur_id", value: activeSector.value.id, compare: "=="},
          {
            field: "level",
            value: activeSector.value.level / 1,
            compare: "==",
          },
          {
            field: "start_date",
            value: moment().format("YYYY-MM"),
            compare: "<=",
          },
        ],
        orderBy: [
          {field: "start_date", direction: "desc"},
          {field: "created_at", direction: "desc"},
        ],
      },
    );
    const parametresMsd = _.get(paramsMSD, 0, {});
    chargeMsdParameters.value = parametresMsd;
    return chargeMsdParameters.value;
  }

  function initParametersViewFromURL(): void {
    // this is used as an early return : -1 would be returned anyway by the logic below
    if (router.currentRoute.value.name === PARAMETERS_DATA_IMPORT_ROUTE_NAME)
      parametersView.value = parametersOptions.value.at(-1);
    const linksRouteNames = Array.from(
      [parametersGeneralMenuItems.value, parametersBySectorMenuItems.value],
      (items: MenuItem[]): string[] =>
        _.flatMap(items, (item: MenuItem): string[] =>
          _.flatMap(item.links, "routeName"),
        ),
    );

    parametersView.value = parametersOptions.value.at(
      linksRouteNames.findIndex((links: RouteRecordName[]) =>
        links.includes(router.currentRoute.value.name),
      ),
    );
  }

  function setParametersView(option: ListOptionLink): void {
    parametersView.value = option;
    if (option.routeName) {
      if (router.currentRoute.value.name === option.routeName) return;
      router.push({name: option.routeName});
    }
  }

  async function loadShiftPlanning(): Promise<void> {
    const {userData} = storeToRefs(mainStore);
    if (!userData.value) return;

    try {
      const shiftPlannings = await dbHelper.getAllDataFromCollectionWithAll(
        "shift_planning",
        {
          where: [
            {
              field: "client_id",
              value: userData.value.client_id,
              compare: "==",
            },
          ],
        },
      );

      shiftPlanning.value = shiftPlannings[0];
    } catch (error) {
      loggerHelper.log({error});
    }
  }

  async function updateShiftPlanning(
    payload: Partial<ShiftPlanningDocument>,
  ): Promise<[unknown, ShiftPlanningDocument]> {
    try {
      const updatedShiftPlanning = {
        ...getBaseOplitFirestoreDocument("shift_planning"),
        ...shiftPlanning.value,
        ...payload,
        ...getOplitFirestoreDocumentUserMetadata(shiftPlanning.value),
      } as ShiftPlanningDocument;

      await dbHelper.setDataToCollection(
        "shift_planning",
        updatedShiftPlanning.id,
        updatedShiftPlanning,
        true,
      );

      shiftPlanning.value = updatedShiftPlanning;

      openSnackbar(null, "SAVE_SUCCESS");

      return [null, updatedShiftPlanning];
    } catch (error: unknown) {
      // TODO: rework errors logging system
      openSnackbar(null, "GENERIC_ERROR");

      return [error, null];
    }
  }

  async function loadImportParsingRules(): Promise<void> {
    const {userData} = storeToRefs(mainStore);

    try {
      const results = await dbHelper.getAllDataFromCollectionWithAll(
        "import_parsing_rules",
        {
          where: [
            {
              field: "client_id",
              value: userData.value.client_id,
              compare: "==",
            },
            {field: "status", value: "active", compare: "=="},
          ],
        },
      );

      // always keep the fallback on {} to differentiate pre and post loading
      const [originalRules] = results || [{}];

      importParsingRules.value = {
        ...(originalRules && {
          ...originalRules,
          // see OPL-5407
          PROD: _.mapKeys(originalRules.PROD, (_, key) => {
            if (key === "date_lancement") return "date_production";
            if (key === "quantite_op") return "quantite_produite";
            return key;
          }),
        }),
      };
    } catch (error: unknown) {
      openSnackbar(null, null, error);
    }
  }
  async function fetchMSDbyClient(): Promise<GenericObject[]> {
    const {activeMsd, userData} = storeToRefs(mainStore);
    const clientId = userData.value?.client_id;
    if (!clientId) return;

    const msdList: MSD[] = await dbHelper.getAllDataFromCollectionWithAll(
      "msd",
      {
        where: [
          {field: "client_id", value: clientId, compare: "=="},
          {field: "status", value: "active", compare: "=="},
        ],
        orderBy: [
          {field: "start_date", direction: "desc"},
          {field: "created_at", direction: "desc"},
        ],
      },
    );
    if (msdList.length > 0) {
      const localFormatDate = (date: string | moment.Moment) =>
        moment(date).format("D MMM YY");

      msdList.forEach((msd, index) => {
        const name = [localFormatDate(msd.start_date)];

        if (!index) name.push(t("global.today"));
        else if (msd.end_date) name.push(localFormatDate(msd.end_date));
        else {
          const previousMSDStartDate = msdList[index - 1].start_date;
          const calculatedEndDate = moment(previousMSDStartDate).subtract(
            1,
            "days",
          );
          name.push(localFormatDate(calculatedEndDate));
        }

        msd.name = name.join(" - ");
      });

      activeMsd.value = msdList[0];
    }
    msdListByClient.value = msdList;
    return msdListByClient.value;
  }
  async function createMSD() {
    const newMSD: MSD = {
      ...getBaseOplitFirestoreDocument("msd"),
      start_date: moment().format(DATE_DEFAULT_FORMAT),
    };
    await dbHelper.setDataToCollection("msd", newMSD.id, newMSD, true);
    fetchMSDbyClient();

    openSnackbar(null, "SAVE_SUCCESS");
  }

  async function updateClientParameters(
    payload: Partial<ClientParameter>,
  ): Promise<[unknown, ClientParameter]> {
    const {clientParameters} = storeToRefs(mainStore);

    try {
      const updatedClientParameters = {
        ...getBaseOplitFirestoreDocument("parametres"),
        ...clientParameters.value,
        ...payload,
        ...getOplitFirestoreDocumentUserMetadata(clientParameters.value),
      } as ClientParameter;

      await dbHelper.setDataToCollection(
        "parametres",
        updatedClientParameters.id,
        updatedClientParameters,
        true,
      );

      clientParameters.value = updatedClientParameters;

      openSnackbar(null, "SAVE_SUCCESS");

      return [null, updatedClientParameters];
    } catch (error: unknown) {
      openSnackbar(null, "GENERIC_ERROR");

      return [error, null];
    }
  }

  async function getSectorSchedulingRule(sector: {
    id?: string;
    secteur_id?: string;
    level?: number;
    secteur_level?: number;
  }): Promise<null | ParametersSchedulingRule> {
    const [, sectorMsdData] = await loadSectorMSDData({
      sector,
      type: "charge",
    });
    const selectedAiRule: string = sectorMsdData?.ai_data?.selected_model_id;
    if (!selectedAiRule) return null;

    return parametersSchedulingRules.value.find(
      (rule) => rule.id === selectedAiRule,
    );
  }

  async function loadParametersOfDisplay(): Promise<void> {
    const {userData} = storeToRefs(mainStore);
    if (!userData.value) return;

    const {providerIds} = storeToRefs(useCustomerInterfaceStore());

    try {
      const allParameters: ParametersOfDisplay[] =
        await dbHelper.getAllDataFromCollectionWithAll(
          "parameters_of_display",
          {
            where: [
              {
                field: "client_id",
                value: userData.value.client_id,
                compare: "==",
              },
            ],
          },
        );

      parametersOfDisplay.value =
        _.orderBy(allParameters, (o) => parseDate(o.updated_at), "desc").at(
          0,
        ) || null;

      if (providerIds.value.length) {
        parametersOfDisplayFromProviders.value =
          (await apiClient.getRequest(
            `/api/scheduling/get-parameters-of-display`,
          )) || [];
      }
    } catch (error) {
      loggerHelper.log({error});
    }
  }
  async function saveParametersOfDisplay(
    payload: Partial<ParametersOfDisplay>,
  ) {
    const {userData} = storeToRefs(mainStore);

    const finalPayload: Partial<ParametersOfDisplay> = {
      ...parametersOfDisplay.value,
      ...payload,
      id: payload.id ?? dbHelper.getCollectionId("parameters_of_display"),
      client_id: userData.value.client_id,
      updated_at: moment.utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
      updated_by: userData.value.id,
    };

    try {
      await dbHelper.setDataToCollection(
        "parameters_of_display",
        finalPayload.id,
        finalPayload,
      );

      const initialAddedColumns = parametersOfDisplayAddedColumns.value.map(
        ({key}) => key,
      );
      const added_columns = (payload.added_columns || [])
        .filter(({key}) => !initialAddedColumns.includes(key))
        .map(({key}) => key);
      if (added_columns.length) {
        segment.value.track("[Parameters] Column added to OF display", {
          added_columns,
        });
      }

      parametersOfDisplay.value = {
        ...parametersOfDisplay.value,
        ...finalPayload,
      };

      shouldSaveOfDisplay.value = false;
    } catch (error: unknown) {
      openSnackbar(null, "GENERIC_ERROR");
    }
  }

  function buildPopulatedImportParsingFieldRowData(
    fieldData?: ImportParsingFieldData,
  ): Pick<ImportParsingFieldData, "rules" & "version" & "value" & "fields"> {
    const builtRules = buildNewImportParsingFieldRules(fieldData);

    const {version, rules, fields = []} = fieldData || {};
    const clientHeaders =
      version === 2 && Array.isArray(rules)
        ? rules.flatMap(({formula}) =>
            formula.map(({formula_left_operand}) => formula_left_operand),
          )
        : fields;
    return {
      value: [...new Set(clientHeaders)].filter(Boolean).map((header) => ({
        label: header,
        model: header,
      })),
      version: 2,
      rules: builtRules,
      // We're already only doing this for fields with custom rules, so we can
      // safely set fields to an empty array if the previous version is not
      // version 2
      fields: version === 2 ? fields : [],
    };
  }
  function buildNewImportParsingFieldRules(
    fieldData?: ImportParsingFieldData,
  ): ImportParsingFieldCompositeRules {
    const {version = 2, rules = [], fields = []} = fieldData || {};
    const [clientField] = fields || [];
    if (version === 2 && Array.isArray(rules)) return rules;

    if (!clientField) return [];

    // Adds a single pre-filled row if only the field has been specified
    const possibleValues = Object.keys(rules).filter(Boolean);
    if (
      Object.values(rules as ImportParsingFieldSimpleRules).every(
        ({values}) => !values?.length,
      )
    ) {
      return [
        {
          dispatch_target: "",
          formula: [
            {
              formula_conjunction: t("Parameters.imports.if"),
              formula_left_operand: clientField,
              formula_operand: "includes",
              formula_right_operand: "",
            },
          ],
        },
      ];
    }

    return possibleValues.reduce((acc, value) => {
      const {values: clientValues} = (rules as ImportParsingFieldSimpleRules)[
        value
      ];
      if (!clientValues?.length) return acc;

      const newRules: ImportParsingFieldCompositeRules = clientValues.map(
        (clientValue) => {
          const formula: ImportDispatchFormulaOperation = {
            formula_conjunction: t("Parameters.imports.if"),
            formula_left_operand: clientField,
            formula_operand: "includes",
            formula_right_operand: clientValue,
          };

          return {
            dispatch_target: value,
            formula: [formula],
          };
        },
      );

      return [...acc, ...newRules];
    }, [] as ImportParsingFieldCompositeRules);
  }

  function getSectorsGroupsContainingSectors(sectorsList: SectorLike[]) {
    const sectorsListIDs = sectorsList.map(
      ({secteur_id, id}) => secteur_id || id,
    );

    return parametersSectorGroups.value.filter(({sectors}) =>
      sectors.every(({secteur_id}) => sectorsListIDs.includes(secteur_id)),
    );
  }

  return {
    parametersSchedulingDisplay,
    parametersAllSchedulingDisplaysByTeamId,
    parametersSchedulingRules,
    parametersSectorGroups,
    parametersColorsCategories,
    areEditedColorsCategories,
    shouldSaveColorsCategories,
    parametersList,
    parametersView,
    calculusRules,
    msdData,
    chargeMsdParameters,

    parametersCurrentMenuItems,
    // parametersGeneralMenuItems,
    // parametersBySectorMenuItems,
    // parametersImportMenuItems,

    parametersOptions,
    parametersSchedulingColorsCategories,
    parametersPlanningColorsCategories,
    parametersStdPlanningChanges,
    dailyCapacityParameter,
    shiftPlanning,
    shiftPlanningItems,
    msdListByClient,
    doesClientHaveShiftPlanning,
    clientFirstShift,

    updateParametersSchedulingDisplay,
    loadParametersSchedulingDisplay,
    updateParametersColorsCategories,
    loadParametersColorsCategories,
    deleteParametersColorsCategories,
    loadMSDData,
    loadMSDChargeData,
    loadSectorMSDData,
    resetDefaultPlanningModifications,
    updateDefaultPlanningModifications,
    saveDefaultPlanning,
    loadParametersSectorGroups,
    saveParametersSectorGroups,
    saveClientMSDs,
    loadClientParametersList,
    loadParametersSchedulingRules,
    saveParametersSchedulingRules,
    updateParametersCalculusRules,
    deleteParametersCalculusRules,
    loadParametersCalculusRules,
    saveMSDData,
    saveMSDChargeData,
    saveSectorMSDData,
    loadChargeMSDParameters,
    buildPopulatedImportParsingFieldRowData,
    buildNewImportParsingFieldRules,

    initParametersViewFromURL,
    setParametersView,
    loadShiftPlanning,
    updateShiftPlanning,
    loadImportParsingRules,
    importParsingRules,
    deleteParametersSchedulingRule,
    updateSingleParametersSchedulingRule,
    fetchMSDbyClient,
    createMSD,

    updateClientParameters,

    getSectorSchedulingRule,

    parametersOfDisplay,
    isEditedOfDisplay,
    shouldSaveOfDisplay,
    parametersOfDisplayHiddenColumns,
    parametersOfDisplayAddedColumns,
    parametersOfDisplayFromProviders,
    firstProviderParametersOfDisplayHiddenColumns,
    firstProviderParametersOfDisplayAddedColumns,
    firstProviderParametersOfDisplayColumnsOrder,
    loadParametersOfDisplay,
    saveParametersOfDisplay,
    getSectorsGroupsContainingSectors,
  };
});
