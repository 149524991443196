<template>
  <v-btn
    :class="[
      {
        'button d-flex justify-space-between cursor-pointer': true,
        'button--disabled': disabled,
        'input-button': input,
        'transparent-button': transparent,
        'white-button': white,
        'blue-button': blue,
        'pink-button': pink,
        'pink-border-button pink-button': pinkButton,
        'medium-button': medium,
        'small-button': small,
        'rounded-button': rounded,
        'light-button': light,
        'outlined-button': variant === 'outlined',
      },
      ...fillClasses,
    ]"
    :variant="variant"
    :ripple="!transparent"
    :loading="loading"
    :disabled="disabled"
    :block="block"
    elevation="0"
    :id="id"
    :height="height || (large ? '48' : medium ? '32' : small ? '24' : '40')"
    :width="width"
    :to="to"
    :style="formComponentStyle"
    v-bind="$attrs"
    :data-cy="dataCy"
    @click="(e) => $emit('click', e)"
    @mousedown="(e) => $emit('mousedown', e)"
  >
    <v-tooltip location="top" :disabled="!tooltip">
      <template v-slot:activator="{props}">
        <div
          v-bind="props"
          :class="{
            'd-flex align-center button-text gap-4': true,
            'mx-auto': !left,
            'button-content-wrapper': constrainContent,
            'expand-content': expandContent,
          }"
          :data-testid="dataTestid"
        >
          <slot name="icon-left">
            <vue-feather
              v-if="icon"
              class="flex-shrink-0 flex-grow-0"
              tag="div"
              :size="iconSize || (small ? 16 : 24)"
              :type="icon"
              :stroke="iconStroke"
              :fill="iconFill"
            />
          </slot>

          <div
            v-if="$slots.default"
            :class="computedSlotClass"
            :id="TEST_IDS.FBUTTON__DEFAULT_SLOT"
          >
            <slot />
          </div>
        </div>
      </template>
      <div>{{ tooltip }}</div>
    </v-tooltip>

    <slot name="append-icon" />
  </v-btn>
</template>

<script scoped lang="ts">
import {computed, defineComponent} from "vue";
import {useFormComponents} from "@/composables/formComponents";
import {VueClassesArray} from "@/interfaces";
import {TEST_IDS} from "@/config/constants";

export default defineComponent({
  name: "figma-button",
  props: {
    height: String,
    iconSize: String,
    iconStroke: String,
    iconFill: String,
    width: String,
    id: String,
    block: Boolean,
    left: Boolean,
    large: Boolean,
    small: Boolean,
    medium: {type: Boolean, default: false},
    rounded: Boolean,
    transparent: Boolean,
    filled: [Boolean, String],
    loading: Boolean,
    disabled: Boolean,
    input: Boolean,
    white: Boolean,
    icon: String,
    textClass: [String, Array],
    constrainContent: Boolean,
    expandContent: {type: Boolean, default: false},
    to: String,
    tooltip: {type: String, default: ""},
    blue: {type: Boolean, default: false},
    pink: {type: Boolean, default: false},
    pinkButton: {type: Boolean, default: false},
    light: {type: Boolean, default: false},
    dataCy: {type: String},
    dataTestid: {type: String, default: TEST_IDS.FBUTTON__DEFAULT_DATA_TEST_ID},
    outlined: {type: [Boolean, Array], default: false},
  },
  emits: ["mousedown", "click"],
  setup(props) {
    const {formComponentStyle} = useFormComponents(props);
    const variant = computed(() => {
      const {outlined, filled} = props;
      if (outlined) return "outlined";
      if (filled) return "tonal";
      return "elevated";
    });

    return {formComponentStyle, variant, TEST_IDS};
  },
  computed: {
    hasDefaultSlot: function () {
      return !!this.$slots.default;
    },
    computedSlotClass: function () {
      const {textClass, icon, hasDefaultSlot} = this;
      if (!icon || !hasDefaultSlot || textClass) return textClass;
      return [];
    },
    fillClasses(): VueClassesArray {
      const {filled} = this;
      if (!filled) return [];
      const classes = ["filled-button"];
      classes.push(
        `bg-${typeof filled === "boolean" ? "newPrimaryRegular" : filled}`,
      );
      return classes;
    },
  },
});
</script>

<style lang="scss">
div.v-application,
div.v-overlay-container {
  .button {
    box-sizing: border-box;
    text-transform: none;
    letter-spacing: normal;
    border-radius: 8px;
    background-color: transparent;
    max-width: 100%;
    flex-wrap: nowrap;

    &-text,
    .v-btn__loader {
      color: rgb(var(--v-theme-newSubText));
    }

    &.v-btn {
      padding-left: 12px;
      padding-right: 12px;
    }

    .v-btn__content {
      width: 100%;
      justify-content: space-between;

      & .expand-content {
        width: 100%;
      }
    }

    &-content-wrapper {
      max-width: 75%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &::before {
      opacity: 0 !important;
    }

    &.white-button {
      .button-text,
      .v-btn__loader {
        color: rgb(var(--v-theme-blanc));
      }

      &.v-btn--variant-outlined {
        border-color: rgb(var(--v-theme-blanc));

        &:hover {
          border: none;
          background-color: rgb(var(--v-theme-newSubBackground)) !important;
        }
      }
    }
    &.light-button {
      background: rgb(var(--v-theme-newLayerBackground));
    }
    &.pink-border-button {
      &.v-btn--variant-outlined {
        border: 1px solid rgb(var(--v-theme-newPinkRegular)) !important ;
      }
    }

    &.blue-button,
    &.blue-button:hover {
      .button-text,
      .v-btn__loader {
        color: rgb(var(--v-theme-newPrimaryRegular));
      }

      &.v-btn--variant-outlined {
        &:hover {
          border: none;
          background-color: rgb(var(--v-theme-newSubBackground)) !important;
          color: rgb(var(--v-theme-newPrimaryRegular)) !important;
        }
      }
    }

    &.pink-button,
    &.pink-button:hover {
      .button-text,
      .v-btn__loader {
        color: rgb(var(--v-theme-newPinkRegular));
      }

      &.v-btn--variant-outlined {
        &:hover {
          border: none;
          background-color: rgb(var(--v-theme-newSubBackground)) !important;
          color: rgb(var(--v-theme-newPinkRegular)) !important;
        }
      }
    }

    &.input-button {
      font-size: 16px;
      background-color: rgb(var(--v-theme-newSubBackground));

      &:hover {
        background-color: rgb(var(--v-theme-newHover)) !important;
        & .button-text {
          color: rgb(var(--v-theme-newMainText));
        }
      }

      &:focus {
        background-color: rgb(var(--v-theme-newLayerBackground)) !important;
        & .button-text {
          color: rgb(var(--v-theme-newMainText));
        }
      }

      &.v-btn.v-btn--disabled {
        background-color: rgb(var(--v-theme-newDisableBG));
      }
    }

    &.medium-button {
      border-radius: 8px;

      &.v-btn {
        padding: 6px 8px;
      }
    }

    &.small-button {
      border-radius: 4px;

      &.v-btn {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    &.rounded-button {
      // Used instead of the rounded prop because it was overriden by our border
      // radius settings, basically gives the button a pill shape
      border-radius: 512px;
    }

    // States
    // Every one of these is !important because Vuetify judged it wise to integrate
    // !important stylings in a public styling framework (that or I'm just incompetent)
    &.filled-button {
      & .button-text,
      & .v-btn__loader {
        color: rgb(var(--v-theme-blanc));
      }

      &:hover,
      &:focus {
        & .button-text {
          color: rgb(var(--v-theme-blanc));
        }
        border: none;
      }

      &:hover {
        background-color: rgb(var(--v-theme-newPrimaryDark1)) !important;
      }

      &:focus {
        background-color: rgb(var(--v-theme-newPrimaryDark2)) !important;
      }

      &.v-btn.v-btn--disabled {
        background-color: rgb(var(--v-theme-newDisableBG)) !important;
      }
    }

    &.transparent-button {
      &:hover,
      &:focus,
      &:active {
        & .button-text {
          color: rgb(var(--v-theme-newMainText));
        }
        background-color: transparent;
        border: none;
      }
    }

    &.outlined-button {
      border-color: rgb(var(--v-theme-newSelected));

      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
      }

      &:hover {
        border-color: rgb(var(--v-theme-newSubText));
      }

      &:focus,
      &:active {
        border: 1px solid rgb(var(--v-theme-newMainText)) !important;
      }
    }

    &:hover {
      background-color: rgb(var(--v-theme-newSubBackground));
      & .button-text {
        color: rgb(var(--v-theme-newMainText));
      }
    }

    &:focus,
    &:active {
      background-color: rgb(var(--v-theme-newHover));
    }

    &:focus {
      border: 1px solid rgb(var(--v-theme-newPrimaryRegular));
    }

    &.v-btn.v-btn--disabled {
      & .button-text,
      & .v-btn__loader {
        color: rgb(var(--v-theme-newDisableText));
      }
      border: none;

      &.v-btn--variant-outlined:not(.input-button) {
        border: 1px solid rgb(var(--v-theme-newDisableText));
      }
    }
  }

  #fbutton--default-slot {
    display: flex;
    align-items: center;
  }
}
</style>
