import moment from "moment";
import loggerHelper from "@/tscript/loggerHelper";

const capitalizeWeekdays = (): string[] => {
  return moment
    .weekdays(true)
    .map((day) => `${day.charAt(0).toUpperCase()}${day.slice(1)}`);
};

const dateFormat = (date, format) => {
  const mDate = moment(date);
  if (!mDate.isValid()) {
    loggerHelper.log("Invalid date", date);
    return "";
  }
  return mDate.format(format);
};

const everyHalfHourStrings = (): string[] => {
  const halfHourStrings = [];
  const time = moment("000", "hmm");

  for (let i = 0; i < 48; i++) {
    halfHourStrings.push(
      moment(time)
        .add(30 * i, "minutes")
        .format("HH:mm"),
    );
  }

  return halfHourStrings;
};

export {capitalizeWeekdays, dateFormat, everyHalfHourStrings};
