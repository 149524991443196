<script setup lang="ts">
import {useI18n} from "vue-i18n";
import {SchedulingOperation} from "@/interfaces";

interface SchedulingOperationIsNewTagProps {
  operation?: SchedulingOperation;
}

defineProps<SchedulingOperationIsNewTagProps>();

const {t} = useI18n();
</script>

<template>
  <span
    v-if="operation.is_new"
    v-tooltip="t('scheduling.new_vs_old_simulation')"
    class="scheduling-operation-is-new-tag"
  >
    New
  </span>
</template>

<style scoped lang="scss">
.scheduling-operation-is-new-tag {
  float: left;
  border-radius: 100px;
  padding: 0px 6px;
  background: rgb(var(--v-theme-newPrimaryRegular));
  color: rgb(var(--v-theme-newLayerBackground));
  text-transform: uppercase;
  line-height: 20px;
}
</style>
