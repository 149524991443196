import {i18n} from "@/i18n";
import {getDefaultParametersSchedulingDisplayByClientID} from "@oplit/shared-module";
import {
  CONFIG_PER_CLIENT_DEFAULT,
  ENVIRONMENT_ID_DEV,
  ENVIRONMENT_ID_STAGING,
  ENVIRONMENT_ID_DEMO,
  ENVIRONMENT_ID_PROD,
} from "@/config/constants";

const DEFAULT_LISI_STOCKS_DEMAND_TOOLTIP_INFO_MAPPING: Record<string, string> =
  {
    condit: "Condit.",
    cpc_code: "CPC Code",
    day_date: "Date dép.",
    depot: "Dépôt",
    holding: "Holding",
    n_cde_client: "N° Cde Client",
    n_client: "N° Client",
    nom_du_client: "Nom du client",
    of_id: "Cmde Lisi",
    op_status: "Cde Stat",
    ref_client: "Réf. Client",
    type_cde: "Type cde",
    ville: "Ville",
  };

export function useClientsConfigurations() {
  const {t} = i18n;

  const GENERAL = {
    [CONFIG_PER_CLIENT_DEFAULT]: {
      PARAMETERS_DEFAULT_SCHEDULING_DISPLAY:
        getDefaultParametersSchedulingDisplayByClientID(),
      SCHEDULING_GROUP_BY: [
        {
          name: t("scheduling.group_by.client"),
          field: "customer",
        },
        {
          name: t("scheduling.group_by.article"),
          field: "ref_article",
        },
      ],
      DEMAND_GROUP_BY_ARTICLE_ID_FIELD: "article_id",
      DEMAND_GROUP_BY_CUSTOMER_FIELD: "customer",
      DEMAND_GROUP_BY_ORDER_NUMBER_FIELD: "order_number",
    },
  };

  /**
   * This file contains specific client configurations for logic parts within the app
   * These specific configurations were previously made in separate files : the aim of this file is to centralize them
   *
   * The format is as follow :
   * ```
   * CLIENTS_CONFIGS = {
   *    ...
   *    <client_id>: {
   *        <identifier_of_the_environment = 'default' | 'dev' | 'staging' | 'demo' | 'prod'>: {
   *            <constant_name_to_be_used_for_specific_logic_part>: <associated_value>
   *        }
   *    }
   * }
   * ```
   * This is meant to be used in two ways :
   * - through the store getter `getClientConfiguration` for generic use cases
   * e.g. displaying information in the scheduling cards given the current client
   * - directly in a file, importing a client configuration (for specific use cases)
   * e.g. conditionally displaying information for specific clients under their export name rather than their ID
   */
  const CLIENTS_CONFIGS = {
    // Delle
    bkEfcYyUTImjXHU3xtsO: {
      [CONFIG_PER_CLIENT_DEFAULT]: {
        CLIENT_ID: "bkEfcYyUTImjXHU3xtsO",
        DEMAND_TOOLTIP_INFO_MAPPING:
          DEFAULT_LISI_STOCKS_DEMAND_TOOLTIP_INFO_MAPPING,
        DEMAND_GROUP_BY_CUSTOMER_FIELD: "holding",
        DEMAND_GROUP_BY_ORDER_NUMBER_FIELD: "n_cde_client",
      },
    },
    // Dasle
    lHLkwWtcv2jWEKq4M5lX: {
      [CONFIG_PER_CLIENT_DEFAULT]: {
        CLIENT_ID: "lHLkwWtcv2jWEKq4M5lX",
        DEMAND_TOOLTIP_INFO_MAPPING:
          DEFAULT_LISI_STOCKS_DEMAND_TOOLTIP_INFO_MAPPING,
        DEMAND_GROUP_BY_CUSTOMER_FIELD: "holding",
        DEMAND_GROUP_BY_ORDER_NUMBER_FIELD: "n_cde_client",
      },
    },
    // Mellrichstadt
    dir6BEJCB3oBM8kviThE: {
      [CONFIG_PER_CLIENT_DEFAULT]: {
        CLIENT_ID: "dir6BEJCB3oBM8kviThE",
        DEMAND_TOOLTIP_INFO_MAPPING:
          DEFAULT_LISI_STOCKS_DEMAND_TOOLTIP_INFO_MAPPING,
      },
    },
    // Puiseux
    ierq5m5UZZOPENZtKiic: {
      [CONFIG_PER_CLIENT_DEFAULT]: {
        CLIENT_ID: "ierq5m5UZZOPENZtKiic",
        DEMAND_TOOLTIP_INFO_MAPPING:
          DEFAULT_LISI_STOCKS_DEMAND_TOOLTIP_INFO_MAPPING,
      },
    },
    // Sagemcom
    "5hs5FtzBAp3zc2A0jji8": {
      [CONFIG_PER_CLIENT_DEFAULT]: {
        PARAMETERS_DEFAULT_SCHEDULING_DISPLAY:
          getDefaultParametersSchedulingDisplayByClientID(
            "5hs5FtzBAp3zc2A0jji8",
          ),
      },
    },
    // Haas
    MUeFMwkxQ8sp4CgonYeq: {
      [CONFIG_PER_CLIENT_DEFAULT]: {
        PARAMETERS_DEFAULT_SCHEDULING_DISPLAY:
          getDefaultParametersSchedulingDisplayByClientID(
            "MUeFMwkxQ8sp4CgonYeq",
          ),
      },
    },
    // Parthenay
    kihJoGUywfHfI7abZWDH: {
      [CONFIG_PER_CLIENT_DEFAULT]: {
        PARAMETERS_DEFAULT_SCHEDULING_DISPLAY:
          getDefaultParametersSchedulingDisplayByClientID(
            "kihJoGUywfHfI7abZWDH",
          ),
        SCHEDULING_GROUP_BY: [
          ...GENERAL.default.SCHEDULING_GROUP_BY,
          {
            name: t("scheduling.group_by.article_op"),
            field: "group_by_field",
          },
        ],
      },
    },
    // Tesla Ordo
    Gsd8urfajIZSYuj3kkn7: {
      [CONFIG_PER_CLIENT_DEFAULT]: {
        PARAMETERS_DEFAULT_SCHEDULING_DISPLAY:
          getDefaultParametersSchedulingDisplayByClientID(
            "Gsd8urfajIZSYuj3kkn7",
          ),
      },
    },
    // Lisi
    g0E4nabmVzpLoQWUPnwq: {
      [CONFIG_PER_CLIENT_DEFAULT]: {
        PARAMETERS_DEFAULT_SCHEDULING_DISPLAY:
          getDefaultParametersSchedulingDisplayByClientID(
            "g0E4nabmVzpLoQWUPnwq",
          ),
        SCHEDULING_GROUP_BY: [
          ...GENERAL.default.SCHEDULING_GROUP_BY,
          {
            name: t("scheduling.group_by.matiere"),
            field: "matiere",
          },
        ],
      },
    },
    // Janson
    "8GHQKvFXKSd4mHUqDJmB": {
      [CONFIG_PER_CLIENT_DEFAULT]: {
        PARAMETERS_DEFAULT_SCHEDULING_DISPLAY:
          getDefaultParametersSchedulingDisplayByClientID(
            "8GHQKvFXKSd4mHUqDJmB",
          ),
      },
    },
    // VCA
    QoGDqp6UoWoRbzsgKasn: {
      [CONFIG_PER_CLIENT_DEFAULT]: {
        PARAMETERS_DEFAULT_SCHEDULING_DISPLAY:
          getDefaultParametersSchedulingDisplayByClientID(
            "QoGDqp6UoWoRbzsgKasn",
          ),
        SCHEDULING_GROUP_BY: [
          ...GENERAL.default.SCHEDULING_GROUP_BY,
          {
            name: t("scheduling.group_by.operation"),
            field: "op_name",
          },
        ],
      },
    },
    // Vaucher
    Re4dpzZvU8ady8Ca3sxH: {
      [CONFIG_PER_CLIENT_DEFAULT]: {
        PARAMETERS_DEFAULT_SCHEDULING_DISPLAY:
          getDefaultParametersSchedulingDisplayByClientID(
            "Re4dpzZvU8ady8Ca3sxH",
          ),
      },
    },
    // Horlyne
    Horlyne: {
      [CONFIG_PER_CLIENT_DEFAULT]: {
        PARAMETERS_DEFAULT_SCHEDULING_DISPLAY:
          getDefaultParametersSchedulingDisplayByClientID("Horlyne"),
      },
    },
    // Cartier
    Cartier: {
      [CONFIG_PER_CLIENT_DEFAULT]: {
        PARAMETERS_DEFAULT_SCHEDULING_DISPLAY:
          getDefaultParametersSchedulingDisplayByClientID("Cartier"),
      },
    },
    // Lisi VDR
    TT95CMEYHNnR2RpJOUw4: {
      CLIENT_ID: "TT95CMEYHNnR2RpJOUw4",
      [ENVIRONMENT_ID_PROD]: {
        PARAMETERS_DEFAULT_SCHEDULING_DISPLAY:
          getDefaultParametersSchedulingDisplayByClientID(
            "TT95CMEYHNnR2RpJOUw4",
          ),
      },
      [CONFIG_PER_CLIENT_DEFAULT]: {
        // FIXME: this may be deprecated when merging UAP 2 environment on prod
        PARAMETERS_DEFAULT_SCHEDULING_DISPLAY: {
          info1: ["of_name", null],
          info2: ["code_article", null],
          info3: ["_reference", null],
        },
        SCHEDULING_GROUP_BY: [
          ...GENERAL.default.SCHEDULING_GROUP_BY,
          {
            name: "Forme de tête",
            field: "forme_tete",
          },
          {
            name: "Diamètre",
            field: "diametre",
          },
        ],
      },
    },
    GENERAL,
  };

  function getClientConfig({
    client_id,
    environment,
    key,
  }: {
    client_id: string;
    environment?: string;
    key?: string;
  }) {
    if (!client_id) return;
    /**
     * global configuration object, created from general definitions overriden by specific ones
     */
    const mergedConfig = [
      CONFIG_PER_CLIENT_DEFAULT,
      ENVIRONMENT_ID_DEV,
      ENVIRONMENT_ID_STAGING,
      ENVIRONMENT_ID_DEMO,
      ENVIRONMENT_ID_PROD,
    ].reduce(
      (acc, env) => ({
        ...acc,
        [env]: {
          ...CLIENTS_CONFIGS.GENERAL[env],
          ...CLIENTS_CONFIGS[client_id]?.[env],
        },
      }),
      {},
    );

    if (!key) {
      return {
        ...mergedConfig[CONFIG_PER_CLIENT_DEFAULT],
        ...mergedConfig[environment],
      };
    }
    return (
      mergedConfig[environment]?.[key] ??
      mergedConfig[CONFIG_PER_CLIENT_DEFAULT]?.[key]
    );
  }

  return {
    CLIENTS_CONFIGS,
    DELLE: CLIENTS_CONFIGS.bkEfcYyUTImjXHU3xtsO,
    GENERAL,
    getClientConfig,
  };
}
