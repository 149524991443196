<template>
  <div
    :class="{'flex-1': flex}"
    class="f-text-field__wrapper"
    @click="(e) => $emit('click', e)"
  >
    <label v-if="label && !flushLabel" class="text-field-label">
      {{ label }}
    </label>
    <Component
      :is="getTextFieldComponent"
      :class="{
        'text-field': true,
        'text-area': isArea,
        'text-field-light': light,
        'text-field--disabled': disabled,
        'mt-2': label && !flushLabel,
        'text-field-pink-border': shouldDisplayPinkBorder,
      }"
      :style="formComponentStyle"
      :model-value="modelValue"
      :suffix="suffix"
      :label="flushLabel ? label : ''"
      :placeholder="placeholder"
      :clearable="clearable"
      :id="id"
      :autofocus="autofocus"
      :readonly="readonly"
      :disabled="disabled"
      :required="required"
      :pattern="pattern"
      :type="localType || type"
      :rows="maxRows"
      :data-cy="dataCy"
      :data-testid="dataTestid"
      variant="outlined"
      density="compact"
      persistent-hint
      hide-details
      no-resize
      @change="(e) => $emit('change', e.target.value)"
      @update:model-value="(v) => $emit('update:model-value', v)"
      @click:clear="() => $emit('update:model-value', '')"
      @blur="(e) => $emit('blur', e)"
      @focus="(e) => $emit('focus', e)"
      @keydown="(e) => $emit('keydown', e)"
      @keydown.left.stop="(e) => $emit('keydown', e)"
      @keydown.right.stop="(e) => $emit('keydown', e)"
    >
      <template v-if="!isArea" #prepend-inner>
        <slot name="prepend-inner">
          <vue-feather
            v-if="icon"
            class="text-field-icon mr-2"
            tag="div"
            :size="iconSize"
            :type="icon"
            :stroke="iconStroke"
            :fill="iconFill"
          />
        </slot>
      </template>

      <template v-if="!isArea" #append-inner>
        <slot name="append-inner">
          <vue-feather
            v-if="type === 'password' && canSeePassword"
            :type="localType ? 'eye-off' : 'eye'"
            class="cursor-pointer"
            @click="() => (localType = localType ? null : 'text')"
          />

          <vue-feather
            v-if="copyable"
            v-tooltip="$t('Parametres.copy_field')"
            :type="copyIcon"
            class="cursor-pointer"
            @click="copyInputContent"
          />
        </slot>
      </template>
      <slot name="input-multitag" />
    </Component>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import {useFormComponents} from "@/composables/formComponents";
import {VTextField, VTextarea} from "vuetify/lib/components/index.mjs";

export default defineComponent({
  name: "figma-text-field",
  props: {
    height: {type: String, default: "40px"},
    modelValue: [String, Number],
    suffix: String,
    label: String,
    icon: String,
    iconStroke: String,
    iconFill: String,
    placeholder: String,
    disabled: Boolean,
    id: String,
    flushLabel: Boolean,
    clearable: Boolean,
    readonly: Boolean,
    light: {type: Boolean, default: false},
    type: {type: String, default: "text"},
    required: Boolean,
    pattern: String,
    iconSize: {type: String, default: "24"},
    autofocus: {type: Boolean, default: false},
    pinkBorder: {type: Boolean, default: false},
    alertIfNan: {type: Boolean, default: false},
    outlined: {type: [Boolean, Array], default: false},
    // as of yet will not work with the #append-inner slot populated
    copyable: {type: Boolean, default: false},
    canSeePassword: {type: Boolean, default: false},
    dataCy: {type: String},
    dataTestid: {type: String},
    flex: Boolean,
    maxRows: {type: Number, default: 1},
  },
  emits: ["change", "update:model-value", "blur", "focus", "keydown", "click"],
  setup(props) {
    const {formComponentStyle} = useFormComponents(props);

    const localType = ref(null);
    const copyIcon = ref("copy");

    const shouldDisplayPinkBorder = computed(() => {
      const {pinkBorder, alertIfNan, modelValue} = props;
      const typedValue: any = (modelValue + "").replace(",", ".");
      return pinkBorder || (alertIfNan && isNaN(typedValue));
    });

    const isArea = computed(() => props.maxRows > 1);

    const getTextFieldComponent = computed(() =>
      !isArea.value ? VTextField : VTextarea,
    );

    function copyInputContent(): void {
      navigator.clipboard.writeText(`${props.modelValue}`);

      copyIcon.value = "check";
      setTimeout(() => (copyIcon.value = "copy"), 1000);
    }

    return {
      formComponentStyle,
      localType,
      copyIcon,
      shouldDisplayPinkBorder,
      getTextFieldComponent,
      isArea,
      copyInputContent,
    };
  },
});
</script>

<style lang="scss">
.text-field {
  border-radius: 8px;
  background: rgb(var(--v-theme-newSubBackground));
  outline: none;

  &-light {
    background: rgb(var(--v-theme-newLayerBackground));
  }

  &-icon {
    min-width: 24px;
  }

  &-label {
    font-size: 16px;
    color: rgb(var(--v-theme-newSubText));
  }

  input {
    color: rgb(var(--v-theme-newSubText)) !important;
    margin: 0;

    &::placeholder {
      color: rgb(var(--v-theme-newSelected)) !important;
    }
  }

  label {
    padding: 0;
  }

  .v-field {
    border-radius: 8px;
  }

  .v-field__input {
    // This now designates the wrapping div rather than the input itself, so
    // we need to bypass default vuetify parameters (OPL-4737)
    padding: 0;
    margin: 0;
    min-height: 0;

    & > input {
      min-height: v-bind(height);
      padding: 8px 0;
      margin: 0;
    }
  }

  .v-field__outline {
    border: 1px solid rgb(var(--v-theme-newSelected));
    border-radius: 8px;
    & [class*="v-field__outline__"] {
      border-width: 0px;
      &::before,
      &::after {
        border-width: 0px;
      }
    }
  }

  .v-text-field__suffix {
    min-height: v-bind(height);
    padding: 0 4px;
    align-self: center;
    color: rgb(var(--v-theme-newSubText));
  }

  .v-field__prepend-inner {
    margin-top: auto !important;
    margin-bottom: auto !important;
    color: rgb(var(--v-theme-newSubText));
  }

  &.text-area {
    height: calc(v-bind(height) + v-bind(maxRows - 1) * 24px) !important;

    .v-field__input {
      padding: 8px;
      margin: 0;
      min-height: 0;

      & > input {
        min-height: v-bind(height);
        padding: 8px 0;
        margin: 0;
      }
    }
  }

  // States
  &--disabled {
    background-color: rgb(var(--v-theme-newDisableBG)) !important;
    border-color: rgb(var(--v-theme-pinkRegular));

    input {
      color: rgb(var(--v-theme-newDisableText)) !important;
    }

    .v-field__outline {
      border: none !important;
    }
  }

  &:hover,
  & .v-field--focused {
    .v-field__prepend-inner {
      color: rgb(var(--v-theme-newMainText)) !important;
    }
  }

  &:hover {
    background: rgb(var(--v-theme-newHover));

    .v-field__outline {
      border-color: rgb(var(--v-theme-newMainText));
    }

    & .v-text-field__prefix,
    & .v-text-field__suffix {
      opacity: 1;
    }
  }

  &:active,
  &:focus,
  & .v-field--focused {
    background: rgb(var(--v-theme-newLayerBackground));

    input {
      color: rgb(var(--v-theme-newMainText)) !important;
    }

    .v-text-field__suffix {
      color: rgb(var(--v-theme-newSubText));
    }
  }

  &:focus,
  & .v-field--focused {
    label {
      color: rgb(var(--v-theme-newPrimaryRegular));
      background-color: rgb(var(--v-theme-newLayerBackground));
      padding: 0 4px;
    }

    .v-field__outline {
      border-color: rgb(var(--v-theme-newPrimaryRegular));
    }
  }

  &:active,
  & .v-field--focused {
    .v-field__outline {
      border-color: rgb(var(--v-theme-newMainText));
    }
  }

  &-pink-border {
    &,
    &:hover,
    &:focus,
    &:active {
      .v-field__outline {
        border-color: rgb(var(--v-theme-newPinkRegular));
      }
    }
  }
}
</style>
