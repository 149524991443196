<template>
  <v-menu
    ref="dropdown-menu"
    v-model="menu"
    content-class="dropdown-menu"
    :location="menuBottom ? 'end' : 'top start'"
    :close-on-content-click="false"
    min-width="300px"
    max-width="300px"
    max-height="300px"
    min-height="300px"
    :disabled="disabled"
  >
    <template v-slot:activator="{props}">
      <div
        v-bind="props"
        :class="[
          computedFieldClass,
          {
            'searchable-field--active': menu,
            'searchable-field--outlined': outlined,
          },
        ]"
        data-cy="fsearchable-text"
      >
        <vue-feather
          v-if="iconType"
          :size="iconSize"
          :type="iconType"
          :stroke="iconStroke"
          :fill="iconFill"
          :class="iconClass"
        />
        <span v-if="text" :class="isTag ? 'small-text' : 'fs-16'">{{
          text
        }}</span>
        <span v-else class="text-newSelected">{{ placeholder }}</span>
      </div>
    </template>
    <v-list
      class="items-list bg-newAppBackground"
      data-cy="fsearchable-list"
      v-if="!intermediateList"
    >
      <FTextField
        v-if="!hideSearch"
        class="px-4 my-2"
        :v-model="search"
        :label="$t('global.search')"
        @click:clear="clearSearch"
        @update:model-value="(v) => onSearchChange(v)"
        content-data-cy="fsearchable-input"
        clearable
        icon="search"
      />
      <NavListeMiddleWare
        v-for="factory in firstLevelSectors"
        v-show="false"
        :key="(factory || {}).id"
        :secteur="factory"
        :level="levelCorresp.find((x) => x.type === factory.type)"
        :niveau="0"
        :perimetres="overloadedPerimetres"
        :onItemSelected="onItemSelected"
        :toggle="toggle"
        :hideNbChildren="true"
      />
    </v-list>
    <v-list class="items-list bg-newAppBackground" v-else>
      <v-menu
        v-for="field in msd_fields"
        :key="field.model"
        open-on-hover
        location="end"
      >
        <template v-slot:activator="{props}">
          <v-list-item v-bind="props" class="nav-liste-item no-min-height">
            {{ field.label }}

            <v-list-item-action>
              <vue-feather type="chevron-right" />
            </v-list-item-action>
          </v-list-item>
        </template>
        <v-list class="items-list bg-newAppBackground">
          <NavListeMiddleWare
            v-for="factory in firstLevelSectors"
            :key="(factory || {}).id"
            :secteur="factory"
            :level="levelCorresp.find((x) => x.type === factory.type)"
            :niveau="0"
            :perimetres="overloadedPerimetres"
            :onItemSelected="(s, l) => onItemSelected(s, l, field)"
            :toggle="toggle"
            :hideNbChildren="true"
          />
        </v-list>
      </v-menu>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import {
  levelCorresp,
  reverseLevelCorresp,
  searchSubstring,
  getSortedPerimeters,
} from "@oplit/shared-module";
import NavListeMiddleWare from "@/components/Navigation/NavListeMiddleware.vue";
import {FTextField} from "@/components/Global/Homemade/Inputs";
import type {IOverloadedPerimetres} from "@/interfaces";

import {useMainStore} from "@/stores/mainStore";

export default defineComponent({
  name: "figma-searchable-dropdown",
  components: {NavListeMiddleWare, FTextField},
  props: {
    value: String,
    onChange: Function,
    placeholder: String,
    backgroundColor: String,
    iconType: String,
    iconStroke: String,
    iconFill: String,
    disabled: Boolean,
    color: String,
    fieldText: String,
    forcedLevel: Object,
    isTag: Boolean,
    menuBottom: Boolean,
    iconSize: {type: String, default: "24"},
    iconClass: {type: String, default: "mr-4"},
    intermediateList: Boolean,
    msd_fields: Array,
    hideSearch: Boolean,
    defaultPath: Object,
    outlined: {type: Boolean, default: false},
  },
  setup() {
    const mainStore = useMainStore();
    const {perimeters, stations} = storeToRefs(mainStore);
    const menu = ref(false);
    const search = ref("");
    const overloadedPerimetres = ref<IOverloadedPerimetres>({});
    return {
      menu,
      search,
      overloadedPerimetres,
      perimeters,
      stations,
      levelCorresp,
    };
  },
  computed: {
    text(): string {
      return (
        this.fieldText ||
        this.stations.find((x) => x.id === this.value)?.text ||
        ""
      );
    },
    computedFieldClass(): string {
      return (
        "br-8 searchable-field" +
        (this.disabled ? " disabled " : " active ") +
        (this.color || "") +
        (this.backgroundColor ? " bg-" + this.backgroundColor : "") +
        (this.isTag ? " tag-style" : "")
      );
    },
    highestLevel() {
      return (
        this.forcedLevel ||
        levelCorresp.find((level: any, i: number) => {
          if (i === 0) return false;
          return this.overloadedPerimetres[level.collection]?.length;
        }) ||
        {}
      );
    },
    firstLevelSectors() {
      let arr = [];
      if (this.forcedLevel?.id) {
        const parentMatch =
          (this.overloadedPerimetres[this.forcedLevel.collection] || []).find(
            (x: any) => x.id === this.forcedLevel.id,
          ) || {};
        arr =
          (parentMatch.children || []).map((child: any) => {
            if (!child) return null;
            const match =
              this.overloadedPerimetres[child.collection].find(
                (x: any) => x.id === child.id,
              ) || {};
            return match;
          }) || [];
      } else
        arr = this.overloadedPerimetres[this.highestLevel.collection] || [];
      return getSortedPerimeters(arr);
    },
  },
  watch: {
    perimeters: {
      deep: true,
      immediate: true,
      handler: function (val: any) {
        this.overloadPerimetres(val);
      },
    },
  },
  methods: {
    overloadPerimetres(perimetres: any) {
      if (!perimetres || !perimetres.sites?.length) return;
      let overloadedPerimetres: any = {};
      levelCorresp.forEach((level: any) => {
        const secteurs = perimetres[level.collection] || [];
        overloadedPerimetres[level.collection] = secteurs.map(
          (x: any, i: number) => ({
            ...x,
            open:
              _.get(
                this.overloadedPerimetres,
                [level.collection, i, "open"],
                false,
              ) || _.get(this.defaultPath, [level.type + "_id"]) === x.id,
          }),
        );
      });
      this.overloadedPerimetres = overloadedPerimetres;
    },
    toggle: async function (secteur: any, level: any) {
      const secteurs = _.get(this.overloadedPerimetres, level.collection, []);
      const newSecteurs = secteurs.map((x: any) => ({
        ...x,
        open: x.id === secteur.id ? !x.open : false,
      }));
      this.overloadedPerimetres[level.collection] = newSecteurs;
    },
    onItemSelected(secteur: any, level: any, field: any = {}) {
      const tempField = field?.model
        ? field
        : {label: "Capacité", model: "capa"};
      this.onChange(secteur, level, tempField);
      this.menu = false;
    },
    clearSearch() {
      this.search = "";
    },
    onSearchChange: _.debounce(function (this: any, search: string) {
      const {perimeters} = this;
      if (!perimeters || !perimeters.factories) return false;
      let overloadedPerimetres = {};
      reverseLevelCorresp.forEach((level, idx: number) => {
        overloadedPerimetres[level.collection] = perimeters[level.collection]
          .filter((x: any) => {
            //either the name matches
            if (searchSubstring(x?.name, search)) return true;
            //Or the entry has children that match
            let match = false;
            for (let i = 0; i < idx; i++) {
              const sublevel = reverseLevelCorresp[i];
              match =
                match ||
                overloadedPerimetres[sublevel.collection].some(
                  (y: any) => y[level.type + "_id"] === x.id,
                );
            }
            return match;
          })
          .map((x: any) => {
            const idx = this.overloadedPerimetres[level.collection].findIndex(
              (y: any) => y.id === x.id,
            );
            return {
              ...x,
              open: _.get(
                this.overloadedPerimetres,
                [level.collection, idx, "open"],
                false,
              ),
            };
          });
      });
      this.overloadedPerimetres = overloadedPerimetres;
      return true;
    }, 1000),
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/colors.scss";
div.searchable-field {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: rgb(var(--v-theme-newSubText));
  cursor: pointer;

  &--outlined {
    border: 1px solid rgb(var(--v-theme-newSelected));
    background-color: rgb(var(--v-theme-newSubBackground));

    &:hover {
      background-color: rgb(var(--v-theme-newHover));
      color: rgb(var(--v-theme-newMainText));
      border: 1px solid rgb(var(--v-theme-newMainText));
    }

    &.searchable-field--active {
      background-color: rgb(var(--v-theme-newLayerBackground));
      color: rgb(var(--v-theme-newMainText));
      border: 1px solid rgb(var(--v-theme-newMainText));
    }

    &.disabled {
      color: rgb(var(--v-theme-newDisableText));
      background-color: rgb(var(--v-theme-newDisableBG));
    }
  }

  &:hover {
    background-color: rgb(var(--v-theme-newSubBackground));
    color: rgb(var(--v-theme-newMainText));
  }

  &--active {
    background-color: rgb(var(--v-theme-newHover));
    color: rgb(var(--v-theme-newMainText));
  }

  &.disabled {
    color: rgb(var(--v-theme-newDisableText));
    background-color: rgb(var(--v-theme-newDisableBG));
  }

  padding: 8px;
  width: 100%;

  &.tag-style {
    padding: 0 4px;
    width: initial;
    .small-text {
      font-size: 12px;
    }
  }
}
.dropdown-menu {
  min-height: 300px;
  background-color: rgb(var(--v-theme-newAppBackground));
}
.nav-liste-item.no-min-height {
  min-height: 30px;
  height: 30px;
}
</style>
