import {
  SCHEDULING_CALENDAR_VIEW_CLASSIC,
  SCHEDULING_CALENDAR_VIEW_GANTT,
  STOCKS_TABLE_VIEWS,
} from "@/config/constants";
import {i18n} from "@/i18n";
import {NamedValue, StocksFilter, StocksGroupBy} from "@/interfaces";

export function useInternationalizedConstants() {
  const {t} = i18n;

  const GRAPH_SINGLE_LOAD_STRING = t("GraphCharge.Tooltip.load");

  const SCHEDULING_CALENDAR_VIEWS_OPTIONS: Array<NamedValue<string>> =
    Array.from(
      [SCHEDULING_CALENDAR_VIEW_CLASSIC, SCHEDULING_CALENDAR_VIEW_GANTT],
      (view: string) => ({
        label: t(`scheduling.lists.scheduling_view_${view}`) as string,
        value: view,
      }),
    );

  const DEFAULT_UNDEFINED_GROUP_STRING = t("global.not_specified");

  const STOCKS_GROUP_BY_OPTIONS = Array.from(
    STOCKS_TABLE_VIEWS.filter((view: string) => view !== "secteur_id"),
    (view: string): StocksGroupBy => ({
      name: t(`Stocks.lists.stocks_table_view_${view}`),
      field: view,
    }),
  );

  const STOCKS_FILTER_BY_OPTIONS: StocksFilter[] = Array.from(
    [undefined, /*"shortage", "overload",*/ "best", "with_cmj"],
    (criterion: string): StocksFilter => ({
      name: t(`Stocks.lists.products_status_${criterion}`),
      criterion,
    }),
  );

  const DEFAULT_STOCKS_FILTER_BY = STOCKS_FILTER_BY_OPTIONS.find(
    (option: StocksFilter) => option.criterion === "with_cmj",
  );

  return {
    DEFAULT_STOCKS_FILTER_BY,
    DEFAULT_UNDEFINED_GROUP_STRING,
    GRAPH_SINGLE_LOAD_STRING,
    SCHEDULING_CALENDAR_VIEWS_OPTIONS,
    STOCKS_FILTER_BY_OPTIONS,
    STOCKS_GROUP_BY_OPTIONS,
  };
}
