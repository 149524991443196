<template>
  <div
    :class="[
      {'text-only': textOnly},
      {'is-full-week': isFullWeek},
      'capacity-day-cell',
    ]"
  >
    <v-skeleton-loader :class="{loading}" :loading="loading" type="text">
      <span v-if="textOnly" :class="getCapaClassTextOnly">
        <CapaTooltip
          :capa-percentage="capaPercentage"
          :computed-charge="computedCharge"
          :computed-capa="computedCapa"
          :is-past="isPast"
          :unit="sectorTree.unite || sectorTree.unit"
          :text-prefix="textPrefix"
          :text-suffix="textSuffix"
          text-only
        />
      </span>
      <div v-else :class="getCapaClass">
        <CapaTooltip
          :capa-percentage="capaPercentage"
          :computed-charge="computedCharge"
          :computed-capa="computedCapa"
          :unit="sectorTree.unite || sectorTree.unit"
          :is-past="isPast"
          :text-prefix="textPrefix"
          :text-suffix="textSuffix"
        />
      </div>
    </v-skeleton-loader>
  </div>
</template>
<script lang="ts">
import {
  computed,
  ComputedRef,
  defineComponent,
  inject,
  MaybeRef,
  PropType,
  ref,
  Ref,
} from "vue";
import {useI18n} from "vue-i18n";
import {storeToRefs} from "pinia";
import _ from "lodash";
import numeral from "numeral";
import moment from "moment";
import {
  usePGWatcher,
  usePGComputedProperties,
  usePGSubscriptions,
} from "@/composables/pgComposable";
import {useSectorTree} from "@/composables/useSectorTree";
import CapaTooltip from "./CapaTooltip.vue";
import {useAPIStore} from "@/stores/apiStore";
import {SectorLike, ShiftPlanningItem, SchedulingOperation} from "@/interfaces";
import {useSchedulingStore} from "@/stores/schedulingStore";

import {useMainStore} from "@/stores/mainStore";
import {unref} from "vue";
import {
  DailyLoadNew,
  getNewDateFromShift,
  getDisplayedPeriod,
  DATE_DEFAULT_FORMAT,
  DATE_WITH_TIME_FORMAT,
  isPastDate,
} from "@oplit/shared-module";
import {getOperationDuration} from "@/tscript/utils/schedulingUtils";

numeral.locale("fr");

export default defineComponent({
  name: "capacity-day-cell",
  components: {CapaTooltip},
  props: {
    mc: {
      type: Object as PropType<SectorLike>,
      default: () => ({} as SectorLike),
    },
    day: {type: String, default: ""},
    isFullWeek: {type: Boolean, default: false},
    isPast: {type: Boolean, default: false},
    textOnly: {type: Boolean, default: false},
    pg_ops: {type: Array}, //don't set default to this props, or set to undefined
    periodData: {type: Object, default: () => ({})},
    dayEnd: {type: String, default: ""},
    shift: {
      type: Object as PropType<Partial<ShiftPlanningItem>>,
      default: () => null,
    },
    showPrefix: {type: Boolean, default: false},
    selectedView: {
      type: String as PropType<"day_view" | "week_view" | "month_view">,
    },
    loading: {type: Boolean},
  },
  setup(props) {
    const {t} = useI18n();
    const {chargeFn} = useAPIStore();
    const {
      selectedSimulation: simulation,
      schedulingMachineCentersAndTags,
      schedulingCurrentGroupBy,
      totalCapaBySector,
    } = storeToRefs(useSchedulingStore());

    const {sectorTree} = useSectorTree(
      props.mc,
      schedulingMachineCentersAndTags,
    );
    const {pg_init, pg_subscribe} = usePGSubscriptions({
      sectorTree,
      simulation,
    });

    const parsedPeriod = computed(() => {
      const {startDate, endDate} = props.periodData || {};
      if (props.day)
        return {startDate: props.day, endDate: props.dayEnd || props.day};
      return {startDate, endDate};
    });

    const {canLoadCapa, canUseSimulation} = usePGComputedProperties({
      parsedPeriod,
      sectorTree,
      simulation,
    });

    const mainStore = useMainStore();
    const {perimeters, userData, apiClient, isDevEnv, variables} =
      storeToRefs(mainStore);

    const shiftPlanning = inject<MaybeRef<Partial<ShiftPlanningItem[]>>>(
      "shiftPlanning",
      [],
    );
    const hasShiftPlanning = inject<ComputedRef<boolean>>("hasShiftPlanning");
    const isUsingShiftPlanning = computed(
      () => hasShiftPlanning.value && !!props.shift,
    );
    const sumOfAllShiftsCapa = computed<number>(() =>
      unref(isUsingShiftPlanning)
        ? unref(shiftPlanning).reduce(
            (sum, shift) => sum + shift.values.at(moment(props.day).day() - 1),
            0,
          )
        : 0,
    );
    const textSuffix = computed(() => {
      if (!schedulingCurrentGroupBy.value?.field) return;
      const groups = _.groupBy(props.pg_ops, (operation: SchedulingOperation) =>
        _.get(operation, schedulingCurrentGroupBy.value.field),
      );
      const groupsCount = Object.keys(groups).length;
      if (groupsCount < 1) return;
      return `(${groupsCount} ${t(
        "CapacityDayCell.groups_count_text_suffix",
        groupsCount,
      )})`;
    });
    const textPrefix = computed(() => {
      if (!props.showPrefix) return;
      const prefix = props.shift?.name
        ? props.shift.name
        : getDisplayedPeriod(
            props.selectedView === "month_view"
              ? moment(props.periodData.startDate).endOf("week")
              : props.periodData.startDate,
            props.selectedView === "month_view",
          );
      return `${_.upperFirst(prefix)} -`;
    });

    const pg_capa = computed(() => {
      const currentSectorCapa =
        totalCapaBySector.value[props.mc.secteur_id] || [];

      return currentSectorCapa.filter(
        ({day_date}) =>
          day_date >= parsedPeriod.value.startDate &&
          day_date <= parsedPeriod.value.endDate,
      );
    });

    return {
      pg_capa,
      pg_charge: ref<unknown[]>([]),
      canLoadCapa,
      canUseSimulation,
      chargeFn,
      parsedPeriod,
      pg_init,
      pg_subscribe,
      sectorTree,
      simulation,
      perimeters,
      userData,
      apiClient,
      isDevEnv,
      variables,
      schedulingMachineCentersAndTags,
      shiftPlanning,
      isUsingShiftPlanning,
      sumOfAllShiftsCapa,
      textPrefix,
      textSuffix,
      totalCapaBySector,
    };
  },
  computed: {
    daysOpen() {
      const {pg_capa} = this;
      return pg_capa.length;
    },
    computedCapa() {
      const {pg_capa, shift, day, sumOfAllShiftsCapa, isUsingShiftPlanning} =
        this;

      if (!pg_capa?.length) return 0;
      const result = _.sum(pg_capa.map((x: any) => +x.daily_capa)) || 0;

      let ratio = 1;
      if (isUsingShiftPlanning) {
        ratio =
          shift.values.at(moment(day).day() - 1) / (sumOfAllShiftsCapa || 1);
      }

      return ratio * result;
    },
    computedCharge() {
      const {pg_charge, pg_ops} = this;
      if (pg_ops) return _.sum(pg_ops.map(getOperationDuration));
      if (!pg_charge?.length) return 0;
      return _.sum(pg_charge.map((x: any) => +x.load));
    },
    capaPercentage() {
      const {
        computedCapa: capacity,
        computedCharge: load,
        isPast,
        daysOpen,
        dayEnd: endDate,
      } = this;

      // fix day to endDate so it display properly for week/month view
      const day = endDate;

      if (isPast) {
        if (+load === 0) return this.$t("global.no_delay");
        const delay = (load * daysOpen) / (capacity || 1) || 0;

        const formatedDelay = numeral(delay).format("0.[00]");
        return `${formatedDelay} ${this.$t("global.delay_days", delay)}`;
      }

      if (isPastDate(day)) return this.$t("global.past");

      if (!daysOpen) return this.$t("global.closed");
      if (capacity === 0 && load === 0) return "0 %";
      if (capacity === 0) return this.$t("global.infinity");
      const percentage = ((load / capacity) * 100).toFixed(0);
      return percentage + " %";
    },

    getCapaClass() {
      const {
        computedCharge: load,
        computedCapa: capacity,
        isFullWeek,
        isPast,
        daysOpen,
        day,
      } = this;
      let classes = ["sheduling-row-percent"];
      if (isPast) return classes;
      if (isFullWeek)
        classes = ["fbody-2", "semi-bold", "sheduling-row-tx-charge"];
      const overcapacity = "over-capacity";
      if (!daysOpen && !isPastDate(day)) classes.push("bg-newLightGrey");
      if (+capacity === 0) {
        if (load) return [...classes, overcapacity];
        return classes;
      }
      const percentage = ((load || 0) / capacity) * 100;
      if (percentage > 100) return [...classes, overcapacity];
      return classes;
    },

    getCapaClassTextOnly() {
      const {computedCharge: load, computedCapa: capacity} = this;
      let result = "";
      const overcapacity = "text-newPinkDark1";
      if (+capacity === 0 && load) return overcapacity;
      if (+capacity === 0) return "";
      const percentage = ((load || 0) / capacity) * 100;
      if (percentage > 100) result = overcapacity;
      return result;
    },
  },
  watch: {
    pg_refresh(val: number) {
      if (!val) return;
      this.loadCharge();
    },
  },
  created() {
    this.loadCharge = _.debounce(this.loadCharge, 200);

    usePGWatcher(this);
  },
  methods: {
    pg_subscriptions() {
      if (!this.canUseSimulation) return;
      this.pg_init();
      this.pg_charge = [];
      this.pg_subscribe(["daily_load"], () => {
        this.loadCharge();
      });
    },
    async loadCharge() {
      const {
        parsedPeriod,
        simulation,
        sectorTree,
        canLoadCapa,
        isPast,
        pg_ops,
        shift,
        shiftPlanning,
        isUsingShiftPlanning,
      } = this;
      if (pg_ops) return;
      const {id: simulation_id} = simulation || {};
      let {startDate, endDate} = parsedPeriod || {};

      if (isPast) {
        startDate = _.min([startDate, moment().format(DATE_DEFAULT_FORMAT)]);
        endDate = moment(startDate)
          .subtract(1, "days")
          .format(DATE_DEFAULT_FORMAT);
        startDate = moment(startDate)
          .subtract(6, "months")
          .startOf("month")
          .format(DATE_DEFAULT_FORMAT);
      }

      if (!canLoadCapa) return;

      if (isUsingShiftPlanning) {
        const shiftIndex = shiftPlanning.findIndex(
          (s: ShiftPlanningItem) => s.name === shift.name,
        );
        const initialStartDate = startDate;
        startDate = getNewDateFromShift(initialStartDate, shift);
        const nextShift: ShiftPlanningItem = shiftPlanning.at(shiftIndex + 1);
        endDate = nextShift
          ? getNewDateFromShift(initialStartDate, nextShift, {isEndDate: true})
          : moment(initialStartDate).endOf("day").format(DATE_WITH_TIME_FORMAT);
      }

      const results: DailyLoadNew[] = await this.chargeFn({
        secteur_id: sectorTree.id,
        simulation_id,
        startDate,
        endDate,
        params: {no_done_status: true},
      });
      this.pg_charge = (results || []).filter((x) => x.op_status !== "done");
    },
  },
});
</script>
<style scoped lang="scss">
@import "@/scss/constants";

.sheduling-row {
  &-percent {
    min-width: 237px;
    text-align: center;
    border-bottom: 1px solid rgb(var(--v-theme-newSelected));
    background: rgb(var(--v-theme-newLayerBackground));
    position: sticky;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-tx-charge {
    width: calc(100% + 1px);
    margin-left: -1px;
    text-align: center;
    border-bottom: 1px solid rgb(var(--v-theme-newSelected));
    border-left: 1px solid rgb(var(--v-theme-newSelected));
  }
}
.text-only {
  display: inline;
}
.over-capacity {
  background: rgb(var(--v-theme-newPinkLight2));
  color: rgb(var(--v-theme-newPinkDark1));
  font-weight: bold;
}
.capacity-day-cell {
  position: sticky;
  top: $schedulingCalendarHeaderCellHeight;
  background: inherit;
  z-index: 1;

  &:not(.text-only) {
    border-top: 1px solid rgb(var(--v-theme-newSelected));
    margin-top: -1px;

    &:first-child {
      border-top-color: rgb(var(--v-theme-newMainText));
    }
  }

  &.is-full-week {
    margin-top: -1px;
  }

  &:deep(.v-skeleton-loader) {
    border-radius: 0;
    &.loading {
      border-bottom: 1px solid rgb(var(--v-theme-newSelected));
    }
  }
  &:deep(.v-skeleton-loader__text) {
    margin: 4px auto 5px;
    width: 50%;
  }
}
</style>
