<template>
  <div
    v-click-outside="onClickOutsideColumn"
    :class="{
      'en-cours-column__is-conwip': !!selectedConwipGroup && !!mc.ticketsGauge,
    }"
    class="machine-column machine"
  >
    <div
      v-if="!loading"
      :class="{'has-border': hasBordersColumn}"
      class="machine-inprogress"
    >
      <template v-if="pg_ops.length > 0">
        <div
          v-show="nbOpsAbove"
          class="sticky-control top-control favorite-card cursor-pointer"
          @click="() => insideContainerScrollTo()"
        >
          <v-tooltip location="start">
            <template v-slot:activator="{props}">
              <v-card :ripple="false" v-bind="props">
                <v-card-text>
                  + {{ nbOpsAbove }}
                  {{
                    $t(
                      `scheduling.${
                        schedulingCurrentGroupBy?.field
                          ? "groups"
                          : "operations"
                      }`,
                      nbOpsAbove,
                    )
                  }}

                  <vue-feather type="arrow-up" size="16" />
                </v-card-text>
              </v-card>
            </template>
            <span>{{ $t("operation.controls.top") }}</span>
          </v-tooltip>
        </div>

        <div
          v-scroll.self="updateCalculations"
          :ref="scrollContainerWrapperRef"
          :class="[
            'operations-scroll-container-wrapper keep-scrollbar',
            {'top-border-radius': !nbOpsAbove},
            {'bottom-border-radius': !nbOpsBelow},
          ]"
        >
          <div
            v-scroll.self="updateCalculations"
            :class="[
              'operations-scroll-container',
              scrollContainerComputedClasses,
            ]"
            :ref="scrollContainerRef"
          >
            <OperationsCardsGroup
              v-if="schedulingCurrentGroupBy?.field"
              v-bind="operationsCardsCommonProps"
              :operations-list="sortedOngoingOps"
              v-on="operationsCardsCommonListeners"
              @toggle-group="updateCalculationsDebounced"
            />

            <template v-else-if="!!selectedConwipGroup && !!mc.ticketsGauge">
              <ConwipTicketsGaugeTicketsArea
                v-for="area in displayedTicketsGaugesArea"
                :key="area"
                :type="getTicketsAreaTypeByName(area)"
                has-left-border
              >
                <template #append-header>
                  {{ getTicketsCountByAreaText(area) }}
                </template>

                <SchedulingOperationCard
                  v-for="(op, index) in opsByTicketsGaugeArea[area]"
                  :key="`${op.op_id}-${op.day_date}`"
                  :intersection-observer-root="$refs[scrollContainerWrapperRef]"
                  v-bind="getOperationProps(op, index)"
                  v-on="operationsCardsCommonListeners"
                  @update-theoric-date="updateTheoricDate"
                />
              </ConwipTicketsGaugeTicketsArea>
            </template>

            <template v-else>
              <SchedulingOperationCard
                v-for="(op, index) in sortedOngoingOps"
                :key="`${op.op_id}-${op.day_date}`"
                :intersection-observer-root="$refs[scrollContainerWrapperRef]"
                v-bind="getOperationProps(op, index)"
                v-on="operationsCardsCommonListeners"
                @update-theoric-date="updateTheoricDate"
              />
            </template>
          </div>
        </div>

        <div
          v-show="nbOpsBelow"
          class="sticky-control bottom-control favorite-card cursor-pointer"
          @click="() => insideContainerScrollTo('bottom')"
        >
          <v-tooltip location="start">
            <template v-slot:activator="{props}">
              <v-card :ripple="false" v-bind="props">
                <v-card-text>
                  + {{ nbOpsBelow }}
                  {{
                    $t(
                      `scheduling.${
                        schedulingCurrentGroupBy?.field
                          ? "groups"
                          : "operations"
                      }`,
                      nbOpsBelow,
                    )
                  }}

                  <vue-feather type="arrow-down" size="16" />
                </v-card-text>
              </v-card>
            </template>
            <span>{{ $t("operation.controls.bottom") }}</span>
          </v-tooltip>
        </div>
      </template>

      <div
        v-else-if="!!mc.ticketsGauge"
        class="operations-scroll-container-wrapper"
      >
        <ConwipTicketsGaugeTicketsArea
          v-for="area in filteredTicketsGaugeAreas"
          :key="area"
          :type="getTicketsAreaTypeByName(area)"
          has-left-border
        >
          <template #append-header>
            0 / {{ getMaxTicketsByArea(area) }}
          </template>
        </ConwipTicketsGaugeTicketsArea>
      </div>
    </div>

    <EnCoursColumnSectorBlock
      :sector="mc"
      :id="operationsActionsMenuAnchorID"
      :class="{
        'has-operations': pg_ops.length,
        undercharged: isUnderchargedMachineCenter,
        overloaded: isOverloadedMachineCenter,
      }"
      :hide-before-border="pg_ops.length === 0"
      class="pa-3"
    >
      <template v-slot="{sectorName}">
        <div class="fd-flex-center gap-2">
          <v-tooltip location="bottom">
            <template v-slot:activator="{props}">
              <h4 v-bind="props" class="bold text-ellipsis flex-1">
                {{ sliceToMaxLength(sectorName, 25) }}
                <DevHelper mini absolute>({{ mc.secteur_id }})</DevHelper>
              </h4>
            </template>
            <span>{{ sectorName }}</span>
          </v-tooltip>

          <FButtonIcon
            v-if="
              !!selectedConwipGroup &&
              currentPermissions.scheduling.update_conwip_configuration
            "
            :icon-fill="variables.newSubText"
            :tooltip="$t('EnCoursColumn.tickets_gauge_button_tooltip')"
            icon="ticket-bar"
            size="32"
            icon-size="18"
            is-oplit-icon
            square
            @click="() => openConwipTicketsGaugeSidebar(mc)"
          />
        </div>

        <template v-if="!selectedConwipGroup">
          <div class="machine-status">
            <vue-feather :type="machineStatusIconType" size="16" />

            {{ machineStatusText }}
            <em v-if="machineStatusTextHelper">
              {{ machineStatusTextHelper }}
            </em>
          </div>

          <v-tooltip v-if="!hideSectorBlockItems" location="start">
            <template v-slot:activator="{props}">
              <div v-bind="props" class="d-flex items-center gap-4">
                <strong>{{ $t("scheduling.capa") }} :</strong>
                {{ getDisplayedMachineValue(formattedPgCapa) }}
              </div>
            </template>

            <span>
              <strong>{{ $t("scheduling.capa") }} :</strong>
              {{ getDisplayedMachineValue(formattedPgCapa) }}
            </span>
          </v-tooltip>
          <v-spacer v-else />
        </template>

        <v-tooltip location="start">
          <template v-slot:activator="{props}">
            <div v-bind="props" class="d-flex items-center gap-4">
              <strong>{{ $t("scheduling.in-progress") }} :</strong>
              {{ getDisplayedMachineValue(ongoingOpsTotalNbPieces.text) }}
            </div>
          </template>
          <span>
            <strong>{{ $t("scheduling.in-progress") }} :</strong>
            {{ getDisplayedMachineValue(ongoingOpsTotalNbPieces.text) }}
          </span>
        </v-tooltip>

        <template v-if="!!selectedConwipGroup">
          <v-spacer />

          <FButton
            v-if="hasOperatorsOrMachines && !hideSectorBlockItems"
            outlined
            @click="() => $emit('machine-name-clicked')"
          >
            {{ $t("EnCoursColumn.detailed_view_button_text") }}
          </FButton>
        </template>
      </template>
    </EnCoursColumnSectorBlock>
  </div>
</template>

<script lang="ts">
import {defineComponent, inject, ref, computed, PropType} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import uniqid from "uniqid";
import moment from "moment";
import numeral from "numeral";
import {useI18n} from "vue-i18n";
import {
  usePGWatcher,
  usePGComputedProperties,
  usePGSubscriptions,
} from "@/composables/pgComposable";
import {
  calculateOfDelay,
  pgOpsMapFn,
  getOPUnit,
  type DailyLoadWithSimulationData,
  getOperationStatus,
  toSafeString,
} from "@oplit/shared-module";
import {operationComputedStagnation} from "@/tscript/utils/schedulingUtils";

import {OperationsCardsGroup} from "@/components/Scheduling/Operations";
import SchedulingOperationCard from "@/components/Scheduling/Operations/SchedulingOperationCard.vue";
import {
  DATE_DEFAULT_FORMAT,
  PRIORITY_RULES_STORAGE_IDENTIFIER,
  CSS_OPERATION_CARD_CLASS,
} from "@/config/constants";
import {
  SchedulingOperation,
  OpenConwipTicketsGaugeSidebarFunction,
  SectorLike,
} from "@/interfaces";
import {useSchedulingStore} from "@/stores/schedulingStore";

import {useMainStore} from "@/stores/mainStore";
import {useStorage} from "@vueuse/core";
import EnCoursColumnSectorBlock from "@/components/Scheduling/Piloting/EnCoursColumnSectorBlock.vue";
import FButton from "@/components/Global/Homemade/Buttons/FButton.vue";
import ConwipTicketsGaugeTicketsArea from "@/components/Scheduling/Conwip/ConwipTicketsGaugeTicketsArea.vue";
import FButtonIcon from "@/components/Global/Homemade/Buttons/FButtonIcon.vue";
import {usePermissionsStore} from "@/stores/permissionsStore";

numeral.locale("fr");

export default defineComponent({
  name: "en-cours-column",
  components: {
    SchedulingOperationCard,
    OperationsCardsGroup,
    EnCoursColumnSectorBlock,
    FButton,
    ConwipTicketsGaugeTicketsArea,
    FButtonIcon,
  },
  props: {
    mc: {type: Object, default: () => ({} as SectorLike)},
    import_id: {type: String, default: ""},
    filters: {type: Array, default: () => []},
    search: {type: String, default: ""},
    ops: {type: Array, default: () => []},
    // toggle for the stagnation display within the OperationCards
    showStagnation: {type: Boolean, default: false},
    // the fields (defined with arbitrary strings) that are hidden within the OperationCards
    hiddenOperationCardsInfos: {type: Array, default: () => []},
    //informs if a request is pending in pg to get the ops
    opsLoading: {type: Boolean, default: false},
    selectedStatus: {type: Array, default: () => []},
    selectedOperationsIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    /**
     * array of 2 numbers representing respectively
     * the current index of this instance within the parent array
     * and the total amount of instances within the parent array
     */
    indexTotalCount: {
      type: Array as PropType<number[]>,
      default: () => [-1, -1],
    },
    isOperationOngoing: {type: Boolean, default: false},
    hideSectorBlockItems: {type: Boolean, default: false},
  },
  setup(props, {emit}) {
    const {t} = useI18n();
    const {pg_init, pg_subscribe} = usePGSubscriptions();
    const schedulingStore = useSchedulingStore();
    const {sendSchedulingLoadEventToBackend} = schedulingStore;
    const {
      selectedSimulation,
      schedulingCurrentGroupBy,
      pgOpsModifications,
      shouldHandleOPDuration,
      selectedConwipGroup,
    } = storeToRefs(schedulingStore);
    const {currentPermissions} = storeToRefs(usePermissionsStore());

    const openConwipTicketsGaugeSidebar =
      inject<OpenConwipTicketsGaugeSidebarFunction>(
        "openConwipTicketsGaugeSidebar",
      );
    const priorityRulesStorageIdentifier = inject<string>(
      "priorityRulesStorageIdentifier",
      PRIORITY_RULES_STORAGE_IDENTIFIER,
    );

    const priorityRules = useStorage(priorityRulesStorageIdentifier, [
      "client_delay",
    ]);

    const mainStore = useMainStore();
    const {userData, apiClient, perimeters, variables} = storeToRefs(mainStore);

    const orderedTicketsGaugeAreas = [
      "underload_critical_tickets_count",
      "underload_moderate_tickets_count",
      "default_ideal_tickets_count",
      "overload_moderate_tickets_count",
      "overload_critical_tickets_count",
    ];

    const pg_ops = ref<SchedulingOperation[]>([]);
    const mountedOps = ref<number>(0);
    const scrollContainerWrapperRef = ref<string>(null);
    const pg_max_wip = ref<number>(null);

    const today = computed(() => moment().format(DATE_DEFAULT_FORMAT));

    const parsedPeriod = computed(() => ({
      startDate: today.value,
      endDate: today.value,
    }));
    const sortedOngoingOps = computed<SchedulingOperation[]>(() => {
      const sortingArr = priorityRules.value.map((rule) => {
        return (o: SchedulingOperation) => {
          const {
            erp_date,
            new_date,
            day_date,
            max_date_of,
            theoric_date,
            fast_track,
            of_delta,
            first_active_op_theoric_date,
            first_active_op_planned_date,
            all_ops_done,
          } = o;

          if (rule === "priority") return fast_track || undefined;

          if (rule === "client_delay") {
            const clientDelay = calculateOfDelay(
              erp_date,
              new_date,
              day_date,
              max_date_of || theoric_date,
              first_active_op_theoric_date,
              first_active_op_planned_date,
              of_delta,
              all_ops_done,
            );
            return clientDelay;
          }

          if (rule === "op_delay") return new_date || day_date;

          const currentOpDate: string = new_date ?? day_date;
          if (rule === "stagnation") {
            const stagnationValue = operationComputedStagnation(o);
            return stagnationValue ?? -1;
          }
          return currentOpDate;
        };
      });
      const sortingOrder: ("asc" | "desc")[] = priorityRules.value.map((rule) =>
        ["op_delay", "priority"].includes(rule) ? "desc" : "asc",
      );

      sortingArr.push((o: SchedulingOperation) => +o.op_sequence || 0);
      sortingOrder.push("desc");

      const groupAndSort = (
        arr: SchedulingOperation[],
        sortingArr: ((op: SchedulingOperation) => any)[],
        sortOrder: ("asc" | "desc")[],
        groupByKey?: keyof SchedulingOperation,
      ): SchedulingOperation[] => {
        const grouped = groupByKey ? _.groupBy(arr, groupByKey) : {"": arr};
        const sortedGroups = Object.values(grouped).map((group) => {
          if (
            groupByKey &&
            group.length > 0 &&
            !Object.hasOwn(group[0], groupByKey)
          ) {
            group = group.map((op) => {
              return {
                ...op,
                [groupByKey]: t("OperationsCardsGroup.default_group_name"),
              };
            });
          }
          const sortedGroup = _.orderBy(group, sortingArr, sortOrder);
          return {
            operations: sortedGroup,
            group: sortingArr.map((sortFn) => sortFn(group[0])).join("_"),
          };
        });

        const flattenedGroups = _.flatMap(
          _.orderBy(sortedGroups, ["group"], sortOrder),
          (group) => group.operations,
        );

        return flattenedGroups;
      };

      return groupAndSort(
        pg_ops.value,
        sortingArr,
        sortingOrder,
        schedulingCurrentGroupBy.value?.field,
      );
    });
    // for certain cases we need to reverse due to the column being flex-reverse'd
    const reverseOrderedTicketsGaugeAreas = computed(() =>
      [...orderedTicketsGaugeAreas].reverse(),
    );
    const filteredTicketsGaugeAreas = computed(() =>
      orderedTicketsGaugeAreas.filter((area) => {
        if (
          area.startsWith("underload") &&
          !props.mc.ticketsGauge?.has_underload
        )
          return false;
        if (area.startsWith("overload") && !props.mc.ticketsGauge?.has_overload)
          return false;
        return true;
      }),
    );
    const displayedTicketsGaugesArea = computed(() =>
      reverseOrderedTicketsGaugeAreas.value.filter(
        (area) => opsByTicketsGaugeArea.value[area],
      ),
    );
    const opsByTicketsGaugeArea = computed(() => {
      if (!props.mc.ticketsGauge) {
        return {
          overload_critical_tickets_count: sortedOngoingOps.value,
        };
      }

      const clone = [...sortedOngoingOps.value].reverse();

      const operationsByArea: Record<string, SchedulingOperation[]> = {};

      for (const area of filteredTicketsGaugeAreas.value) {
        if (props.mc.ticketsGauge[area])
          operationsByArea[area] = clone.splice(0, props.mc.ticketsGauge[area]);
      }

      if (clone.length > 0)
        operationsByArea.overload_critical_tickets_count = clone;

      return operationsByArea;
    });
    const sectorTree = computed(() => {
      if (!props.mc?.secteur_id || !props.mc?.secteur_collection) return {};
      const sectorTree = (
        perimeters.value[props.mc.secteur_collection] || []
      ).find((x) => x.id === props.mc.secteur_id);
      return sectorTree || {};
    });
    const operationsActionsMenuAnchorID = computed(
      () => `operations-actions-menu__anchor-${props.mc.secteur_id}`,
    );
    const operationsActionsMenuProps = computed(() => {
      const [currentIndex, totalItems] = props.indexTotalCount;

      return {
        isOperationOngoing: props.isOperationOngoing,
        hideEditDialog: true,
        hideVerticalArrows: true,
        teleport: `#${operationsActionsMenuAnchorID.value}`,
        displaySide: currentIndex === totalItems ? "left" : "right",
        backgroundColor: "newPrimaryRegular",
        arrowsState: {
          Left: currentIndex > 1,
          Right: currentIndex !== totalItems,
        },
      };
    });
    const operationsCardsCommonProps = computed(() => ({
      showStagnation: props.showStagnation,
      selectedOpsIds: props.selectedOperationsIds,
      hiddenInfos: props.hiddenOperationCardsInfos,
      operationsActionsMenuProps: operationsActionsMenuProps.value,
    }));
    const operationsCardsCommonListeners = computed(() => ({
      "select-card": onSelectCard,
      "operation-mounted": onOperationMounted,
      "move-operation": onMoveOperation,
      "change-status": onChangeStatus,
    }));
    const determineOpIndexOfQtyLimit = computed(() => {
      if (!pg_max_wip.value) return -1;
      let targetIdx = -1,
        totalQte = 0;
      for (let i = sortedOngoingOps.value.length - 1; i >= 0; i--) {
        totalQte += +(sortedOngoingOps.value[i].quantite || 0);
        if (totalQte >= pg_max_wip.value) {
          targetIdx = i;
          break;
        }
      }
      return targetIdx;
    });
    const hasOperatorsOrMachines = computed(
      () =>
        props.mc.operateurs?.length > 0 || props.mc.machine_tags?.length > 0,
    );

    const {canLoadCapa} = usePGComputedProperties({parsedPeriod});

    function propagateOpsChanges(
      updatesList: Partial<DailyLoadWithSimulationData>[],
    ) {
      const tempPgOps = pg_ops.value.map((op: any) => {
        const matchOnNewUpdates = updatesList.find(
          ({op_id}) => op_id === op.op_id,
        );
        if (matchOnNewUpdates) return matchOnNewUpdates;
        else return op;
      });
      pg_ops.value = tempPgOps;
      pgOpsModifications.value = tempPgOps;
    }
    // returns a string without unwanted spaces between "nullish" values
    function stringifyArray(
      array: Array<number | string>,
      separator = " ",
    ): string {
      return array.filter(Boolean).join(separator);
    }

    function getDisplayedMachineValue(value: string) {
      if (pg_ops.value.length < 1) return "-";
      const [first] = pg_ops.value;
      return stringifyArray([value, getOPUnit(first, sectorTree.value)]);
    }
    function onSelectCard(card?: {op_id: string}) {
      emit("select-card", {sectorID: props.mc.secteur_id, opID: card?.op_id});
    }
    function onMoveOperation({code}: {code: string}) {
      emit("move-operations", code);
    }
    /**
     * moving an operation triggers an outside click therefore removing all selected cards
     * this function is to prevent this behaviour upon clicking on a menu arrow
     */
    function shouldPreventOutsideClick(eventTarget: HTMLElement) {
      if (!eventTarget) return false;
      if (
        ["feather-arrow-left", "feather-arrow-right"].some((key) =>
          eventTarget.classList?.contains(key),
        )
      )
        return true;
      return shouldPreventOutsideClick(eventTarget.parentElement);
    }
    async function onClickOutsideColumn({target}: {target: HTMLElement}) {
      if (!props.selectedOperationsIds.length) return;
      if (shouldPreventOutsideClick(target)) return;
      onSelectCard({op_id: null});
    }
    function onOperationMounted() {
      mountedOps.value += 1;
    }
    async function onChangeStatus({update, operation}) {
      if (!operation) return;

      //OPL-4456 : nouveau format des évènements d'ordo
      const {updated_values} = await sendSchedulingLoadEventToBackend({
        data: [{initial: operation, update}],
        should_return_updated_values: true,
        should_handle_op_duration: shouldHandleOPDuration.value,
      });
      const mappedValues = pgOpsMapFn(updated_values, {
        keepOpsDone: true,
      });
      propagateOpsChanges(mappedValues);
    }
    function getOperationProps(operation: SchedulingOperation, index: number) {
      return {
        ...operationsCardsCommonProps.value,
        id: operation.op_id,
        order: index + 1,
        isOngoingLimit: index === determineOpIndexOfQtyLimit.value,
        doNotRenderLazy: index > sortedOngoingOps.value.length - 5,
        class: "scheduling-operation-card__en-cours",
        operation,
      };
    }
    function getMaxTicketsByArea(area: string): string | number {
      return area === "overload_critical_tickets_count"
        ? "∞"
        : props.mc.ticketsGauge?.[area];
    }
    function getTicketsCountByAreaText(area: string) {
      return `${
        opsByTicketsGaugeArea.value[area].length
      } / ${getMaxTicketsByArea(area)}`;
    }
    function getTicketsAreaTypeByName(
      area: string,
    ): "ideal" | "critical" | "moderate" {
      for (const keyword of ["critical", "moderate"])
        if (area.includes(keyword)) return keyword as "critical" | "moderate";

      return "ideal";
    }

    return {
      pg_ops,
      pg_capa: ref<unknown[]>(null),
      loading: ref<boolean>(false),
      scrollContainerRef: ref<string>(null),
      pg_min_wip: ref<number>(null),
      pg_max_wip,
      //data to handle the number of hidden cards depending on the column height
      totalElementsHeight: ref<number>(0),
      wrapperHeight: ref<number>(0),
      childrenHeightsArr: ref<number[]>([]),
      nbOpsAbove: ref<number>(0),
      nbOpsBelow: ref<number>(0),
      // whether or not the borders are rendered around the column
      hasBordersColumn: ref<boolean>(false),
      // the operations-scroll-container overflow css prop is required to display full borders
      // we can't overflow directly or the calculations made within updateCalculations will be
      // restricted by this css prop
      canContainerOverflow: ref<boolean>(false),
      // counting operations that have mounted
      mountedOps,
      selectedSimulation,
      schedulingCurrentGroupBy,
      pgOpsModifications,
      canLoadCapa,
      pg_init,
      pg_subscribe,
      userData,
      apiClient,
      perimeters,
      parsedPeriod,
      shouldHandleOPDuration,
      sendSchedulingLoadEventToBackend,
      sortedOngoingOps,
      stringifyArray,
      sectorTree,
      getDisplayedMachineValue,
      onClickOutsideColumn,
      operationsActionsMenuAnchorID,
      operationsActionsMenuProps,
      operationsCardsCommonProps,
      scrollContainerWrapperRef,
      priorityRules,
      variables,
      opsByTicketsGaugeArea,
      orderedTicketsGaugeAreas,
      getOperationProps,
      getTicketsCountByAreaText,
      selectedConwipGroup,
      displayedTicketsGaugesArea,
      getMaxTicketsByArea,
      hasOperatorsOrMachines,
      getTicketsAreaTypeByName,
      reverseOrderedTicketsGaugeAreas,
      openConwipTicketsGaugeSidebar,
      filteredTicketsGaugeAreas,
      onOperationMounted,
      operationsCardsCommonListeners,
      currentPermissions,
      onSelectCard,
    };
  },
  computed: {
    // returns the icon type in function of the machine status
    machineStatusIconType(): string {
      return this.pg_ops.length
        ? this.isUnderchargedMachineCenter || this.isOverloadedMachineCenter
          ? "alert-circle"
          : "check-circle"
        : this.opsLoading
        ? "loader"
        : "x";
    },
    // returns the i18n text for the machine status
    machineStatusText(): string {
      // is the key that's been defined within i18n files
      let statusKey = "default";

      if (!this.pg_ops.length)
        statusKey = this.opsLoading ? "loading" : "no_operation";
      else if (this.isUnderchargedMachineCenter) statusKey = "undercharged";
      else if (this.isOverloadedMachineCenter) statusKey = "overloaded";

      return this.$t(`scheduling.machine_center_statuses.${statusKey}`);
    },
    // returns the min/max values in case of undercharge/overload
    // this is separated from machineStatusText because of the style applied (italic)
    machineStatusTextHelper(): string {
      if (this.isUnderchargedMachineCenter) return `(Min : ${this.pg_min_wip})`;
      else if (this.isOverloadedMachineCenter)
        return `(Max : ${this.pg_max_wip})`;
      return "";
    },
    // returns true if the total number of {unit} for the ongoing ops is below the minimum value defined by the user
    isUnderchargedMachineCenter(): boolean {
      // OPL-6627: undercharge / overload formatting should not be displayed in conwip context
      if (this.selectedConwipGroup) return false;
      if (!this.pg_min_wip) return false;
      return (
        this.pg_ops.length &&
        typeof this.pg_min_wip === "number" &&
        this.ongoingOpsTotalNbPieces.value < this.pg_min_wip
      );
    },
    // returns true if the total number of {unit} for the ongoing ops is above the maximum value defined by the user
    isOverloadedMachineCenter(): boolean {
      // OPL-6627: undercharge / overload formatting should not be displayed in conwip context
      if (this.selectedConwipGroup) return false;
      if (!this.pg_max_wip) return false;
      return (
        this.pg_ops.length &&
        typeof this.pg_max_wip === "number" &&
        this.ongoingOpsTotalNbPieces.value > this.pg_max_wip
      );
    },

    formattedPgCapa() {
      const {pg_capa, pg_ops} = this;
      if (!pg_ops.length) return "-";
      return numeral(+pg_capa).format("0.[00]");
    },
    simulation() {
      return this.selectedSimulation;
    },

    ongoingOpsTotalNbPieces() {
      const totalNb: number = _.sumBy(
        this.pg_ops,
        (o: any) => +(o.op_duration ?? o.quantite_op ?? o.quantite),
      );
      return {
        value: totalNb,
        text: numeral(totalNb).format("0.[00]"),
      };
    },
    scrollContainerComputedClasses(): string[] {
      const classes = [];
      if (!this.hasBordersColumn) {
        if (!this.schedulingCurrentGroupBy) classes.push("has-border");
        if (this.canContainerOverflow) classes.push("overflow-auto");
      }
      return classes;
    },
    debouncedCalculationsWatcher(): string {
      return JSON.stringify([
        this.showStagnation,
        this.hiddenOperationCardsInfos,
        this.priorityRules,
      ]);
    },
    nextTickCalculationsWatcher(): string {
      return JSON.stringify([
        this.mountedOps,
        this.filters,
        this.activeGrouping,
        this.selectedStatus,
        this.search,
      ]);
    },
  },
  watch: {
    selectedSimulation() {
      this.loadTotalCapa();
    },
    ops: {
      immediate: true,
      handler: function (val: any) {
        this.pg_ops = pgOpsMapFn(Array.from(val || []), {
          removeSmoothedDuplicates: true,
        });
      },
      deep: true,
    },
    debouncedCalculationsWatcher() {
      this.updateCalculationsDebounced();
    },
    nextTickCalculationsWatcher() {
      this.updateCalculationsNext();
    },
    schedulingCurrentGroupBy() {
      // due to the content of the column changing, we need to reset this to have proper calculations
      this.canContainerOverflow = false;
      this.updateCalculationsDebounced();
    },
  },
  created() {
    this.scrollContainerWrapperRef = uniqid("scroll_");
    this.scrollContainerRef = uniqid("scroll_");

    window.addEventListener("resize", this.updateCalculationsNext);

    this.pg_subscriptions = _.debounce(this.pg_subscriptions, 150);
    this.loadTotalCapa = _.debounce(this.loadTotalCapa, 150);
    this.loadFieldValue = _.debounce(this.loadFieldValue, 150);

    usePGWatcher(this);
  },
  methods: {
    pg_subscriptions() {
      if (!this.canLoadCapa) return;
      this.pg_init();
      this.pg_capa = null;
      this.pg_subscribe(["daily_capa", "default_capa"], () => {
        this.loadTotalCapa();
      });
    },
    async loadTotalCapa() {
      if (this.hideSectorBlockItems) return;

      this.loading = true;
      const {sectorTree, userData, simulation, parsedPeriod, canLoadCapa} =
        this;
      const {client_id} = userData || {};
      const {startDate, endDate} = parsedPeriod || {};

      if (!canLoadCapa) {
        this.loading = false;
        return;
      }
      const params = {
        query_type: "daily_total_capa",
        client_id,
        simulation_id: simulation.id,
        startDate,
        endDate,
        sector_tree: sectorTree,
        load_auto_orga: true,
      };

      const results = await this.apiClient.pgCustom(params);
      this.pg_capa = _.get(results, [0, "daily_capa"]) || 0;

      this.loading = false;
    },
    // loads the value for min_wip and max_wip that will determine whether or not a machine center is undercharged/overloaded
    async loadFieldValue() {
      const {userData, sectorTree: sector_tree, simulation} = this;
      const {client_id} = userData || {};
      const defaultDate = "1970-01-01";
      ["min_wip", "max_wip"].forEach((model: string) => {
        const params = {
          query_type: "msd_field_value",
          client_id,
          startDate: defaultDate,
          endDate: defaultDate,
          sector_tree,
          simulation,
          field: model,
          load_calendar: true,
        };

        this.apiClient.pgCustom(params).then((result: any) => {
          const {field_value} = result || {};
          this[`pg_${model}`] = field_value;
        });
      });
    },
    insideContainerScrollTo(direction = "top") {
      if (!this.$refs[this.scrollContainerWrapperRef]) return;
      this.$refs[this.scrollContainerWrapperRef].scrollTo({
        top: direction === "bottom" ? 0 : this.totalElementsHeight * -1.15,
        behavior: "smooth",
      });
    },
    /**
     * this function has to be called to update the above/below ops whenever
     * - the available size above the columns changes: resizing vertically / zooming in/out
     * - the amount of operations within a column changes (triggering therefore the above logic)
     */
    updateCalculationsNext() {
      this.canContainerOverflow = false;
      this.$nextTick(this.updateCalculations);
    },
    updateCalculationsDebounced: _.debounce(function (this: any) {
      this.updateCalculations();
    }, 50),
    updateCalculations() {
      const scrollContainer = this.$refs[this.scrollContainerRef];
      const scrollContainerWrapper = this.$refs[this.scrollContainerWrapperRef];

      if (!scrollContainer) return;

      let totalElementsHeight = scrollContainer.offsetHeight;
      let childrenList = scrollContainer.querySelectorAll(
        `.${CSS_OPERATION_CARD_CLASS}`,
      );

      if (!childrenList.length) return;

      /**
       * when the operations are not grouped, they are displayed directly as OperationCards with a v-for
       * and therefore are direct children of the scroll container.
       * when they are grouped, they are passed as a prop to the OperationsCardsGroup component.
       * due to vue's definition not allowing v-for's on the root template's tag,
       * this component has a wrapping <div> containing all the different groups of cards.
       * for this use case of grouped operations, the list of children in which we are interested to determine
       * the total height of the scroll container is this wrapping div's children and not the scroll container direct children.
       */
      if (this.schedulingCurrentGroupBy?.field) {
        const operationsCardsGroupWrappingDiv = scrollContainer.children[0];
        childrenList = operationsCardsGroupWrappingDiv.children;
        totalElementsHeight = operationsCardsGroupWrappingDiv.offsetHeight;
      }

      const childrenHeightsArr = Array.from(
        childrenList,
        ({offsetHeight}: {offsetHeight: number}): number => offsetHeight,
      );

      const wrapperHeight = scrollContainerWrapper.offsetHeight;
      const scrollTop = scrollContainerWrapper.scrollTop; //negative value
      const topHiddenHeight = totalElementsHeight - wrapperHeight + scrollTop;
      const bottomHiddenHeight = -scrollTop;

      // we apply borders to the column only if we have more operations than the available space in the container
      this.hasBordersColumn =
        wrapperHeight < totalElementsHeight && !this.schedulingCurrentGroupBy;

      //calculate the number of hidden ops
      let nbOpsAbove = 0,
        nbOpsBelow = 0,
        passedHeight = 0;
      //hidden ops above
      for (let i = 0; i < childrenHeightsArr.length; i++) {
        const itemHeight: number = childrenHeightsArr[i];
        if (itemHeight > topHiddenHeight - passedHeight) break;
        nbOpsAbove += 1;
        passedHeight += itemHeight;
      }
      passedHeight = 0;
      //hidden ops below
      for (let i = childrenHeightsArr.length - 1; i >= 0; i--) {
        const itemHeight: number = childrenHeightsArr[i];
        if (itemHeight > bottomHiddenHeight - passedHeight) break;
        nbOpsBelow += 1;
        passedHeight += itemHeight;
      }

      //pass the values to the state
      this.totalElementsHeight = totalElementsHeight;
      this.wrapperHeight = wrapperHeight;
      this.childrenHeightsArr = childrenHeightsArr;
      this.nbOpsAbove = nbOpsAbove;
      this.nbOpsBelow = nbOpsBelow;
      // we overflow after the calculations or these will be erroneous due to the working of the overflow prop
      this.canContainerOverflow = true;
    },
    sliceToMaxLength(
      str: string,
      maxLength: number,
      forceFullText = false,
    ): string {
      return str?.length < maxLength || forceFullText
        ? str
        : `${toSafeString(str).slice(0, maxLength - 3)}...`;
    },
    updateTheoricDate(data: any) {
      const {op_id, theoric_date} = data;
      this.pg_ops = this.pg_ops.map((x: any) =>
        x.op_id === op_id ? {...x, theoric_date} : x,
      );
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateCalculationsNext);
  },
});
</script>
<style scoped lang="scss">
.scroll-x-hidden {
  overflow-x: hidden !important;
}
.machine {
  display: inline-block;
  &-column {
    & .sticky-control {
      position: sticky;
      width: 100%;
      font-weight: bold;

      & > .v-card {
        background: rgb(var(--v-theme-newSubBackground));
        border-color: rgb(var(--v-theme-newSelected));
        border-style: solid;
        box-shadow: none;

        & .v-card-text {
          color: rgb(var(--v-theme-newSubText)) !important;
          font-size: 14px;
          font-weight: bold;
          padding: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
        }
      }

      &.top-control {
        top: 0;

        & > .v-card {
          border-width: 0 0 2px 0;
          border-radius: 8px 8px 0 0;
        }
      }

      &.bottom-control {
        top: 450px;

        & > .v-card {
          border-width: 4px 0 0 0;
          border-radius: 0 0 8px 8px;
        }
      }
    }

    & :deep(.conwip-tickets-gauge-tickets-area) {
      padding: 4px;
    }

    & :deep(.conwip-tickets-gauge-tickets-area__content) {
      display: flex;
      flex-direction: column-reverse;
    }

    & :deep(.conwip-tickets-gauge-tickets-area__header) {
      padding: 0 4px;
    }
  }
  &-inprogress {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: rgb(var(--v-theme-newLayerBackground));

    &:deep(.oplist-title.first:not(.last)) {
      border-radius: 8px 8px 0px 0px;
    }

    &:deep(.oplist-title.last:not(.first)) {
      border-radius: 0 0 8px 8px;
    }

    &.has-border {
      border: 1px solid rgb(var(--v-theme-newSelected));
      border-radius: 8px;
    }

    & .operations-scroll-container-wrapper {
      height: 0px;
      flex: 1 1 auto;
      overflow-y: auto;
      min-height: 0px;
      display: flex;
      flex-direction: column-reverse;
      overflow-x: hidden;

      &.keep-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      & .operations-scroll-container.has-border {
        border: 2px solid rgb(var(--v-theme-newSelected));
        border-radius: 8px;
        &.overflow-auto {
          overflow: auto;
        }
      }

      & .operation-card {
        padding: 0 !important;
      }
    }
  }

  &-ended {
    border: 1px solid rgb(var(--v-theme-newSelected));
    border-radius: 0px 0px 8px 8px;
    background-color: rgb(var(--v-theme-newLayerBackground));
    height: 320px;
  }
}

.en-cours-column-sector-block {
  & .machine-status {
    font-size: 12px;
    font-weight: 600;
    color: rgb(var(--v-theme-newSelected));

    display: flex;
    align-items: center;
    gap: 8px;

    margin: 4px 0 8px;
  }

  /* this has to be before the undercharged and overloaded classes */
  &.has-operations {
    & .machine-status {
      color: rgb(var(--v-theme-newGreenRegular));
    }
  }

  &.undercharged {
    background-color: rgb(var(--v-theme-newOrangeLight2));
    border-color: rgb(var(--v-theme-newOrangeRegular));

    & .machine-status {
      color: rgb(var(--v-theme-newOrangeRegular));
    }
  }

  &.overloaded {
    background-color: rgb(var(--v-theme-newPinkLight2));
    border-color: rgb(var(--v-theme-newPinkRegular));

    & .machine-status {
      color: rgb(var(--v-theme-newPinkRegular));
    }
  }
}

.machine-column
  .operations-scroll-container
  :deep(
    .operation-card:not(:first-child):not(:last-child)
      .scheduling-operation-card__content
  ) {
  border-radius: 0 !important;
}

.machine-column:not(.en-cours-column__is-conwip)
  .scheduling-operation-card__en-cours {
  &:deep(.scheduling-operation-card__content) {
    border: none;
  }

  &:not(:first-child):deep(.scheduling-operation-card__content) {
    border-top: 2px solid rgb(var(--v-theme-newSelected));
  }
}

.en-cours-column__is-conwip {
  & .scheduling-operation-card__en-cours {
    &:deep(.scheduling-operation-card__content) {
      margin-top: 4px;
      border-radius: 0;
    }
  }

  & .operations-scroll-container,
  & .has-border,
  & .bottom-border-radius,
  & .top-border-radius {
    border: none !important;
    border-radius: 0 !important;
  }

  & .sticky-control {
    border: 1px solid rgb(var(--v-theme-newSelected));

    &.top-control {
      border-radius: 8px 8px 0 0;
    }

    &.bottom-control {
      border-radius: 0 0 8px 8px;
    }
  }
}

[id^="operations-actions-menu__anchor-"] {
  position: relative;

  &:deep(.operation-card-actions-menu) {
    top: initial !important;
    bottom: 144px;
    padding: 6px;

    &.display-right {
      right: -128px;
    }

    &.display-left {
      left: -128px;
    }
  }
}
</style>
<style lang="scss">
.operations-scroll-container-wrapper {
  &.top-border-radius {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  &.bottom-border-radius {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  & .oplist-wrapper {
    padding: 0 !important;

    & .oplist-container {
      border-top: 2px solid rgb(var(--v-theme-newSelected));
      border-bottom: 2px solid rgb(var(--v-theme-newSelected));
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    & .operation-card {
      padding: 0 !important;
    }
  }

  & .operation-card {
    & > .v-card {
      border-radius: 0;
      border: none !important; /* removing the previous style applied so that only the following is */
      cursor: unset;
    }

    &:not(:first-child) {
      & > .v-card {
        border-top: 2px solid rgb(var(--v-theme-newSelected)) !important;
      }
    }
  }
}

.machine-status {
  svg {
    stroke-width: 3px;
  }
}
</style>
