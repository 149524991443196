import {
  PERMISSIONS_BY_ROLE_DEPENDENCIES_PER_GROUP,
  PERMISSIONS_BY_ROLE_GROUPS,
  USER_ROLES,
} from "@/config/constants";
import {
  OplitUserRole,
  PermissionsByRole,
  PermissionsGroup,
  PermissionsGroupName,
  PermissionsItem,
  User,
} from "@/interfaces";

const isUserFromRole = (currentUser: User, role: OplitUserRole) =>
  currentUser?.role === role;

const getClientUserRoles = () =>
  Object.keys(USER_ROLES).filter(
    (role) => role !== USER_ROLES.ADMIN,
  ) as OplitUserRole[];

/**
 * returns an object containing a key representing the @group as an object containing keys representing permissions defined in @enabledPermissions
 * @param group : the name of the group
 * @param enabledPermissions : the list of permissions set to `true`
 */
const getPermissionsGroupConfig = (
  group: PermissionsGroupName,
  enabledPermissions: string[],
): Partial<PermissionsGroup> => ({
  [group]: PERMISSIONS_BY_ROLE_GROUPS[group].reduce(
    (identifiers, currentIdentifier): Record<string, boolean> => ({
      ...identifiers,
      [currentIdentifier]: enabledPermissions.includes(currentIdentifier),
    }),
    {},
  ),
});

/**
 * @returns a list of random permissions for each group of the PermissionsGroup's
 */
const getRandomPermissions = (): PermissionsGroup =>
  Object.keys(PERMISSIONS_BY_ROLE_GROUPS).reduce(
    (groups, currentGroup: PermissionsGroupName): PermissionsGroup => ({
      ...groups,
      ...getPermissionsGroupConfig(
        currentGroup,
        PERMISSIONS_BY_ROLE_GROUPS[currentGroup].filter(
          () => Math.random() > 0.5,
        ),
      ),
    }),
    {} as PermissionsGroup,
  );

/**
 *
 * @param role : the name of the role from the OplitUserRole type
 * @returns the list of default permissions for each group of the PermissionsGroup's
 */
const getDefaultPermissionsByRole = (
  role?: OplitUserRole,
): PermissionsGroup => {
  switch (role) {
    case USER_ROLES.ADMIN:
    case USER_ROLES.GROUP_ADMIN:
      return Object.keys(PERMISSIONS_BY_ROLE_GROUPS).reduce(
        (acc, groupName: PermissionsGroupName) => ({
          ...acc,
          ...getPermissionsGroupConfig(
            groupName,
            PERMISSIONS_BY_ROLE_GROUPS[groupName],
          ),
        }),
        {} as PermissionsGroup,
      );
    case USER_ROLES.CLIENT_SUPER_ADMIN:
      return {
        ...getPermissionsGroupConfig("general", [
          "create_update_simulation",
          "read_simulation_active",
          "read_parameters",
          "update_parameters",
          "create_update_user",
          "create_comment",
          "read_analytics",
          "create_read_update_action",
        ]),
        ...getPermissionsGroupConfig("load_capacity", [
          "create_update_event",
          "read_event",
        ]),
        ...getPermissionsGroupConfig("scheduling", [
          "update_of_machine",
          "update_of_date",
          "update_of_status",
          "create_update_of_tags",
          "can_see_scheduling_kpis",
          "update_conwip_configuration",
        ]),
      } as PermissionsGroup;

    case USER_ROLES.CLIENT_ADMIN:
      return {
        ...getPermissionsGroupConfig("general", [
          "create_update_simulation",
          "read_simulation_active",
          "read_parameters",
          "update_parameters",
          "create_comment",
          "read_analytics",
          "create_read_update_action",
        ]),
        ...getPermissionsGroupConfig("load_capacity", [
          "create_update_event",
          "read_event",
        ]),
        ...getPermissionsGroupConfig("scheduling", [
          "update_of_machine",
          "update_of_date",
          "update_of_status",
          "create_update_of_tags",
          "can_see_scheduling_kpis",
        ]),
      } as PermissionsGroup;

    case USER_ROLES.CLIENT_USER:
      return {
        ...getPermissionsGroupConfig("general", [
          "read_simulation_active",
          "read_parameters",
          "create_comment",
        ]),
        ...getPermissionsGroupConfig("load_capacity", []),
        ...getPermissionsGroupConfig("scheduling", [
          "update_of_status",
          "create_update_of_tags",
        ]),
      } as PermissionsGroup;

    default:
      return {
        ...getPermissionsGroupConfig("general", []),
        ...getPermissionsGroupConfig("load_capacity", []),
        ...getPermissionsGroupConfig("scheduling", []),
      } as PermissionsGroup;
  }
};

const filterOnPermissions = <T>(
  array: PermissionsItem<T>[],
): PermissionsItem<T>[] =>
  array.filter(
    ({permissions}) => !permissions?.length || permissions.every(Boolean),
  );

const getPermissionsRequirements = (
  group: PermissionsGroupName,
  identifier: string,
): string[] => {
  const requirements =
    PERMISSIONS_BY_ROLE_DEPENDENCIES_PER_GROUP[group][identifier] || [];

  const result: string[] = [...requirements];

  for (const requirementIdentifier of requirements) {
    const coRequirements = getPermissionsRequirements(
      group,
      requirementIdentifier,
    );
    result.push(...coRequirements);
  }

  return result;
};

const getPermissionsDependencies = (
  group: PermissionsGroupName,
  identifier: string,
) => {
  const dependenciesPerGroup =
    PERMISSIONS_BY_ROLE_DEPENDENCIES_PER_GROUP[group];

  const dependencies = Object.keys(dependenciesPerGroup).filter(
    (identifierWithDependencies) =>
      dependenciesPerGroup[identifierWithDependencies].includes(identifier),
  );

  const result = [...dependencies];

  for (const dependencyIdentifier of dependencies) {
    const coDependencies = getPermissionsDependencies(
      group,
      dependencyIdentifier,
    );

    result.push(...coDependencies);
  }

  return result;
};

const getForcedPermissionsByGroup = (
  groupName: string,
  parametres?: {
    unique_scheduling_simulation?: boolean;
    floating_simulation?: boolean;
  },
): Partial<PermissionsByRole> => {
  switch (groupName) {
    case "general": {
      const keys: Record<string, boolean> = {};

      if (
        parametres.unique_scheduling_simulation &&
        parametres.floating_simulation
      )
        keys.read_simulation_active = true;

      return keys;
    }

    default:
      return {};
  }
};

export {
  getClientUserRoles,
  getDefaultPermissionsByRole,
  getRandomPermissions,
  isUserFromRole,
  filterOnPermissions,
  getPermissionsRequirements,
  getPermissionsDependencies,
  getPermissionsGroupConfig,
  getForcedPermissionsByGroup,
};
