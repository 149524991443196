<template>
  <div class="entity-progress-rate--wrapper">
    <slot name="prepend-progress-rate">
      <v-tooltip v-if="!hideEntityName" location="top">
        <template v-slot:activator="{props}">
          <div v-bind="props" class="entity-progress-rate--sector-name">
            {{ entity.name }}
          </div>
        </template>
        {{ entity.text }}
      </v-tooltip>
    </slot>

    <v-skeleton-loader
      v-if="loading"
      type="text"
      class="entity-progress-rate--container"
      height="inherit"
      boilerplate
    />
    <v-tooltip v-else :disabled="!tooltipMessage" location="top">
      <template v-slot:activator="{props}">
        <div
          v-bind="props"
          :style="getProgressRateStyle"
          class="entity-progress-rate--container"
        >
          <div
            class="entity-progress-rate--days-progress"
            :style="{left: `${getDaysProgress}%`}"
          />
        </div>
      </template>

      {{ tooltipMessage }}
    </v-tooltip>

    <div class="entity-progress-rate--value">
      <v-progress-circular
        v-if="loading"
        size="12"
        width="2"
        :color="variables.newSelected"
        indeterminate
      />
      <span v-else> {{ getProgressRate }} %</span>
    </div>
  </div>
</template>

<script lang="ts">
import {storeToRefs} from "pinia";
import {defineComponent, PropType} from "vue";
import {n, prettifyNumber} from "@/tscript/utils/generalHelpers";
import {workDaysSum} from "@oplit/shared-module";
import {buildRecursivelySectorTree, levelCorresp} from "@oplit/shared-module";
import {DATE_DEFAULT_FORMAT} from "@/config/constants";
import type {HTMLElementStyleObject, Sector} from "@/interfaces";
import moment from "moment";

import {useMainStore} from "@/stores/mainStore";

export default defineComponent({
  props: {
    entity: {type: Object as PropType<Record<string, unknown>>, required: true},
    period: {type: Array as PropType<string[]>, required: true},
    // loading state for the skeleton/spinner display
    loading: {type: Boolean, default: false},
    // values for the calculations & tooltip
    current: {type: Number, default: 0},
    total: {type: Number, default: 0},
    // unit displayed in the tooltip
    unit: {type: String, default: null},
    // will hide the default content of the `prepend-progress-rate` slot
    hideEntityName: {type: Boolean, default: false},
  },
  setup() {
    const mainStore = useMainStore();
    const {perimeters, calendars, variables} = storeToRefs(mainStore);

    return {perimeters, calendars, variables};
  },
  computed: {
    /**
     * used to construct the sectorTree for props.entity
     */
    localSectorTree() {
      const {entity, perimeters} = this;
      // FIXME: to verify
      const collection =
        entity.secteur_collection ?? entity.collection ?? "factories";
      const id =
        entity.secteur_id ?? entity.id ?? entity.current_pdc_id ?? "operations";

      const match = (perimeters[collection] || []).find(
        (sector: Sector) => sector.id === id,
      );
      return buildRecursivelySectorTree(match, perimeters);
    },
    /**
     * returns the progress rate for the given period, considering the sector's calendar (closed/opened days)
     */
    getDaysProgress(): number {
      const {period, calendars, localSectorTree} = this;
      // FIXME: handle when no sector tree
      if (!localSectorTree) return 0;

      const [startDate, endDate] = Array.from(period, (date: string) =>
        moment(date).format(DATE_DEFAULT_FORMAT),
      );
      if (!startDate || !endDate) return 0;

      const startOfWeek = moment().startOf("week").format(DATE_DEFAULT_FORMAT);
      const endOfWeek = moment().endOf("week").format(DATE_DEFAULT_FORMAT);

      if (startOfWeek > endDate) return 100;
      else if (endOfWeek < startDate) return 0;

      const monthCalendars = calendars.filter(
        (x: any) =>
          x.date >= startDate &&
          x.date <= endDate &&
          (x.secteur_id === localSectorTree.id ||
            levelCorresp.some(
              (level: any) =>
                x.secteur_id === localSectorTree[level.type + "_id"],
            )),
      );
      //Now we need to filter out in the other direction : if a parent was modified prior to the child, it should be ignored
      const orderedCalendars = monthCalendars.filter(
        (x: any, idx: number, arr: any) => {
          const restArr = arr.slice(idx + 1);
          if (
            restArr.some(
              (c: any) => c.date === x.date && c.updated_at >= x.updated_at,
            )
          )
            return false;
          return true;
        },
      );
      const today = moment() //
        .subtract(1, "days")
        .format(DATE_DEFAULT_FORMAT);

      const tx_avancement = workDaysSum(startDate, today, orderedCalendars);
      const total_cap = workDaysSum(startDate, endDate, orderedCalendars);
      return (total_cap ? tx_avancement / total_cap : 0) * 100;
    },
    tooltipMessage(): string {
      const {current, total, unit} = this;

      return `${this.$t("Analytics.titles.progress_rate")} :
      ${prettifyNumber(current)} /
      ${prettifyNumber(total)} ${unit ?? ""}`;
    },
    getProgressRate(): number {
      return n((this.current / this.total) * 100);
    },
    getProgressRateStyle(): HTMLElementStyleObject {
      const {getDaysProgress, getProgressRate, variables} = this;

      const containerBackgroundColor = variables.newAppBackground;
      const progressRateBackgroundColor =
        variables[
          getDaysProgress > getProgressRate
            ? "newPinkRegular"
            : "newGreenRegular"
        ];

      return {
        background: `linear-gradient(90deg,
            ${progressRateBackgroundColor} ${getProgressRate}%,
            ${containerBackgroundColor} ${getProgressRate}%,
            ${containerBackgroundColor} 100%)`,
      };
    },
  },
});
</script>

<style scoped lang="scss">
.entity-progress-rate--wrapper {
  display: flex;
  gap: 8px;
  align-items: center;

  & .entity-progress-rate--sector-name {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .entity-progress-rate--container {
    position: relative;
    border-radius: 50px;
    height: 12px;
    flex: 1;

    :deep(.v-skeleton-loader__text) {
      margin: 0;
    }

    & .entity-progress-rate--days-progress {
      height: inherit;
      width: 2px;
      background: black;
      position: absolute;
    }
  }
}
</style>
