<template>
  <div class="taux-avancement-wrapper">
    <v-row
      v-if="stacked"
      class="py-0 objective-chart-header d-flex align-center justify-space-between mx-auto"
    >
      <span class="objective-chart-header-title">{{ name }}</span>
      <h4 class="objective-chart-header-percent">
        {{ Math.round(computedCharts.production.totalProdWidth || 0) }}%
      </h4>
    </v-row>
    <v-row class="taux-avancement fd-flex-center">
      <v-col
        v-if="!stacked"
        cols="3"
        :class="[
          {
            'right-heading-border': firstRow,
          },
          'px-8',
          'py-0',
          'fd-flex-center',
          'justify-end',
        ]"
      >
        <h2
          data-cy="txAvancement-name"
          :class="{
            'medium-percent translate-top-lg': firstRow && lg,
            'percentage-advance': true,
            'small-percent': setSmallPercent,
            'translate-top': firstRow && lg,
          }"
          :style="{height: firstRow ? rowHeight + 'px' : 'unset'}"
        >
          {{ name }}
        </h2>
      </v-col>
      <v-col
        :cols="stacked ? 12 : 6"
        :class="[
          {
            'line-wrapper px-0': disableBorderRadius && !firstRow,
            'px-0': firstRow,
          },
          'py-3',
        ]"
        :style="{
          height: `${rowHeight}px`,
        }"
      >
        <v-tooltip location="bottom" :disabled="disableTooltip">
          <template v-slot:activator="{props}">
            <div
              v-bind="props"
              class="d-flex py-0"
              :style="{
                height: height + 'px',
                position: 'relative',
                top: '50%',
                transform: 'translateY(-50%)',
                borderRadius: disableBorderRadius ? 0 : 50 + 'px',
              }"
            >
              <ProgressLinearWithGoal
                :progress="computedCharts.production.totalProdWidth"
                :goal="computedCharts.production.lastUpdateGoalWidth"
                :goal-text="$t('Homepage.statistics.last_business_day_goal')"
                :hide-goal-text="hideGoalText"
                :height="firstRow ? 24 : 8"
                hide-percentage
                flat-container
                rounded-progress
                goal-text-on-top
                red-by-default
              />
            </div>
          </template>

          <div v-if="stacked" class="move-tooltip-2">
            <span class="fbody-2">{{ $t("Simulation.taux_avancement") }}</span>
            <div class="d-flex justify-space-between mt-2">
              <div class="d-flex align-center mr-15">
                <div :class="`small-badge bg-${computedColor} mr-2`" />
                <div>
                  {{ $t("Simulation.in_unit") }}
                  {{ computedCharts.production.unit }}
                </div>
              </div>
              <div>
                {{ computedCharts.production.passed }}
                <span class="text-newDisableText">
                  /{{ computedCharts.production.totalGoal }}
                </span>
              </div>
            </div>
          </div>

          <div v-else class="move-tooltip pa-2">
            <div class="px-1 text-newSubText">{{ activePeriod }}</div>
            <div class="d-flex justify-space-between">
              <div class="mr-2">
                {{ $t("Simulation.in_unit") }}
                {{ computedCharts.production.unit }}
              </div>
              <div>
                {{ computedCharts.production.passed }}
                <span class="text-newDisableText">
                  /{{ computedCharts.production.totalGoal }}
                </span>
              </div>
            </div>
            <div class="d-flex justify-space-between">
              <div class="mr-2">
                {{ $t("Simulation.in_unit") }} {{ computedCharts.days.unit }}
              </div>
              <div>
                {{ computedCharts.days.passed }}
                <span class="text-newDisableText">
                  /{{ computedCharts.days.totalGoal }}
                </span>
              </div>
            </div>
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center">
                <div class="small-badge bg-newGreenDark1 mr-2" />
                <div>{{ $t("Simulation.advance") }}</div>
              </div>
              <div>
                {{ computedCharts.production.advance }}
                {{ computedCharts.production.unit }}
              </div>
            </div>
          </div>
        </v-tooltip>
      </v-col>
      <v-col
        v-if="!stacked"
        cols="3"
        :class="[
          {
            'left-heading-border': firstRow,
          },
          'px-6',
          'py-0',
        ]"
      >
        <h2
          data-cy="txAvancement-0-3"
          :class="{
            'percentage-advance text-left': true,
            'medium-percent translate-top-lg': firstRow && lg,
            'small-percent': setSmallPercent,
            'translate-top': firstRow,
          }"
          :style="{height: firstRow ? rowHeight + 'px' : 'unset'}"
        >
          <span :class="`text-${computedColor}`">
            {{ Math.round(computedCharts.production.totalProdWidth || 0) }}
            %
          </span>
          <v-progress-circular
            v-if="spinner.capa || spinner.prod"
            size="20"
            color="newPrimaryDark1"
            indeterminate
            class="ml-2"
          />
        </h2>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, toRefs} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import numeral from "numeral";
import moment from "moment";
import {
  usePGWatcher,
  usePGComputedProperties,
  usePGSubscriptions,
} from "@/composables/pgComposable";
import {useSimulation} from "@/composables/useSimulation";
import ProgressLinearWithGoal from "@/components/Commons/ProgressLinearWithGoal.vue";

import {useMainStore} from "@/stores/mainStore";
import {useDisplay} from "vuetify";

numeral.locale("fr");

export default defineComponent({
  name: "taux-avancement",
  components: {
    ProgressLinearWithGoal,
  },
  props: {
    activePeriod: String,
    workdays: Object,
    height: {type: Number, default: 50},
    rowHeight: {type: Number, default: 20},
    setSmallPercent: Boolean,
    name: String,
    forcedPassedColor: {type: String, default: ""},
    disableTooltip: Boolean,
    sectorTree: {type: Object, default: () => ({})},
    forceSimulation: {type: Object, default: () => ({})},
    parsedPeriod: {type: Object, default: () => ({})},
    stacked: {type: Boolean, default: false},
    disableBorderRadius: {type: Boolean, default: false},
    firstRow: {type: Boolean, default: false},
    lastProdUpdateDate: {type: String, default: ""},
    hideGoalText: {type: Boolean, default: false},
  },
  setup(props) {
    const {parsedPeriod, forceSimulation, sectorTree} = toRefs(props);
    const mainStore = useMainStore();
    const {userData, apiClient, variables, team} = storeToRefs(mainStore);
    const {simulation} = forceSimulation.value?.id
      ? useSimulation(forceSimulation.value.id)
      : storeToRefs(mainStore);
    const {pg_init, pg_subscribe} = usePGSubscriptions({
      sectorTree,
      simulation,
    });
    const {canLoadCapa, canUseSimulation} = usePGComputedProperties({
      parsedPeriod,
      sectorTree,
      simulation,
    });

    const {lg} = useDisplay();

    const pg_prod = ref<unknown[]>([]);
    const pg_capa = ref<unknown[]>([]);
    const spinner = ref({prod: false, capa: false});

    return {
      canLoadCapa,
      canUseSimulation,
      pg_init,
      pg_subscribe,
      simulation,
      userData,
      apiClient,
      variables,
      team,
      pg_prod,
      pg_capa,
      spinner,
      lg,
    };
  },
  computed: {
    totalProd() {
      const {pg_prod} = this;
      return _.sum(pg_prod.map((x: any) => (x.qte || 0) / 1));
    },
    totalGoal() {
      const {
        pg_capa,
        pg_prod,
        simulation,
        spinner: {prod: prodLoading},
        datesArray,
      } = this;

      if (!pg_prod.length || prodLoading)
        return _.sumBy(pg_capa, (e: any) => +e.daily_capa);

      const simStartDate =
        simulation.forceToday || moment().format("YYYY-MM-DD");

      const updated_pg_capa = datesArray.reduce((acc: any, curr: any) => {
        if (curr < simStartDate) {
          return [
            ...acc,
            ...pg_prod
              .filter((f: any) => f.date === curr)
              .map((e: any) => ({...curr, daily_capa: +e.qte})),
          ];
        } else
          return [...acc, ...pg_capa.filter((f: any) => f.day_date === curr)];
      }, []);

      const totalGoal = _.sumBy(updated_pg_capa, (x: any) => +x.daily_capa);
      return totalGoal;
    },
    lastUpdateGoal() {
      const {pg_capa, lastProdUpdateDate, parsedPeriod} = this;
      const {startDate, endDate} = parsedPeriod;
      if (lastProdUpdateDate < startDate || lastProdUpdateDate > endDate)
        return 0;
      return _.sum(
        pg_capa
          .filter((x: any) => x.day_date <= lastProdUpdateDate)
          .map((x: any) => +x.daily_capa),
      );
    },
    computedCharts() {
      const {workdays, totalGoal, totalProd, lastUpdateGoal, sectorTree} = this;

      const unit = sectorTree?.unite || sectorTree?.unit;
      const ratioDays = Object.keys(workdays)?.length
        ? Math.min(
            1,
            workdays.todayWorkdays / (workdays.allMailleWorkdays || 0),
          )
        : 1;

      const totalProdWidth = (+totalProd / (+totalGoal || 1)) * 100;
      const lastUpdateGoalWidth = +lastUpdateGoal
        ? (+lastUpdateGoal / +(totalGoal || 1)) * 100
        : 0;

      const computedCharts = {
        production: {
          passed: this.computeNumeral(totalProd),
          totalGoal: this.computeNumeral(totalGoal),
          unit: (unit || "pièces").toLowerCase(),
          advance: this.computeNumeral(
            Math.max(0, totalProd - totalGoal * ratioDays),
          ),

          totalProdWidth,
          lastUpdateGoalWidth,
        },
        days: {
          passed: Math.min(workdays.todayWorkdays, workdays.allMailleWorkdays),
          totalGoal: workdays.allMailleWorkdays,
          unit: this.$t("global.day").toLowerCase(),
          passed_width: Math.min(
            Math.round(
              (workdays.todayWorkdays / (workdays.allMailleWorkdays || 0)) *
                10000,
            ) / 100,
            100,
          ),
        },
      };
      return computedCharts;
    },
    computedHasAdvance() {
      const {totalProd = 0, lastUpdateGoal = 0} = this;
      return _.round(totalProd, 2) > _.round(lastUpdateGoal, 2);
    },
    computedColor() {
      const {computedHasAdvance, forcedPassedColor} = this;
      if (forcedPassedColor) return forcedPassedColor;
      return computedHasAdvance ? "newGreenRegular" : "newPinkRegular";
    },
    startDate() {
      const {
        parsedPeriod: {startDate},
      } = this;
      return moment(startDate).format("D MMMM");
    },
    endDate() {
      const {
        parsedPeriod: {endDate},
      } = this;
      return moment(endDate).format("D MMMM");
    },
    datesArray() {
      const {startDate, endDate} = this.parsedPeriod;
      const length = moment(endDate).diff(startDate, "days");
      const datesArray = [...new Array(length + 1)].map((_x: any, i: number) =>
        moment(startDate).add(i, "days").format("YYYY-MM-DD"),
      );
      return datesArray;
    },
  },
  created() {
    this.pg_subscriptions = _.debounce(this.pg_subscriptions, 300);
    this.loadProd = _.debounce(this.loadProd, 300);
    this.loadCapa = _.debounce(this.loadCapa, 300);

    usePGWatcher(this);
  },
  methods: {
    pg_subscriptions() {
      if (!this.canUseSimulation) return;
      this.pg_init();
      this.pg_capa = [];
      this.pg_prod = [];
      this.pg_subscribe(["daily_capa", "default_capa"], this.loadCapa);
    },
    async loadProd() {
      const {parsedPeriod, sectorTree: sector_tree, canLoadCapa, team} = this;
      if (!canLoadCapa) return;
      this.spinner.prod = true;
      const {startDate, endDate} = parsedPeriod || {};
      const {id: secteur_id} = sector_tree || {};
      const {id: team_id} = team || {};

      const pg_prod = await this.apiClient.allProdFn(
        secteur_id,
        {startDate, endDate},
        team_id,
      );
      this.pg_prod = pg_prod || [];
      this.spinner.prod = false;
    },
    async loadCapa() {
      const {
        canLoadCapa,
        userData,
        simulation,
        parsedPeriod,
        sectorTree: sector_tree,
      } = this;
      if (!canLoadCapa) return;
      this.spinner.capa = true;
      const {client_id} = userData || {};
      const {startDate, endDate} = parsedPeriod || {};
      const {id: simulation_id} = simulation || {};
      const params = {
        query_type: "daily_total_capa",
        client_id,
        simulation_id,
        startDate,
        endDate,
        sector_tree,
        load_auto_orga: true,
      };
      const pg_capa = (await this.apiClient.pgCustom(params)) || [];
      this.pg_capa = pg_capa;
      this.spinner.capa = false;
    },
    computeNumeral(value: any, short = true) {
      return value === null
        ? value
        : numeral(value || 0).format(
            short ? `0,0.[0${value > Math.pow(10, 6) ? "00" : ""}] a` : "0,0",
          );
    },
  },
});
</script>
<style scoped lang="scss">
@import "@/scss/colors.scss";
.vertical-tx {
  position: absolute;
  bottom: -40px;
  height: 97px;
  width: 2px;
  border-radius: 1px;
  background-color: rgb(var(--v-theme-newSubText));
  &-wrapper {
    z-index: 11;
    position: relative;
  }
}
.percentage-italic {
  font-style: italic;
}
.disabled-text {
  color: rgb(var(--v-theme-newDisableText));
}
.tooltip-vertical {
  position: absolute;
  bottom: -2px;
}
.tooltip-vertical-text {
  color: rgb(var(--v-theme-newMainText));
  font-weight: 600;
}
.taux-avancement {
  .small-percent {
    font-size: 14px;
    line-height: 20px;
  }
  .medium-percent {
    font-size: 28px;
    font-weight: 600;
  }
}
.translate-left {
  transform: translate(-112%);
}
.translate-right {
  transform: translate(14%);
}
.objective-chart-wrapper {
  display: flex;
  flex-direction: row;
  border-radius: 50px;
  background: rgb(var(--v-theme-newAppBackground));
  overflow: hidden;
  flex: 1;
  position: relative;
  .chart-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    &.production {
      border-radius: 0px !important;
      z-index: 6;
      &-late {
        border-radius: 0px 50px 50px 0px !important;
      }
    }
    &.advance {
      background: rgb(var(--v-theme-newGreenDark1));
      border-radius: 0 50px 50px 0;
      z-index: 6;
    }
  }
  .days-bar {
    background: rgb(var(--v-theme-newMainText));
    border: 2px solid rgb(var(--v-theme-newMainText));
    position: absolute;
    left: 12px;
    border-radius: 8px;
    z-index: 8;
  }
}

.chart-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.max-height-100 {
  max-height: 100%;
}
.line-wrapper {
  border-left: 1px solid rgb(var(--v-theme-newDisableBG));
  border-right: 1px solid rgb(var(--v-theme-newDisableBG));
}
.left-heading-border {
  border-left: 1px solid rgb(var(--v-theme-newDisableBG));
  z-index: 1;
  position: relative;
  top: 19px;
  right: 1px;
}
.right-heading-border {
  border-right: 1px solid rgb(var(--v-theme-newDisableBG));
  z-index: 1;
  position: relative;
  top: 19px;
  left: 1px;
}
.translate-top {
  transform: translateY(-16%);
  &-lg {
    transform: translateY(-8%);
  }
}
.tooltip-container {
  position: relative;
  bottom: -8px;
}
</style>
